//  FONT FACE

@font-face {
  font-family: "National Book";
  src: url("fonts/National-Book.eot");
  src: url("fonts/National-Book.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-Book.woff2") format("woff2"),
    url("fonts/National-Book.woff") format("woff"),
    url("fonts/National-Book.ttf") format("truetype"),
    url("fonts/National-Book.svg#National-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "National Narrow";
  src: url("fonts/National2Narrow-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "National Narrow Bold";
  src: url("fonts/National2Narrow-Extrabold.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-Light.eot");
  src: url("fonts/National-Light.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-Light.woff2") format("woff2"),
    url("fonts/National-Light.woff") format("woff"),
    url("fonts/National-Light.ttf") format("truetype"),
    url("fonts/National-Light.svg#National-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "National Regular";
  src: url("fonts/National-RegularItalic.eot");
  src: url("fonts/National-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/National-RegularItalic.woff2") format("woff2"),
    url("fonts/National-RegularItalic.woff") format("woff"),
    url("fonts/National-RegularItalic.ttf") format("truetype"),
    url("fonts/National-RegularItalic.svg#National-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "National Book";
  src: url("fonts/National-BookItalic.eot");
  src: url("fonts/National-BookItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-BookItalic.woff2") format("woff2"),
    url("fonts/National-BookItalic.woff") format("woff"),
    url("fonts/National-BookItalic.ttf") format("truetype"),
    url("fonts/National-BookItalic.svg#National-BookItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-BoldItalic.eot");
  src: url("fonts/National-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-BoldItalic.woff2") format("woff2"),
    url("fonts/National-BoldItalic.woff") format("woff"),
    url("fonts/National-BoldItalic.ttf") format("truetype"),
    url("fonts/National-BoldItalic.svg#National-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-SemiboldItalic.eot");
  src: url("fonts/National-SemiboldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/National-SemiboldItalic.woff2") format("woff2"),
    url("fonts/National-SemiboldItalic.woff") format("woff"),
    url("fonts/National-SemiboldItalic.ttf") format("truetype"),
    url("fonts/National-SemiboldItalic.svg#National-SemiboldItalic")
      format("svg");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-ThinItalic.eot");
  src: url("fonts/National-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-ThinItalic.woff2") format("woff2"),
    url("fonts/National-ThinItalic.woff") format("woff"),
    url("fonts/National-ThinItalic.ttf") format("truetype"),
    url("fonts/National-ThinItalic.svg#National-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-Black.eot");
  src: url("fonts/National-Black.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-Black.woff2") format("woff2"),
    url("fonts/National-Black.woff") format("woff"),
    url("fonts/National-Black.ttf") format("truetype"),
    url("fonts/National-Black.svg#National-Black") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-BlackItalic.eot");
  src: url("fonts/National-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-BlackItalic.woff2") format("woff2"),
    url("fonts/National-BlackItalic.woff") format("woff"),
    url("fonts/National-BlackItalic.ttf") format("truetype"),
    url("fonts/National-BlackItalic.svg#National-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-Bold.eot");
  src: url("fonts/National-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-Bold.woff2") format("woff2"),
    url("fonts/National-Bold.woff") format("woff"),
    url("fonts/National-Bold.ttf") format("truetype"),
    url("fonts/National-Bold.svg#National-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-MediumItalic.eot");
  src: url("fonts/National-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-MediumItalic.woff2") format("woff2"),
    url("fonts/National-MediumItalic.woff") format("woff"),
    url("fonts/National-MediumItalic.ttf") format("truetype"),
    url("fonts/National-MediumItalic.svg#National-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-Regular.eot");
  src: url("fonts/National-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-Regular.woff2") format("woff2"),
    url("fonts/National-Regular.woff") format("woff"),
    url("fonts/National-Regular.ttf") format("truetype"),
    url("fonts/National-Regular.svg#National-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-LightItalic.eot");
  src: url("fonts/National-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-LightItalic.woff2") format("woff2"),
    url("fonts/National-LightItalic.woff") format("woff"),
    url("fonts/National-LightItalic.ttf") format("truetype"),
    url("fonts/National-LightItalic.svg#National-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-Medium.eot");
  src: url("fonts/National-Medium.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-Medium.woff2") format("woff2"),
    url("fonts/National-Medium.woff") format("woff"),
    url("fonts/National-Medium.ttf") format("truetype"),
    url("fonts/National-Medium.svg#National-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-Semibold.eot");
  src: url("fonts/National-Semibold.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-Semibold.woff2") format("woff2"),
    url("fonts/National-Semibold.woff") format("woff"),
    url("fonts/National-Semibold.ttf") format("truetype"),
    url("fonts/National-Semibold.svg#National-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-ExtraboldItalic.eot");
  src: url("fonts/National-ExtraboldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/National-ExtraboldItalic.woff2") format("woff2"),
    url("fonts/National-ExtraboldItalic.woff") format("woff"),
    url("fonts/National-ExtraboldItalic.ttf") format("truetype"),
    url("fonts/National-ExtraboldItalic.svg#National-ExtraboldItalic")
      format("svg");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-Thin.eot");
  src: url("fonts/National-Thin.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-Thin.woff2") format("woff2"),
    url("fonts/National-Thin.woff") format("woff"),
    url("fonts/National-Thin.ttf") format("truetype"),
    url("fonts/National-Thin.svg#National-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "National";
  src: url("fonts/National-Extrabold.eot");
  src: url("fonts/National-Extrabold.eot?#iefix") format("embedded-opentype"),
    url("fonts/National-Extrabold.woff2") format("woff2"),
    url("fonts/National-Extrabold.woff") format("woff"),
    url("fonts/National-Extrabold.ttf") format("truetype"),
    url("fonts/National-Extrabold.svg#National-Extrabold") format("svg");
  font-weight: 800;
  font-style: normal;
}
