/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

button::-moz-focus-inner {
  border: 0;
}

@font-face {
  font-family: "National Book";
  src: url("fonts/National-Book.eot");
  src: url("fonts/National-Book.eot?#iefix") format("embedded-opentype"), url("fonts/National-Book.woff2") format("woff2"), url("fonts/National-Book.woff") format("woff"), url("fonts/National-Book.ttf") format("truetype"), url("fonts/National-Book.svg#National-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "National Narrow";
  src: url("fonts/National2Narrow-Regular.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "National Narrow Bold";
  src: url("fonts/National2Narrow-Extrabold.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-Light.eot");
  src: url("fonts/National-Light.eot?#iefix") format("embedded-opentype"), url("fonts/National-Light.woff2") format("woff2"), url("fonts/National-Light.woff") format("woff"), url("fonts/National-Light.ttf") format("truetype"), url("fonts/National-Light.svg#National-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "National Regular";
  src: url("fonts/National-RegularItalic.eot");
  src: url("fonts/National-RegularItalic.eot?#iefix") format("embedded-opentype"), url("fonts/National-RegularItalic.woff2") format("woff2"), url("fonts/National-RegularItalic.woff") format("woff"), url("fonts/National-RegularItalic.ttf") format("truetype"), url("fonts/National-RegularItalic.svg#National-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "National Book";
  src: url("fonts/National-BookItalic.eot");
  src: url("fonts/National-BookItalic.eot?#iefix") format("embedded-opentype"), url("fonts/National-BookItalic.woff2") format("woff2"), url("fonts/National-BookItalic.woff") format("woff"), url("fonts/National-BookItalic.ttf") format("truetype"), url("fonts/National-BookItalic.svg#National-BookItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-BoldItalic.eot");
  src: url("fonts/National-BoldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/National-BoldItalic.woff2") format("woff2"), url("fonts/National-BoldItalic.woff") format("woff"), url("fonts/National-BoldItalic.ttf") format("truetype"), url("fonts/National-BoldItalic.svg#National-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-SemiboldItalic.eot");
  src: url("fonts/National-SemiboldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/National-SemiboldItalic.woff2") format("woff2"), url("fonts/National-SemiboldItalic.woff") format("woff"), url("fonts/National-SemiboldItalic.ttf") format("truetype"), url("fonts/National-SemiboldItalic.svg#National-SemiboldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-ThinItalic.eot");
  src: url("fonts/National-ThinItalic.eot?#iefix") format("embedded-opentype"), url("fonts/National-ThinItalic.woff2") format("woff2"), url("fonts/National-ThinItalic.woff") format("woff"), url("fonts/National-ThinItalic.ttf") format("truetype"), url("fonts/National-ThinItalic.svg#National-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-Black.eot");
  src: url("fonts/National-Black.eot?#iefix") format("embedded-opentype"), url("fonts/National-Black.woff2") format("woff2"), url("fonts/National-Black.woff") format("woff"), url("fonts/National-Black.ttf") format("truetype"), url("fonts/National-Black.svg#National-Black") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-BlackItalic.eot");
  src: url("fonts/National-BlackItalic.eot?#iefix") format("embedded-opentype"), url("fonts/National-BlackItalic.woff2") format("woff2"), url("fonts/National-BlackItalic.woff") format("woff"), url("fonts/National-BlackItalic.ttf") format("truetype"), url("fonts/National-BlackItalic.svg#National-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-Bold.eot");
  src: url("fonts/National-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/National-Bold.woff2") format("woff2"), url("fonts/National-Bold.woff") format("woff"), url("fonts/National-Bold.ttf") format("truetype"), url("fonts/National-Bold.svg#National-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-MediumItalic.eot");
  src: url("fonts/National-MediumItalic.eot?#iefix") format("embedded-opentype"), url("fonts/National-MediumItalic.woff2") format("woff2"), url("fonts/National-MediumItalic.woff") format("woff"), url("fonts/National-MediumItalic.ttf") format("truetype"), url("fonts/National-MediumItalic.svg#National-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-Regular.eot");
  src: url("fonts/National-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/National-Regular.woff2") format("woff2"), url("fonts/National-Regular.woff") format("woff"), url("fonts/National-Regular.ttf") format("truetype"), url("fonts/National-Regular.svg#National-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-LightItalic.eot");
  src: url("fonts/National-LightItalic.eot?#iefix") format("embedded-opentype"), url("fonts/National-LightItalic.woff2") format("woff2"), url("fonts/National-LightItalic.woff") format("woff"), url("fonts/National-LightItalic.ttf") format("truetype"), url("fonts/National-LightItalic.svg#National-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-Medium.eot");
  src: url("fonts/National-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/National-Medium.woff2") format("woff2"), url("fonts/National-Medium.woff") format("woff"), url("fonts/National-Medium.ttf") format("truetype"), url("fonts/National-Medium.svg#National-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-Semibold.eot");
  src: url("fonts/National-Semibold.eot?#iefix") format("embedded-opentype"), url("fonts/National-Semibold.woff2") format("woff2"), url("fonts/National-Semibold.woff") format("woff"), url("fonts/National-Semibold.ttf") format("truetype"), url("fonts/National-Semibold.svg#National-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-ExtraboldItalic.eot");
  src: url("fonts/National-ExtraboldItalic.eot?#iefix") format("embedded-opentype"), url("fonts/National-ExtraboldItalic.woff2") format("woff2"), url("fonts/National-ExtraboldItalic.woff") format("woff"), url("fonts/National-ExtraboldItalic.ttf") format("truetype"), url("fonts/National-ExtraboldItalic.svg#National-ExtraboldItalic") format("svg");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-Thin.eot");
  src: url("fonts/National-Thin.eot?#iefix") format("embedded-opentype"), url("fonts/National-Thin.woff2") format("woff2"), url("fonts/National-Thin.woff") format("woff"), url("fonts/National-Thin.ttf") format("truetype"), url("fonts/National-Thin.svg#National-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("fonts/National-Extrabold.eot");
  src: url("fonts/National-Extrabold.eot?#iefix") format("embedded-opentype"), url("fonts/National-Extrabold.woff2") format("woff2"), url("fonts/National-Extrabold.woff") format("woff"), url("fonts/National-Extrabold.ttf") format("truetype"), url("fonts/National-Extrabold.svg#National-Extrabold") format("svg");
  font-weight: 800;
  font-style: normal;
}
body > *,
input {
  width: 100%;
  font-family: "National", sans-serif;
  font-feature-settings: "tnum";
}

html {
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
  outline: none;
}

body {
  background-color: #eff0f2;
}

@media screen {
  body {
    min-height: 100vh;
  }
}

@media screen {
  #root {
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}

@media screen {
  body.prevent-scroll, body.ReactModal__Body--open {
    overflow: hidden;
  }
}

html,
body {
  font-size: 16px;
}

@media screen {
  .row {
    display: flex;
    flex-direction: row;
    margin: 0 -8px 20px -8px;
  }
}
.row.justify-between {
  justify-content: space-between;
}
@media screen {
  .row:only-of-type {
    margin: 20px -8px;
  }
}
@media screen {
  .row.no-margin {
    margin: 0;
  }
}
@media screen {
  .row .col {
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 0;
  }
}
@media screen {
  .row .col.is-full {
    flex: none;
    width: 100%;
  }
}
@media screen {
  .row .col.push-right {
    margin-left: auto;
  }
}
@media screen {
  .row .col.h-centered {
    margin: 0 auto;
  }
}
@media screen {
  .row .col.push-left {
    margin-right: auto;
  }
}
@media screen {
  .row .col.auto {
    flex: 0 0 0;
  }
}
.row .col.align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen {
  .row .col.is-1-of-2 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-1-of-3 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-1-of-4 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .row .col.is-1-of-5 {
    flex: none;
    width: 20%;
  }
}
@media screen {
  .row .col.is-1-of-6 {
    flex: none;
    width: 16.6666666667%;
  }
}
@media screen {
  .row .col.is-1-of-7 {
    flex: none;
    width: 14.2857142857%;
  }
}
@media screen {
  .row .col.is-1-of-8 {
    flex: none;
    width: 12.5%;
  }
}
@media screen {
  .row .col.is-1-of-9 {
    flex: none;
    width: 11.1111111111%;
  }
}
@media screen {
  .row .col.is-1-of-10 {
    flex: none;
    width: 10%;
  }
}
@media screen {
  .row .col.is-1-of-11 {
    flex: none;
    width: 9.0909090909%;
  }
}
@media screen {
  .row .col.is-1-of-12 {
    flex: none;
    width: 8.3333333333%;
  }
}
@media screen {
  .row .col.is-1-of-13 {
    flex: none;
    width: 7.6923076923%;
  }
}
@media screen {
  .row .col.is-1-of-14 {
    flex: none;
    width: 7.1428571429%;
  }
}
@media screen {
  .row .col.is-1-of-15 {
    flex: none;
    width: 6.6666666667%;
  }
}
@media screen {
  .row .col.is-1-of-16 {
    flex: none;
    width: 6.25%;
  }
}
@media screen {
  .row .col.is-2-of-3 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-2-of-4 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-2-of-5 {
    flex: none;
    width: 40%;
  }
}
@media screen {
  .row .col.is-2-of-6 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-2-of-7 {
    flex: none;
    width: 28.5714285714%;
  }
}
@media screen {
  .row .col.is-2-of-8 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .row .col.is-2-of-9 {
    flex: none;
    width: 22.2222222222%;
  }
}
@media screen {
  .row .col.is-2-of-10 {
    flex: none;
    width: 20%;
  }
}
@media screen {
  .row .col.is-2-of-11 {
    flex: none;
    width: 18.1818181818%;
  }
}
@media screen {
  .row .col.is-2-of-12 {
    flex: none;
    width: 16.6666666667%;
  }
}
@media screen {
  .row .col.is-2-of-13 {
    flex: none;
    width: 15.3846153846%;
  }
}
@media screen {
  .row .col.is-2-of-14 {
    flex: none;
    width: 14.2857142857%;
  }
}
@media screen {
  .row .col.is-2-of-15 {
    flex: none;
    width: 13.3333333333%;
  }
}
@media screen {
  .row .col.is-2-of-16 {
    flex: none;
    width: 12.5%;
  }
}
@media screen {
  .row .col.is-3-of-4 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .row .col.is-3-of-5 {
    flex: none;
    width: 60%;
  }
}
@media screen {
  .row .col.is-3-of-6 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-3-of-7 {
    flex: none;
    width: 42.8571428571%;
  }
}
@media screen {
  .row .col.is-3-of-8 {
    flex: none;
    width: 37.5%;
  }
}
@media screen {
  .row .col.is-3-of-9 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-3-of-10 {
    flex: none;
    width: 30%;
  }
}
@media screen {
  .row .col.is-3-of-11 {
    flex: none;
    width: 27.2727272727%;
  }
}
@media screen {
  .row .col.is-3-of-12 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .row .col.is-3-of-13 {
    flex: none;
    width: 23.0769230769%;
  }
}
@media screen {
  .row .col.is-3-of-14 {
    flex: none;
    width: 21.4285714286%;
  }
}
@media screen {
  .row .col.is-3-of-15 {
    flex: none;
    width: 20%;
  }
}
@media screen {
  .row .col.is-3-of-16 {
    flex: none;
    width: 18.75%;
  }
}
@media screen {
  .row .col.is-4-of-5 {
    flex: none;
    width: 80%;
  }
}
@media screen {
  .row .col.is-4-of-6 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-4-of-7 {
    flex: none;
    width: 57.1428571429%;
  }
}
@media screen {
  .row .col.is-4-of-8 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-4-of-9 {
    flex: none;
    width: 44.4444444444%;
  }
}
@media screen {
  .row .col.is-4-of-10 {
    flex: none;
    width: 40%;
  }
}
@media screen {
  .row .col.is-4-of-11 {
    flex: none;
    width: 36.3636363636%;
  }
}
@media screen {
  .row .col.is-4-of-12 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-4-of-13 {
    flex: none;
    width: 30.7692307692%;
  }
}
@media screen {
  .row .col.is-4-of-14 {
    flex: none;
    width: 28.5714285714%;
  }
}
@media screen {
  .row .col.is-4-of-15 {
    flex: none;
    width: 26.6666666667%;
  }
}
@media screen {
  .row .col.is-4-of-16 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .row .col.is-5-of-6 {
    flex: none;
    width: 83.3333333333%;
  }
}
@media screen {
  .row .col.is-5-of-7 {
    flex: none;
    width: 71.4285714286%;
  }
}
@media screen {
  .row .col.is-5-of-8 {
    flex: none;
    width: 62.5%;
  }
}
@media screen {
  .row .col.is-5-of-9 {
    flex: none;
    width: 55.5555555556%;
  }
}
@media screen {
  .row .col.is-5-of-10 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-5-of-11 {
    flex: none;
    width: 45.4545454545%;
  }
}
@media screen {
  .row .col.is-5-of-12 {
    flex: none;
    width: 41.6666666667%;
  }
}
@media screen {
  .row .col.is-5-of-13 {
    flex: none;
    width: 38.4615384615%;
  }
}
@media screen {
  .row .col.is-5-of-14 {
    flex: none;
    width: 35.7142857143%;
  }
}
@media screen {
  .row .col.is-5-of-15 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-5-of-16 {
    flex: none;
    width: 31.25%;
  }
}
@media screen {
  .row .col.is-6-of-7 {
    flex: none;
    width: 85.7142857143%;
  }
}
@media screen {
  .row .col.is-6-of-8 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .row .col.is-6-of-9 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-6-of-10 {
    flex: none;
    width: 60%;
  }
}
@media screen {
  .row .col.is-6-of-11 {
    flex: none;
    width: 54.5454545455%;
  }
}
@media screen {
  .row .col.is-6-of-12 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-6-of-13 {
    flex: none;
    width: 46.1538461538%;
  }
}
@media screen {
  .row .col.is-6-of-14 {
    flex: none;
    width: 42.8571428571%;
  }
}
@media screen {
  .row .col.is-6-of-15 {
    flex: none;
    width: 40%;
  }
}
@media screen {
  .row .col.is-6-of-16 {
    flex: none;
    width: 37.5%;
  }
}
@media screen {
  .row .col.is-7-of-8 {
    flex: none;
    width: 87.5%;
  }
}
@media screen {
  .row .col.is-7-of-9 {
    flex: none;
    width: 77.7777777778%;
  }
}
@media screen {
  .row .col.is-7-of-10 {
    flex: none;
    width: 70%;
  }
}
@media screen {
  .row .col.is-7-of-11 {
    flex: none;
    width: 63.6363636364%;
  }
}
@media screen {
  .row .col.is-7-of-12 {
    flex: none;
    width: 58.3333333333%;
  }
}
@media screen {
  .row .col.is-7-of-13 {
    flex: none;
    width: 53.8461538462%;
  }
}
@media screen {
  .row .col.is-7-of-14 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-7-of-15 {
    flex: none;
    width: 46.6666666667%;
  }
}
@media screen {
  .row .col.is-7-of-16 {
    flex: none;
    width: 43.75%;
  }
}
@media screen {
  .row .col.is-8-of-9 {
    flex: none;
    width: 88.8888888889%;
  }
}
@media screen {
  .row .col.is-8-of-10 {
    flex: none;
    width: 80%;
  }
}
@media screen {
  .row .col.is-8-of-11 {
    flex: none;
    width: 72.7272727273%;
  }
}
@media screen {
  .row .col.is-8-of-12 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-8-of-13 {
    flex: none;
    width: 61.5384615385%;
  }
}
@media screen {
  .row .col.is-8-of-14 {
    flex: none;
    width: 57.1428571429%;
  }
}
@media screen {
  .row .col.is-8-of-15 {
    flex: none;
    width: 53.3333333333%;
  }
}
@media screen {
  .row .col.is-8-of-16 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-9-of-10 {
    flex: none;
    width: 90%;
  }
}
@media screen {
  .row .col.is-9-of-11 {
    flex: none;
    width: 81.8181818182%;
  }
}
@media screen {
  .row .col.is-9-of-12 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .row .col.is-9-of-13 {
    flex: none;
    width: 69.2307692308%;
  }
}
@media screen {
  .row .col.is-9-of-14 {
    flex: none;
    width: 64.2857142857%;
  }
}
@media screen {
  .row .col.is-9-of-15 {
    flex: none;
    width: 60%;
  }
}
@media screen {
  .row .col.is-9-of-16 {
    flex: none;
    width: 56.25%;
  }
}
@media screen {
  .row .col.is-10-of-11 {
    flex: none;
    width: 90.9090909091%;
  }
}
@media screen {
  .row .col.is-10-of-12 {
    flex: none;
    width: 83.3333333333%;
  }
}
@media screen {
  .row .col.is-10-of-13 {
    flex: none;
    width: 76.9230769231%;
  }
}
@media screen {
  .row .col.is-10-of-14 {
    flex: none;
    width: 71.4285714286%;
  }
}
@media screen {
  .row .col.is-10-of-15 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-10-of-16 {
    flex: none;
    width: 62.5%;
  }
}
@media screen {
  .row .col.is-11-of-12 {
    flex: none;
    width: 91.6666666667%;
  }
}
@media screen {
  .row .col.is-11-of-13 {
    flex: none;
    width: 84.6153846154%;
  }
}
@media screen {
  .row .col.is-11-of-14 {
    flex: none;
    width: 78.5714285714%;
  }
}
@media screen {
  .row .col.is-11-of-15 {
    flex: none;
    width: 73.3333333333%;
  }
}
@media screen {
  .row .col.is-11-of-16 {
    flex: none;
    width: 68.75%;
  }
}
@media screen {
  .row .col.is-12-of-13 {
    flex: none;
    width: 92.3076923077%;
  }
}
@media screen {
  .row .col.is-12-of-14 {
    flex: none;
    width: 85.7142857143%;
  }
}
@media screen {
  .row .col.is-12-of-15 {
    flex: none;
    width: 80%;
  }
}
@media screen {
  .row .col.is-12-of-16 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .row .col.is-13-of-14 {
    flex: none;
    width: 92.8571428571%;
  }
}
@media screen {
  .row .col.is-13-of-15 {
    flex: none;
    width: 86.6666666667%;
  }
}
@media screen {
  .row .col.is-13-of-16 {
    flex: none;
    width: 81.25%;
  }
}
@media screen {
  .row .col.is-14-of-15 {
    flex: none;
    width: 93.3333333333%;
  }
}
@media screen {
  .row .col.is-14-of-16 {
    flex: none;
    width: 87.5%;
  }
}
@media screen {
  .row .col.is-15-of-16 {
    flex: none;
    width: 93.75%;
  }
}

@media screen {
  .columns {
    display: flex;
    flex-direction: column;
  }
}

@-webkit-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-moz-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-o-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
.bottom-border-focus {
  /* For browsers that don't support :focus-visible */
  /* Remove :focus styling for browsers that do support :focus-visible */
}
.bottom-border-focus:focus {
  border-bottom: 3px solid red;
  box-sizing: border-box;
}
.bottom-border-focus:focus:not(:focus-visible) {
  border-bottom: none;
}

.background-focus.btn.fill.cardinal:focus-visible {
  background-color: black;
}

.background-focus2.btn.outline.blue:focus-visible {
  background-color: yellow;
}

.color-focus.btn.lnk.white:focus-visible {
  color: red;
}

.border-focus:focus-visible {
  outline: 3px solid red;
}

.rounded-border-focus:focus-visible,
.rounded-border-focus a:focus-visible, .rounded-border-focus:focus-visible img {
  outline: 3px solid red;
  border-radius: 5px;
}

.container {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .container.has-adds {
    width: 730px;
  }
}
@media screen and (min-width: 1280px) {
  .container.has-adds {
    width: 970px;
  }
}

.promo_google_banner {
  display: none;
}
@media screen and (min-width: 768px) {
  .promo_google_banner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0;
  }
}

.promo_homepage_banner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.s-navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.25s ease-out;
  transform: translateY(0);
}
.s-navigation.is-scrolled-down {
  transition-delay: 0.15s;
  transform: translateY(-100%);
}
.s-navigation.is-scrolled-up {
  transform: translateY(0);
}

.s-navigation-placeholder {
  height: 60px;
}
@media screen and (min-width: 768px) {
  .s-navigation-placeholder {
    height: 110px;
  }
}
@media screen and (min-width: 1280px) {
  .s-navigation-placeholder {
    height: 130px;
  }
}
.s-navigation-placeholder.smaller-height {
  height: 60px;
}
@media screen and (min-width: 768px) {
  .s-navigation-placeholder.smaller-height {
    height: 70px;
  }
}
@media screen and (min-width: 1280px) {
  .s-navigation-placeholder.smaller-height {
    height: 90px;
  }
}

@media screen {
  header {
    background-color: #fff;
    height: 60px;
  }
}
@media screen and (min-width: 768px) {
  header {
    height: 72px;
  }
}
@media screen and (min-width: 1280px) {
  header {
    height: 90px;
  }
}
@media screen {
  header .header__container {
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (min-width: 1280px) {
  header .header__container {
    margin: 0 auto;
  }
}
header .header__container .header-sentry-logo {
  display: block;
  height: 50px;
  width: auto;
  margin: 0 0 0 auto;
}
@media screen and (min-width: 768px) {
  header .header__container .header-sentry-logo {
    margin: 0 0 0 32px;
  }
}
header .header__container .hide-link {
  position: absolute;
  left: 47%;
  top: 0;
  color: #00365f;
  font-weight: 600;
  transform: scale(0);
}
header .header__container .hide-link:focus {
  display: flex;
  align-items: center;
  padding: 5px;
  transform: scale(1);
}

@media screen {
  .main__alert {
    cursor: pointer;
    width: 100%;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    padding: 8px 16px;
    display: flex;
    background: #cc0000;
    justify-content: center;
    align-items: center;
    font-family: National;
    font-weight: bold;
    color: #fff;
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  .main__alert {
    padding: 8px 56px;
  }
}
@media screen {
  .main__alert .icon {
    background: url("../img/icons/alert-icon.svg") no-repeat center;
    width: 40px;
    height: 30px;
  }
}
@media screen {
  .main__alert span {
    padding: 4px 16px 0px;
  }
}
@media screen and (min-width: 768px) {
  .main__alert span {
    margin-left: 16px;
  }
}

@media screen {
  .hide_alert {
    cursor: pointer;
    border: none;
    width: 16px;
    height: 16px;
    background: url("../img/icons/alert-close.svg") no-repeat center;
  }
}
@media screen and (min-width: 768px) {
  .hide_alert {
    position: absolute;
    right: 32px;
    top: 16px;
  }
}

@media screen {
  .smartbanner-show .smartbanner {
    display: block !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999;
  }
  .smartbanner-show .s-navigation {
    top: 80px !important;
  }
  .smartbanner-show .s-navigation-placeholder {
    height: 140px;
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 768px) {
  .smartbanner-show .smartbanner {
    display: block !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .smartbanner-show .s-navigation {
    top: 80px !important;
  }
  .smartbanner-show .s-navigation-placeholder {
    height: 180px !important;
  }
}
.smartbanner-show .page_container.ghin_associations .alinks_wrapper {
  top: 150px;
}

.smartbanner-show.smartbanner-margin-top {
  margin-top: 80px;
}
.smartbanner-show.smartbanner-margin-bottom {
  margin-bottom: 80px;
}
.smartbanner-show .smartbanner {
  display: block;
}

.smartbanner {
  left: 0;
  display: none;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-family: Helvetica Neue, sans-serif;
  background: #f4f4f4;
  z-index: 9998;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
  border-bottom: 1px solid #e5e5e5;
}

.smartbanner-top {
  position: absolute;
  top: 0;
}

.smartbanner-bottom {
  position: fixed;
  bottom: 0;
}

.smartbanner-container {
  margin: 0 auto;
  padding: 0 5px;
}

.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 10px;
  font-size: 22px;
  font-weight: 100;
  text-align: center;
  color: #888;
  text-decoration: none;
  background: transparent;
  border: 0;
  padding: 0 0 1px;
  -webkit-font-smoothing: subpixel-antialiased;
}
.smartbanner-close:active, .smartbanner-close:hover {
  color: #444;
}

.smartbanner-icon {
  width: 57px;
  height: 57px;
  margin-right: 12px;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

.smartbanner-info {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  width: calc(99% - 170px);
  font-size: 12px;
  line-height: 1.2em;
  font-weight: normal;
}

.smartbanner-wrapper {
  max-width: 60px;
  display: inline-block;
  text-align: right;
  width: 100%;
}

.smartbanner-title {
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 5px;
}

.smartbanner-description {
  max-height: 40px;
  overflow: hidden;
}

.smartbanner-author {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 3px;
}
.smartbanner-author:empty + .smartbanner-description {
  max-height: 50px;
}

.smartbanner-button {
  margin: auto 0;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 400;
  color: #0067e6;
  text-decoration: none;
  display: inline-block;
}
.smartbanner-button:active, .smartbanner-button:hover {
  color: #aaa;
}

.smartbanner-ios {
  background: #f2f2f2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  line-height: 80px;
}
.smartbanner-ios .smartbanner-close {
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-family: Arial;
  color: #888;
  -webkit-font-smoothing: none;
}
.smartbanner-ios .smartbanner-close:active, .smartbanner-ios .smartbanner-close:hover {
  color: #888;
}
.smartbanner-ios .smartbanner-icon {
  background-size: cover;
}
.smartbanner-ios .smartbanner-info {
  color: #6a6a6a;
  text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.8);
  font-weight: 300;
}
.smartbanner-ios .smartbanner-title {
  color: #4d4d4d;
  font-weight: 500;
}
.smartbanner-ios .smartbanner-button {
  padding: 0 10px;
  font-size: 15px;
  min-width: 10%;
  font-weight: 400;
  color: #0c71fd;
}
.smartbanner-ios .smartbanner-button:active, .smartbanner-ios .smartbanner-button:hover {
  background: #f2f2f2;
}

.smartbanner-kindle, .smartbanner-windows {
  background: #f4f4f4;
  background: linear-gradient(180deg, #f4f4f4, #cdcdcd);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  line-height: 80px;
}

.smartbanner-kindle .smartbanner-close, .smartbanner-windows .smartbanner-close {
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: #888;
  text-shadow: 0 1px 0 #fff;
}

.smartbanner-kindle .smartbanner-close:active, .smartbanner-kindle .smartbanner-close:hover {
  color: #aaa;
}

.smartbanner-windows .smartbanner-close:active, .smartbanner-windows .smartbanner-close:hover {
  color: #aaa;
}

.smartbanner-kindle .smartbanner-icon, .smartbanner-windows .smartbanner-icon {
  background: rgba(0, 0, 0, 0.6);
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.smartbanner-kindle .smartbanner-info, .smartbanner-windows .smartbanner-info {
  color: #6a6a6a;
  text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.8);
}

.smartbanner-kindle .smartbanner-title, .smartbanner-windows .smartbanner-title {
  color: #4d4d4d;
  font-weight: 700;
}

.smartbanner-kindle .smartbanner-button, .smartbanner-windows .smartbanner-button {
  padding: 0 10px;
  min-width: 10%;
  color: #6a6a6a;
  background: #efefef;
  background: linear-gradient(180deg, #efefef, #dcdcdc);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px #bfbfbf, 0 1px 0 hsla(0, 0%, 100%, 0.6), inset 0 2px 0 hsla(0, 0%, 100%, 0.7);
}

.smartbanner-kindle .smartbanner-button:active, .smartbanner-kindle .smartbanner-button:hover {
  background: #dcdcdc;
  background: linear-gradient(180deg, #dcdcdc, #efefef);
}

.smartbanner-windows .smartbanner-button:active, .smartbanner-windows .smartbanner-button:hover {
  background: #dcdcdc;
  background: linear-gradient(180deg, #dcdcdc, #efefef);
}

@media screen {
  footer {
    margin: auto 0 0 0;
    background-color: #fff;
  }
}
@media screen and (min-width: 768px) {
  footer {
    padding: 20px 0;
  }
}
@media screen {
  footer .footer__container {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  footer .footer__container {
    padding: 0 20px;
  }
}
@media screen and (min-width: 1280px) {
  footer .footer__container {
    width: 1230px;
    margin: 0 auto;
    padding: 0;
  }
}
@media screen and (min-width: 1280px) {
  footer .footer__container .information__container {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  footer .footer__container .partners {
    padding: 20px;
    border-bottom: solid 1px #eff0f2;
  }
}
@media screen and (min-width: 768px) {
  footer .footer__container .partners {
    border-bottom: none;
  }
}
@media screen and (min-width: 1280px) {
  footer .footer__container .partners {
    flex: 1 1 0;
  }
}
@media screen {
  footer .footer__container .partners .partners__title {
    color: #00365f;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 40px 0;
  }
}
@media screen and (min-width: 768px) {
  footer .footer__container .partners .partners__title {
    font-size: 16px;
  }
}
@media screen {
  footer .footer__container .partners .partners__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 768px) {
  footer .footer__container .partners .partners__list {
    margin: 0;
  }
}
@media screen and (min-width: 1280px) {
  footer .footer__container .partners .partners__list {
    height: 140px;
    margin: 0;
  }
}
footer .footer__container .partners .partners__list .partner__item {
  width: 50%;
  flex: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 14px 0;
}
@media screen and (min-width: 768px) {
  footer .footer__container .partners .partners__list .partner__item {
    width: auto;
    flex: 1 1 auto;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer__container .partners .partners__list .partner__item.w-33 {
    width: 33.333333%;
    flex: none;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer__container .partners .partners__list .partner__item.w-25 {
    width: 25%;
    flex: none;
  }
}
@media screen and (min-width: 768px) {
  footer .footer__container .partners .partners__list .partner__item :not(.american-express) {
    padding: 0 10px;
  }
}
footer .footer__container .partners .partners__list .partner__item .partner__logo {
  width: 100%;
  height: 80px;
  display: block;
  object-fit: contain;
}
@media screen and (min-width: 1024px) {
  footer .footer__container .partners .partners__list .partner__item.sentry {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  footer .footer__container .partners .partners__list .partner__item.sentry .partner__logo {
    width: auto;
    height: 48px;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer__container .partners .partners__list .partner__item.american-express {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  footer .footer__container .partners .partners__list .partner__item.american-express .partner__logo {
    width: 90px;
    height: 90px;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer__container .partners .partners__list .partner__item.ally {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
@media screen {
  footer .footer__container .partners .partners__list .partner__item.ally .partner__logo {
    width: 90px;
    height: 90px;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer__container .partners .partners__list .partner__item.cisco {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
@media screen {
  footer .footer__container .partners .partners__list .partner__item.cisco .partner__logo {
    width: 96px;
    height: 67px;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer__container .partners .partners__list .partner__item.delloite {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
@media screen {
  footer .footer__container .partners .partners__list .partner__item.delloite .partner__logo {
    width: 156px;
    height: 67px;
  }
}
footer .footer__container .partners .partners__list .partner__item.lexus .partner__logo {
  margin-top: 15px;
}
@media screen {
  footer .footer__container .partners .partners__list .partner__item.lexus .partner__logo {
    width: 215px;
    height: 67px;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1024px) {
  footer .footer__container .partners .partners__list .partner__item.rolex {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  footer .footer__container .partners .partners__list .partner__item.rolex .partner__logo {
    width: 156px;
    height: 67px;
  }
}
@media screen {
  footer .footer__container .mobile__app {
    padding: 20px 0;
  }
}
@media screen and (min-width: 768px) {
  footer .footer__container .mobile__app {
    flex: none;
    width: 280px;
    margin: 0 auto 20px auto;
  }
}
@media screen and (min-width: 1280px) {
  footer .footer__container .mobile__app {
    border-left: solid 1px #eff0f2;
  }
}
@media screen {
  footer .footer__container .mobile__app .app__title {
    color: #00365f;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 40px 0;
  }
}
@media screen and (min-width: 768px) {
  footer .footer__container .mobile__app .app__title {
    font-size: 16px;
  }
}
@media screen {
  footer .footer__container .mobile__app .sentry-logo-container {
    max-width: 100%;
  }
}
@media screen {
  footer .footer__container .mobile__app .sentry-logo-container .sentry-logo {
    max-width: calc(100% - 40px);
    margin: 0 20px 20px;
  }
}
@media screen {
  footer .footer__container .mobile__app .app__details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1280px) {
  footer .footer__container .mobile__app .app__details {
    padding: 20px;
  }
}
@media screen {
  footer .footer__container .mobile__app .app__details .app__logo {
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 20px 0 0;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: cover;
    background-image: url("../img/logos/logo-ghin-app-icon.svg");
    border-radius: 15px;
  }
}
@media screen {
  footer .footer__container .mobile__app .app__details .app__stores {
    max-height: 100px;
  }
}
@media screen {
  footer .footer__container .mobile__app .app__details .app__stores li {
    margin: 0 0 20px 0;
  }
}
@media screen {
  footer .footer__container .mobile__app .app__details .app__stores li:last-child {
    margin: 0;
  }
}
@media screen {
  footer .footer__container .mobile__app .app__details .app__stores li .store {
    width: 135px;
    height: 40px;
    display: block;
    margin: 0 0 20px 0;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    color: transparent;
    line-height: 0;
    font-size: 0;
  }
}
@media screen {
  footer .footer__container .mobile__app .app__details .app__stores li .store.app-store {
    background-image: url("../img/logos/badge_app_store.svg");
  }
}
@media screen {
  footer .footer__container .mobile__app .app__details .app__stores li .store.google-play {
    background-image: url("../img/logos/badge_google_play.svg");
  }
}
footer .footer__container .mobile__app .app__details .app__stores li .store:focus-visible {
  outline: 3px solid red;
  border-radius: 5px;
}
@media screen {
  footer .footer__container .footer__links {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  footer .footer__container .footer__links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 40px 0 20px 0;
    border-top: solid 1px #eff0f2;
  }
}
@media screen {
  footer .footer__container .footer__links li {
    border-top: solid 1px #eff0f2;
  }
}
@media screen and (min-width: 768px) {
  footer .footer__container .footer__links li {
    flex: none;
    border-top: none;
    border-right: solid 1px #00365f;
    padding: 0 10px;
  }
}
@media screen and (min-width: 1280px) {
  footer .footer__container .footer__links li {
    padding: 0 20px;
  }
}
@media screen {
  footer .footer__container .footer__links li:last-child {
    border-bottom: solid 1px #eff0f2;
  }
}
@media screen and (min-width: 768px) {
  footer .footer__container .footer__links li:last-child {
    border-bottom: none;
    border-right: none;
  }
}
@media screen {
  footer .footer__container .footer__links li .footer__link {
    color: #00365f;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    padding: 15px 0;
    display: block;
  }
  footer .footer__container .footer__links li .footer__link:focus-visible {
    color: red;
  }
}
@media screen and (min-width: 768px) {
  footer .footer__container .footer__links li .footer__link {
    line-height: 18px;
    padding: 0;
  }
}
@media screen {
  footer .footer__container .copyright__container {
    padding: 20px 0;
  }
}
@media screen {
  footer .footer__container .copyright__container p {
    color: #4e515e;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.25px;
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  footer .footer__container .copyright__container p {
    letter-spacing: 0;
  }
}
@media screen {
  footer .footer__container .accessibility__container {
    padding: 15px 0;
  }
}
@media screen and (min-width: 768px) {
  footer .footer__container .accessibility__container {
    padding: 5px 0;
  }
}
footer .footer__container .accessibility__container .logo {
  width: 100%;
  height: 30px;
  display: block;
  object-fit: contain;
}

@media screen {
  .navigation {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .navigation {
    display: block;
    height: 40px;
    background-color: rgba(0, 54, 95, 0.9);
  }
}
@media screen {
  .navigation .nav__links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0;
  }
}
@media screen {
  .navigation .nav__links li .nav__link {
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    font-weight: 700;
    letter-spacing: 0.5px;
    white-space: nowrap;
    padding: 0 10px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease;
  }
}
@media screen and (min-width: 1280px) {
  .navigation .nav__links li .nav__link {
    letter-spacing: 1.8px;
  }
}
.navigation .nav__links li .nav__link:hover {
  background: rgba(0, 136, 206, 0.2);
}
.navigation .nav__links li .nav__link.is-active {
  background: #007AB8;
}

@media screen {
  .hero__section {
    height: 624px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 50%;
    background-size: cover;
    background-image: url("../img/ghin_background.jpg");
  }
}
@media screen and (min-width: 768px) {
  .hero__section {
    height: 456px;
  }
}
@media screen and (min-width: 1280px) {
  .hero__section {
    height: 600px;
  }
}
@media screen {
  .hero__section .hero__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70%;
  }
}
@media screen and (min-width: 768px) {
  .hero__section .hero__container {
    display: flex;
    flex-direction: row;
    padding: 0px 0;
    height: calc(100% - 40px);
  }
}
@media screen and (min-width: 1280px) {
  .hero__section .hero__container {
    width: 1230px;
    padding: 0px 0px;
    padding-bottom: 120px;
    margin: 0 auto;
  }
}
@media screen {
  .hero__section .hero__container .hero__column {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .hero__section .hero__container .hero__column {
    flex: none;
    width: 50%;
    padding: 0 0px;
  }
}
@media screen and (min-width: 1024px) {
  .hero__section .hero__container .hero__column {
    padding: 0 0px;
  }
}
@media screen and (min-width: 414px) {
  .hero__section .hero__container .hero__column {
    flex: content;
    width: 80%;
  }
}
@media screen and (min-width: 1280px) {
  .hero__section .hero__container .hero__column {
    padding: 0 30px;
  }
}
@media screen {
  .hero__section .hero__container .hero__column.grow-0 {
    flex-grow: 0;
  }
}
@media screen and (min-width: 768px) {
  .hero__section .hero__container .hero__column.grow-0 {
    flex-grow: 1;
  }
}
@media screen {
  .hero__section .hero__container .hero__column.hide-phone {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .hero__section .hero__container .hero__column.hide-phone {
    display: flex;
  }
}
.hero__section.login {
  height: auto;
}
@media screen {
  .hero__section.login {
    -webkit-box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.25);
    -ms-box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.25);
    padding: 0px;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 414px) {
  .hero__section.login {
    padding: 20px;
  }
}
@media screen and (min-width: 768px) {
  .hero__section.login {
    padding: 0;
  }
}
.hero__section.login .hero__column {
  margin-top: 50px;
}
@media screen and (min-width: 768px) {
  .hero__section.login .hero__column {
    margin-top: 50px;
    max-width: 100%;
  }
}
@media screen {
  .hero__section.profile {
    height: 420px;
  }
}
@media screen {
  .hero__section.profile.trial {
    height: 520px;
  }
}
@media screen and (min-width: 768px) {
  .hero__section.profile.trial {
    height: 490px;
  }
}
.hero__section.golfer {
  height: 250px;
  background-image: url("../img/ghin_background.jpg");
  background-position: left center;
}
@media screen and (min-width: 768px) {
  .hero__section.golfer {
    height: 300px;
  }
}
@media screen and (min-width: 1280px) {
  .hero__section.golfer {
    height: 375px;
  }
}

@media screen {
  .trial__background {
    height: 624px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center 30%; /* Modified */
    background-size: cover;
    background-image: url("../img/ghin_background3.jpg");
    background-position-y: 60%;
  }
}

@media screen {
  .thanks__section {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: top center;
    background-size: cover;
    background-image: url("../img/ghin_background2.jpg");
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
}
@media screen and (min-width: 768px) {
  .thanks__section {
    height: 624px;
  }
}
@media screen and (min-width: 1280px) {
  .thanks__section {
    height: 700px;
  }
}
@media screen {
  .thanks__section .thanks__container {
    display: flex;
    flex-direction: column;
    background-color: white;
    color: white;
    align-items: center;
    height: 100%;
    padding: 50px;
  }
}

@media screen {
  section:not(.hero__section) .section__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin: 20px 0 20px;
  }
}
@media screen and (min-width: 1024px) {
  section:not(.hero__section) .section__head {
    padding: 0;
    margin: 20px 0 40px 0;
  }
}
@media screen {
  section:not(.hero__section) .section__head .section__title {
    color: #00365f;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
  }
}
@media screen and (min-width: 768px) {
  section:not(.hero__section) .section__head .section__title {
    font-size: 28px;
    line-height: 32px;
  }
}
@media screen {
  section:not(.hero__section) .section__head .section__controls li .section__link {
    color: #00365f;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 32px;
  }
}
@media screen and (min-width: 1024px) {
  section:not(.hero__section) .section__head .section__controls li .section__link {
    font-size: 18px;
  }
}
@media screen {
  section:not(.hero__section) .section__head .section__controls li .section__link .material-icons-outlined {
    font-size: 18px;
  }
}
section:not(.hero__section) .section__head .section__controls li .section__link.default-section-link {
  color: #00365f;
}
section:not(.hero__section) .section__head .section__controls li .section__link.default-section-link:hover {
  color: #cc233c;
}
@media screen and (min-width: 768px) {
  section.post-score, section.stats_section, section.my-card-section {
    width: 730px;
    margin: 0 auto 40px;
  }
}
@media screen and (min-width: 1280px) {
  section.post-score, section.stats_section, section.my-card-section {
    width: 970px;
  }
}

@media screen and (min-width: 1024px) {
  .content.has-aside {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
  }
}
@media screen and (min-width: 1280px) {
  .content.has-aside {
    width: 1220px;
    margin: 0 auto 40px;
  }
}
@media screen {
  .content.has-aside .main {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .content.has-aside .main {
    width: 724px;
  }
}
@media screen and (min-width: 1280px) {
  .content.has-aside .main {
    width: 890px;
  }
}
@media screen {
  .content.has-aside .aside {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width: 1024px) {
  .content.has-aside .aside {
    width: 400px;
    margin-top: 92px;
  }
}
@media screen and (min-width: 1280px) {
  .content.has-aside .aside {
    width: 240px;
  }
}

.hidden {
  display: none !important;
}

.center {
  margin: 0 auto;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.right {
  float: right;
}

.no-results-found {
  text-align: center;
  margin: 0 30px;
  padding: 50px;
}

.pagination_row {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

#back-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-image: url("../img/icons/ic_back-to-top.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  animation: 2s all;
}
#back-top-button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 768px) {
  #back-top-button {
    right: 50px;
  }
}

.golfer_lookup_section {
  margin-bottom: 130px;
}
.golfer_lookup_section .panel {
  box-shadow: none;
}
.golfer_lookup_section select {
  border-radius: 0;
  font-size: 13px;
  font-weight: 500;
  padding: 0 35px 0 10px;
  height: 40px;
}
.golfer_lookup_section label {
  font-family: "National Book";
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
@media screen {
  .golfer_lookup_section .promo_banner {
    display: none;
  }
  .golfer_lookup_section .section_header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 10px;
  }
  .golfer_lookup_section .section_header h3 {
    color: #00365f;
    font-size: 24px;
    font-weight: 300;
    margin: 10px 0;
  }
  .golfer_lookup_section .section_header h1 {
    color: #00365f;
    font-size: 24px;
    font-weight: 300;
    margin: 10px 0;
  }
  .golfer_lookup_section .section_header h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
  }
  .golfer_lookup_section .section_header .tabs {
    margin: 0;
    padding: 0;
  }
  .golfer_lookup_section .section_header .tabs li a {
    font-size: 16px;
    text-transform: none;
    line-height: 1em;
    white-space: nowrap;
    padding: 0.8em 0;
  }
  .golfer_lookup_section .section_header .search-container {
    min-width: 280px;
  }
  .golfer_lookup_section .section_header .search-container input[type=text].search {
    background: #eff0f2;
    padding: 0 40px 0 20px;
  }
  .golfer_lookup_section .section_header .form-group {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .golfer_lookup_section .section_header .form-group:last-child {
    margin: 0;
  }
  .golfer_lookup_section .section_header .form-group .search-container {
    margin: 0;
  }
  .golfer_lookup_section .section_header.filters {
    padding: 35px 25px;
    border-bottom: solid 1px #e5e5e5;
  }
  .golfer_lookup_section .section_header.expand .form-group {
    flex: 1;
  }
}
@media screen and (min-width: 768px) {
  .golfer_lookup_section .promo_banner {
    display: block;
    margin-bottom: 30px;
  }
  .golfer_lookup_section .promo_banner img {
    width: 100%;
  }
  .golfer_lookup_section .section_header {
    flex-direction: row;
    align-items: flex-end;
  }
  .golfer_lookup_section .section_header h3 {
    font-size: 28px;
  }
  .golfer_lookup_section .section_header h1 {
    font-size: 28px;
  }
  .golfer_lookup_section .section_header .tabs li {
    flex: 0 0 auto;
  }
  .golfer_lookup_section .section_header .tabs li a {
    padding: 0.7em 2.3em;
  }
  .golfer_lookup_section .section_header .search-container {
    margin-bottom: 0;
  }
  .golfer_lookup_section .section_header .form-group {
    margin: 0 10px 0 10px;
  }
  .golfer_lookup_section .section_header .form-group:first-child {
    margin-left: 0;
  }
  .golfer_lookup_section .section_header .form-group:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 1280px) {
  .golfer_lookup_section .section_header {
    padding: 20px 0;
  }
  .golfer_lookup_section .section_header h3 {
    font-size: 35px;
  }
  .golfer_lookup_section .section_header h1 {
    font-size: 35px;
  }
}

.page_container {
  width: 100%;
  max-width: 970px;
  margin: 0 auto;
  padding: 20px 0 50px 0;
}
.page_container.contact {
  max-width: 830px;
}
@media screen and (min-width: 768px) {
  .page_container {
    padding: 20px;
  }
}
@media screen and (min-width: 768px) {
  .page_container {
    padding: 30px 0;
  }
}
.page_container input::-ms-reveal,
.page_container input::-ms-clear {
  display: none;
}
.page_container h1 {
  color: #00365f;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.2em;
  margin-bottom: 18px;
}
@media screen and (min-width: 768px) {
  .page_container h1 {
    font-size: 30px;
  }
}
@media screen and (min-width: 1280px) {
  .page_container h1 {
    font-size: 35px;
  }
}
.page_container h3 {
  font-size: 25px;
  line-height: 1em;
}
@media screen and (min-width: 768px) {
  .page_container h3 {
    font-size: 28px;
  }
}
.page_container p,
.page_container select,
.page_container option,
.page_container textarea,
.page_container label {
  font-size: 16px;
  border-color: #e5e5e5;
}
.page_container input[type=text].large,
.page_container input[type=password].large,
.page_container input[type=email].large {
  font-size: 16px;
  border-color: #e5e5e5;
}
.page_container input[type=text].large:focus,
.page_container input[type=password].large:focus,
.page_container input[type=email].large:focus {
  border-color: #e5e5e5;
}
.page_container input[type=text].large.error,
.page_container input[type=password].large.error,
.page_container input[type=email].large.error {
  border-color: #ca243e;
}
@media screen and (min-width: 1280px) {
  .page_container p,
  .page_container select,
  .page_container option,
  .page_container textarea,
  .page_container label {
    font-size: 18px;
  }
}
.page_container p {
  margin: 1em 0;
  line-height: 1.5em;
  font-family: "National", sans-serif;
}
.page_container .top-note {
  margin-top: 0;
  color: #73737d;
  font-size: 90%;
  font-style: italic;
  font-weight: 400;
  position: relative;
  top: 10px;
}
.page_container .help-text {
  margin: 0;
  font-size: 88%;
  color: #73737d;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: right;
}
.page_container .help-text span {
  display: inline-block;
  min-width: 40px;
}
.page_container .text-muted {
  color: #73737d;
}
.page_container .box-content {
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .page_container .box-content {
    padding: 0;
  }
}
.page_container .box-panel {
  background-color: #fff;
  padding: 20px;
}
.page_container .box-panel.center {
  justify-content: center;
  display: flex;
}
.page_container .box-panel.no-margins {
  padding: 0;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .page_container .box-panel {
    padding: 30px 30px;
  }
  .page_container .box-panel.no-margins {
    padding: 0;
    padding: 0;
  }
}
@media screen and (min-width: 1280px) {
  .page_container .box-panel {
    padding: 25px 50px;
  }
  .page_container .box-panel.no-margins {
    padding: 0;
    padding: 0;
  }
}
.page_container .video_wrapper {
  position: relative;
  padding: 28% 0 28% 0;
  height: 0;
}
.page_container .video_wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page_container .form-row {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.page_container .form-row .form-group {
  flex: 1 0 auto;
  padding: 12px 0;
  position: relative;
}
.page_container .form-row .form-group span.error {
  position: absolute;
  bottom: -17px;
  left: 0;
  color: #ca243e;
  font-style: italic;
  font-size: 14px;
}
@media screen and (min-width: 768px) {
  .page_container .form-row {
    flex-direction: row;
    padding: 12px 0;
  }
  .page_container .form-row .form-group {
    flex: 1 0 0;
    padding: 0 5px;
  }
  .page_container .form-row .form-group:first-child {
    padding-left: 0;
  }
  .page_container .form-row .form-group:last-child {
    padding-right: 0;
  }
}
.page_container .buttons-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  max-width: 380px;
  margin: 0 auto;
}
.page_container .buttons-row .lnk {
  text-decoration: none;
  padding-left: 0;
}
.page_container .buttons-row .cardinal {
  flex-grow: 1;
}
.page_container label {
  color: inherit;
  font-weight: 400;
}
.page_container textarea {
  border: 1px solid #e5e5e5;
  width: 100%;
  padding: 20px;
}
.page_container .box-content.fix-right-links {
  padding: 0 50px 0 20px;
}
@media screen and (min-width: 1280px) {
  .page_container .box-content.fix-right-links {
    padding: 0;
  }
}
.page_container.ghin_associations .out_link {
  box-sizing: border-box;
  display: inline-block;
  color: #0067e6;
  background-image: url("../img/icons/ic_out_link.svg");
  background-repeat: no-repeat;
  background-position: right 0;
  background-size: 18px 18px;
  padding-right: 25px;
  line-height: 27px;
  cursor: pointer;
}
.page_container.ghin_associations .hr_anchor {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  line-height: 0.1em;
  margin: 10px 0 0;
  padding-top: 30px;
  position: relative;
  top: -20px;
}
.page_container.ghin_associations .hr_anchor span {
  font-size: 18px;
  color: #00365f;
  background: #fff;
  padding: 0 20px;
}
@media screen and (min-width: 1280px) {
  .page_container.ghin_associations .hr_anchor span {
    font-size: 22px;
  }
}
.page_container.ghin_associations .alinks_wrapper {
  position: fixed;
  right: 1%;
  top: 60px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page_container.ghin_associations .alinks_wrapper .alinks {
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 30px;
  padding: 8px 0;
  max-width: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
}
.page_container.ghin_associations .alinks_wrapper .alinks ::-webkit-scrollbar {
  display: none;
}
.page_container.ghin_associations .alinks_wrapper .alinks a,
.page_container.ghin_associations .alinks_wrapper .alinks a:visited {
  flex: 1 1 auto;
  color: #000;
  padding: 4px 15px;
  font-size: 17px;
  text-align: center;
}
.page_container.ghin_associations .alinks_wrapper .alinks a:hover, .page_container.ghin_associations .alinks_wrapper .alinks a:active,
.page_container.ghin_associations .alinks_wrapper .alinks a:visited:hover,
.page_container.ghin_associations .alinks_wrapper .alinks a:visited:active {
  color: #00365f;
}
@media screen and (min-width: 768px) {
  .page_container.ghin_associations .alinks_wrapper {
    right: 2%;
    top: 140px;
  }
  .page_container.ghin_associations .alinks_wrapper .alinks {
    border: 2px solid #000;
  }
  .page_container.ghin_associations .alinks_wrapper .alinks a,
  .page_container.ghin_associations .alinks_wrapper .alinks a:visited {
    font-size: 18px;
  }
}
@media screen and (min-width: 1280px) {
  .page_container.ghin_associations .alinks_wrapper {
    right: 4%;
  }
  .page_container.ghin_associations .alinks_wrapper .alinks a,
  .page_container.ghin_associations .alinks_wrapper .alinks a:visited {
    font-size: 20px;
  }
}
.page_container.ghin_associations .assoc {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.page_container.ghin_associations .assoc li {
  height: 150px;
  flex: 0 0 100%;
  padding: 20px 0;
}
.page_container.ghin_associations .assoc li h4, .page_container.ghin_associations .assoc li h2 {
  font-size: 19px;
  font-weight: 500;
}
.page_container.ghin_associations .assoc li p {
  font-size: 16px;
  margin: 10px 0;
}
.page_container.ghin_associations .assoc li a,
.page_container.ghin_associations .assoc li a:visited {
  color: rgba(0, 54, 95, 0.8);
}
.page_container.ghin_associations .assoc li a:hover,
.page_container.ghin_associations .assoc li a:visited:hover {
  color: #00365f;
}
@media screen and (min-width: 768px) {
  .page_container.ghin_associations .assoc li {
    flex: 0 0 50%;
    padding: 20px 0;
  }
}
@media screen and (min-width: 1280px) {
  .page_container.ghin_associations .assoc li {
    padding: 30px 0;
  }
  .page_container.ghin_associations .assoc li h4, .page_container.ghin_associations .assoc li h2 {
    font-size: 24px;
  }
  .page_container.ghin_associations .assoc li p {
    font-size: 18px;
  }
}
.page_container.about .logo_header {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
}
.page_container.about .logo_header .ghin {
  display: flex;
  align-items: flex-end;
  background-position: right bottom;
  background-repeat: no-repeat;
  height: 40px;
}
.page_container.about .logo_header .ghin {
  font-size: 24px;
  color: #00365f;
  font-weight: 300;
}
@media screen and (min-width: 768px) {
  .page_container.about .logo_header {
    padding: 30px 0;
  }
  .page_container.about .logo_header .ghin {
    background-size: auto 40px;
    padding-right: 125px;
    font-size: 28px;
  }
}
@media screen and (min-width: 1280px) {
  .page_container.about .logo_header .ghin {
    background-size: auto 40px;
    padding-right: 130px;
    font-size: 34px;
  }
}
.page_container.about .section_links {
  padding: 15px;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
}
.page_container.about .section_links .tabs.light {
  margin: 0;
}
.page_container.about h3 {
  color: #00365f;
  margin-bottom: 20px;
}
.page_container.about p {
  line-height: 2em;
}
.page_container.about .bigstart::first-letter {
  font-size: 200%;
}
.page_container.about .pos-rlt {
  position: relative;
}
.page_container.about .pos-rlt .socials {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
.page_container.about .pos-rlt .socials a,
.page_container.about .pos-rlt .socials button {
  width: 35px;
  height: 35px;
  margin-left: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: auto 16px;
  background-position: center center;
  border: none;
  cursor: pointer;
  text-indent: -99999px;
}
.page_container.about .pos-rlt .socials a.share,
.page_container.about .pos-rlt .socials button.share {
  background-image: url("../img/icons/ic_social_share.svg");
}
.page_container.about .pos-rlt .socials a.twitter,
.page_container.about .pos-rlt .socials button.twitter {
  background-image: url("../img/icons/ic_social_twitter.svg");
  display: none;
}
.page_container.about .pos-rlt .socials a.facebook,
.page_container.about .pos-rlt .socials button.facebook {
  background-image: url("../img/icons/ic_social_fb.svg");
  display: none;
}
.page_container.about .pos-rlt .socials a.linkedin,
.page_container.about .pos-rlt .socials button.linkedin {
  background-image: url("../img/icons/ic_social_linkedin.svg");
  display: none;
}
@media screen and (min-width: 768px) {
  .page_container.about .pos-rlt .socials {
    right: 20px;
    bottom: 20px;
  }
  .page_container.about .pos-rlt .socials a.share,
  .page_container.about .pos-rlt .socials button.share {
    display: none;
  }
  .page_container.about .pos-rlt .socials a.twitter, .page_container.about .pos-rlt .socials a.facebook, .page_container.about .pos-rlt .socials a.linkedin,
  .page_container.about .pos-rlt .socials button.twitter,
  .page_container.about .pos-rlt .socials button.facebook,
  .page_container.about .pos-rlt .socials button.linkedin {
    display: block;
  }
}
@media screen and (min-width: 1280px) {
  .page_container.about .pos-rlt .socials a,
  .page_container.about .pos-rlt .socials button {
    width: 40px;
    height: 40px;
    background-size: auto 17px;
  }
}
.page_container.create-profile-page h4 {
  color: #4c7b93;
  margin-bottom: 20px;
  margin-top: -10px;
}

@media screen {
  .stats__container.margin00200 {
    margin-bottom: 20px;
  }
}
@media screen {
  .stats__container .separator {
    height: 1px;
    margin-bottom: 25px;
    background-color: #e5e5e5;
  }
}
@media screen and (min-width: 768px) {
  .stats__container .separator {
    border-right: 1px solid #e5e5e5;
    height: 130px;
  }
}
@media screen {
  .stats__container .stats__row {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .stats__container .stats__row {
    flex-direction: row;
  }
}
@media screen {
  .stats__container .stats__row .stats__column {
    margin: 0 0 25px 0;
  }
}
@media screen and (min-width: 768px) {
  .stats__container .stats__row .stats__column {
    flex: 1 1 0;
  }
}
@media screen and (min-width: 768px) {
  .stats__container .stats__row .stats__column:first-of-type {
    margin: 0 10px 0 0;
  }
}
@media screen and (min-width: 768px) {
  .stats__container .stats__row .stats__column:last-of-type {
    margin: 0 0 0 10px;
  }
}
@media screen {
  .stats__container .stats__row .stats__column.combined-score {
    align-self: flex-start;
  }
}
@media screen and (min-width: 768px) {
  .stats__container .stats__row .stats__column.combined-score {
    align-self: center;
  }
}
.stats__container .stats__row .stats__column .combined-score-info {
  color: #999999;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-left: 25px;
  margin-top: 5px;
}
.stats__container .stats__row .stats__column .combined-score-info .title {
  font-size: 19px;
  font-weight: bold;
}

.auth__container {
  max-width: 450px;
  min-width: 350px;
}
.auth__container .center {
  text-align: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}
.auth__container .bottom-actions {
  display: flex;
  flex-direction: row;
  margin-top: 2.5em;
  margin-bottom: 2em;
  justify-content: center;
}
.auth__container .bottom-link {
  font-size: 16px;
  line-height: 1.2em;
  font-family: "National", sans-serif;
  text-align: center;
}
.auth__container .bottom-link a {
  text-decoration: underline;
  color: #000;
}
.auth__container .success-icon {
  height: 50px;
  background: url("../img/icons/check_icon.svg") no-repeat center;
  margin-top: 2em;
}
.auth__container .success-title {
  color: #00365f;
  text-align: center;
  font-size: 1.4em;
  margin-top: 1.5em;
  margin-bottom: 2.5em;
}

@media screen {
  .tabs-container {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .tabs-container {
    display: block;
    height: 40px;
    border-bottom: solid 1px #e5e5e5;
  }
}
@media screen {
  .tabs-container .nav__links {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
}
@media screen {
  .tabs-container .nav__links li .nav__link {
    font-size: 19px;
    line-height: 40px;
    font-weight: 500;
    letter-spacing: 0.5px;
    white-space: nowrap;
    padding: 0 10px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease;
    color: #00365f;
  }
}
@media screen and (min-width: 1280px) {
  .tabs-container .nav__links li .nav__link {
    letter-spacing: 1.8px;
  }
}
.tabs-container .nav__links li .nav__link:hover {
  background: rgba(0, 136, 206, 0.2);
}
.tabs-container .nav__links li .nav__link.is-active {
  color: #fff;
  background-color: #00365f;
}

@media screen {
  .usga_logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #00365f;
    font-size: 13px;
    line-height: 12px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 100%;
    background-color: #fff;
    position: relative;
    z-index: 9;
    cursor: pointer;
  }
}
@media screen and (min-width: 1280px) {
  .usga_logo {
    font-size: 16px;
    line-height: 16px;
  }
}
@media screen {
  .usga_logo::before {
    content: "";
    display: block;
    width: 80px;
    height: 24px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: contain;
    background-image: url("../img/logos/logo_usga.svg");
    margin: 0 8px 0 0;
  }
}
@media screen and (min-width: 1280px) {
  .usga_logo::before {
    width: 90px;
    height: 32px;
  }
}

@media screen {
  .menu-ham__container {
    margin: 0 16px 0 0;
    height: 100%;
    background-color: #fff;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
@media screen and (min-width: 1280px) {
  .menu-ham__container {
    margin: 0 16px;
  }
}
@media screen and (min-width: 768px) {
  .menu-ham__container.hide-on-tablet-and-above {
    display: none;
  }
}
.menu-ham__container p.invisible {
  display: none;
}
@media screen {
  .menu-ham__container .menu-ham__trigger {
    width: 24px;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 9;
  }
}
.menu-ham__container .menu-ham__trigger.border-focus:focus-visible {
  outline: 3px solid red;
}
@media screen {
  .menu-ham__container .menu-ham__trigger span {
    display: block;
    width: 24px;
    height: 3px;
    background-color: #00365f;
    margin: 0 0 3px 0;
    will-change: transform, opacity;
    transform-origin: center center;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
    border-radius: 1px;
  }
}
@media screen {
  .menu-ham__container .menu-ham__trigger span:last-child {
    margin: 0;
  }
}
@media screen {
  .menu-ham__container .menu-ham__trigger + .menu-ham__content {
    width: 100vw;
    min-height: calc(100vh - 60px);
    position: absolute;
    z-index: 99;
    top: 60px;
    left: -100%;
    background-color: #fff;
    opacity: 0;
    will-change: position, opacity;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen and (min-width: 768px) {
  .menu-ham__container .menu-ham__trigger + .menu-ham__content {
    min-height: calc(100vh - 72px);
    top: 72px;
    width: 300px;
  }
}
@media screen and (min-width: 1280px) {
  .menu-ham__container .menu-ham__trigger + .menu-ham__content {
    min-height: calc(100vh - 90px);
    top: 90px;
  }
}
@media screen {
  .menu-ham__container .menu-ham__trigger.is-active span {
    opacity: 0;
    margin: 0;
  }
}
@media screen {
  .menu-ham__container .menu-ham__trigger.is-active span:first-child, .menu-ham__container .menu-ham__trigger.is-active span:last-child {
    height: 2px;
  }
}
@media screen {
  .menu-ham__container .menu-ham__trigger.is-active span:first-child {
    opacity: 1;
    -webkit-transform: translate3d(0, 2px, 0) rotate(45deg);
    -moz-transform: translate3d(0, 2px, 0) rotate(45deg);
    -ms-transform: translate3d(0, 2px, 0) rotate(45deg);
    transform: translate3d(0, 2px, 0) rotate(45deg);
  }
}
@media screen {
  .menu-ham__container .menu-ham__trigger.is-active span:last-child {
    opacity: 1;
    -webkit-transform: translate3d(0, -3px, 0) rotate(-45deg);
    -moz-transform: translate3d(0, -3px, 0) rotate(-45deg);
    -ms-transform: translate3d(0, -3px, 0) rotate(-45deg);
    transform: translate3d(0, -3px, 0) rotate(-45deg);
  }
}
@media screen {
  .menu-ham__container .menu-ham__trigger.is-active + .menu-ham__content {
    position: absolute;
    left: 0;
    background-color: #fff;
    opacity: 1;
    max-height: 50vh;
    overflow: scroll;
  }
}
@media screen and (min-width: 1280px) {
  .menu-ham__container .menu-ham__trigger.is-active + .menu-ham__content {
    top: 90px;
    left: 0;
  }
}

.menu__links__wrapper {
  overflow: auto;
  height: 90vh;
  padding-bottom: 60px;
}

@media screen {
  .menu__links {
    padding: 0 0 20px 0;
  }
}
@media screen and (min-width: 768px) {
  .menu__links {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .menu__links:not(:first-child) {
    padding: 20px 0 0 0;
    border-top: solid 1px #eff0f2;
  }
}
@media screen {
  .menu__links li {
    padding: 5px 20px 5px 60px;
  }
}
@media screen and (min-width: 1280px) {
  .menu__links li {
    padding: 5px 20px 5px 65px;
  }
}
@media screen and (min-width: 1440px) {
  .menu__links li {
    padding: 5px 20px 5px 60px;
  }
}
@media screen {
  .menu__links li .menu__link {
    color: #00365f;
    font-size: 16px;
    line-height: 40px;
    font-weight: 500;
    letter-spacing: 0.5px;
    display: block;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: all 0.25s ease;
  }
  .menu__links li .menu__link.is-active, .menu__links li .menu__link:hover {
    color: #cc233c;
  }
  .menu__links li .menu__link.is-active span, .menu__links li .menu__link:hover span {
    display: inline-block;
    position: relative;
  }
  .menu__links li .menu__link.is-active span::after, .menu__links li .menu__link:hover span::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    bottom: 6px;
    border-bottom: 2px solid #cc233c;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-1 {
    order: 1;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-2 {
    order: 2;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-3 {
    order: 3;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-4 {
    order: 4;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-5 {
    order: 5;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-6 {
    order: 6;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-7 {
    order: 7;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-8 {
    order: 8;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-9 {
    order: 9;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-10 {
    order: 10;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-11 {
    order: 11;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-12 {
    order: 12;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-13 {
    order: 13;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-14 {
    order: 14;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-15 {
    order: 15;
  }
}
@media screen and (min-width: 768px) {
  .menu__links li.order-16 {
    order: 16;
  }
}
@media screen {
  .menu__links.compact li {
    padding: 5px 40px;
  }
}

@media screen {
  .user-menu__container {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .user-menu__container {
    position: relative;
    margin: 0 0 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (min-width: 1280px) {
  .user-menu__container {
    margin: 0 15px 0 auto;
  }
}
@media screen {
  .user-menu__container .user-menu__trigger {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #00365f;
    font-family: "National Book", sans-serif;
    font-size: 16px;
    line-height: 40px;
    font-weight: 500;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    padding: 0 32px 0 0;
  }
}
.user-menu__container .user-menu__trigger.border-focus:focus-visible {
  outline: 3px solid red;
}
@media screen and (min-width: 1280px) {
  .user-menu__container .user-menu__trigger {
    font-size: 20px;
  }
}
@media screen {
  .user-menu__container .user-menu__trigger .material-icons-outlined {
    color: #00365f;
    width: 24px;
    flex: none;
    position: absolute;
    right: 0;
  }
}
@media screen {
  .user-menu__container .user-menu__trigger .material-icons-outlined.more {
    display: block;
  }
}
@media screen {
  .user-menu__container .user-menu__trigger .material-icons-outlined.less {
    display: none;
  }
}
@media screen {
  .user-menu__container .user-menu__trigger + .user-menu__content {
    display: none;
    position: absolute;
    top: 72px;
    right: -20px;
    width: 240px;
    background-color: #fff;
  }
}
@media screen and (min-width: 1024px) {
  .user-menu__container .user-menu__trigger + .user-menu__content {
    right: -25px;
  }
}
@media screen {
  .user-menu__container .user-menu__trigger.is-active .material-icons-outlined.more {
    display: none;
  }
}
@media screen {
  .user-menu__container .user-menu__trigger.is-active .material-icons-outlined.less {
    display: block;
  }
}
@media screen {
  .user-menu__container .user-menu__trigger.is-active + .user-menu__content {
    display: block;
    text-transform: uppercase;
  }
}

@media screen {
  .notifications__container {
    margin: 0 0 0 auto;
  }
}
@media screen {
  .notifications__container .notifications__trigger {
    width: 32px;
    height: 32px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
}
@media screen {
  .notifications__container .notifications__trigger .material-icons-outlined {
    color: #00365f;
    font-size: 24px;
    line-height: 32px;
    display: block;
  }
}
@media screen {
  .notifications__container .notifications__trigger .notifications__value {
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    min-width: 20px;
    display: inline-block;
    background-color: #cc233c;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: -8px;
  }
}
@media screen {
  .notifications__container .notifications__content {
    display: none;
  }
}

.login_form input[type=text],
.login_form input[type=password] {
  font-family: National, sans-serif;
  border-radius: 4.5px;
  margin-bottom: 5px;
  font-size: 18.5px;
  height: 43px;
}
.login_form input[type=text]:focus-visible,
.login_form input[type=password]:focus-visible {
  border: 3px solid red;
}
.login_form label {
  font-family: National, sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.login_form span.error {
  color: #aa1d32;
  font-size: 13px;
  line-height: 15px;
  font-weight: 600;
  width: fit-content;
  background: rgba(255, 255, 255, 0.8);
  padding: 3px;
  border-radius: 5px;
}
.login_form span.error:empty {
  padding: 0;
}
.login_form div.hidden-text {
  line-height: 0;
  font-size: 0;
  color: transparent;
}
.login_form .show_password_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.login_form .show_password {
  position: absolute;
  align-self: flex-end;
}
.login_form .show_button {
  padding: 0.3em 1.4em;
  text-decoration: none;
}
.login_form .infoLabel {
  color: white;
  font-family: National, sans-serif;
  font-size: 1.3em;
  line-height: 1.1em;
  font-weight: 400;
  margin-bottom: 15px;
  white-space: pre-wrap;
}
.login_form .infoLabel a:link {
  color: white;
  text-decoration: underline;
}
.login_form .infoLabel a:visited {
  color: white;
}
.login_form .infoLabel a:focus-visible {
  color: red;
}

.recaptcha_policy {
  font-size: 14px;
  text-align: center;
  color: white;
  word-spacing: 0.06rem;
  margin-bottom: 3px;
}
.recaptcha_policy a {
  color: white;
  display: inline-block;
  text-decoration: underline;
  margin-inline: 0.3rem;
  text-decoration-color: rgba(255, 255, 255, 0.7);
}

.login_container {
  display: flex; /* Use flexbox layout */
  flex: 1;
  justify-content: space-between; /* Align items with space between them */
  align-items: center; /* Align items at the start of the cross axis */
  background: #fff; /* White background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 0px 0px; /* 30px top and bottom, 20px left and right */
  max-width: 1000px; /* Maximum width of the form */
  margin: auto; /* Centering the form */
  margin-top: 20px;
  width: 90%;
  flex-direction: column;
  padding-bottom: 20px;
}
@media screen and (min-width: 414px) {
  .login_container {
    padding: 0px 20px;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 768px) {
  .login_container {
    padding: 0px 20px;
    margin: 50px 50px;
    width: 70%;
    flex-direction: row;
  }
}

@media screen {
  .login__title {
    color: #00365f;
    font-size: 23px;
    text-align: center;
    margin: 0 0 30px 0;
  }
}
@media screen and (min-width: 1024px) {
  .login__title {
    font-size: 23px;
    line-height: 1.2;
  }
}
@media screen and (min-width: 1280px) {
  .login__title {
    font-size: 23px;
  }
}
.login__title.sub-title {
  color: #00365f;
  font-weight: 500;
  font-size: 23px;
}

.welcome__header {
  text-align: center;
  color: white;
  padding-top: 70px;
  font-size: 45px;
}

.welcome__header_small {
  text-align: center;
  color: white;
  font-size: 17px;
  padding-top: 10px;
}

.separator {
  border-left: 2px solid rgba(149, 148, 148, 0.443);
  height: 350px;
  top: 0px;
  position: relative;
}

@media screen {
  .create-trial {
    height: 100%;
    padding: 0px 25px;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .create-trial {
    margin: 0;
  }
}
@media screen {
  .create-trial .create-trial__title {
    color: #aa1d32;
    font-size: 23px;
    line-height: 1;
    text-align: center;
    margin: 0 0 30px 0;
    font-weight: bold;
  }
}
@media screen and (min-width: 1024px) {
  .create-trial .create-trial__title {
    font-size: 23px;
    line-height: 1.2;
  }
}
@media screen and (min-width: 1280px) {
  .create-trial .create-trial__title {
    font-size: 23px;
  }
}
.create-trial .create-trial__title.sub-title {
  color: #00365f;
  font-weight: 500;
}

.create-trial-list {
  list-style-type: disc;
  margin-left: 20px;
}

.create-trial-list li {
  margin-bottom: 10px;
}

@media screen {
  .create-profile {
    height: 100%;
    padding: 35px 25px;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .create-profile {
    margin: 0;
  }
}
@media screen {
  .create-profile .create-profile__title {
    color: #aa1d32;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    margin: 0 0 30px 0;
    font-weight: bold;
  }
}
@media screen and (min-width: 1024px) {
  .create-profile .create-profile__title {
    font-size: 26px;
    line-height: 1.2;
  }
}
@media screen and (min-width: 1280px) {
  .create-profile .create-profile__title {
    font-size: 26px;
  }
}
.create-profile .create-profile__title.sub-title {
  color: #00365f;
  font-weight: normal;
}
@media screen {
  .create-profile p {
    font-size: 13px;
    line-height: 1.2em;
    color: #4e515e;
    margin: 15px 0 0 0;
    text-align: center;
  }
}
@media screen and (min-width: 1024px) {
  .create-profile p {
    font-size: 16px;
  }
}
@media screen {
  .create-profile p a {
    color: #000;
    text-decoration: underline;
    position: relative;
  }
}
@media screen {
  .create-profile p a sup {
    vertical-align: super;
    position: absolute;
    right: -10px;
    top: -6px;
  }
}
.create-profile .button-container {
  align-self: center;
}
.create-profile .separator {
  background-color: #e8e8e8;
  height: 0.5px;
  margin-bottom: 1.8em;
}
.create-profile .separator.transparent {
  height: 0px;
}
.create-profile .separator.mt {
  margin-top: 1.8em;
}
.create-profile .field-place-holder {
  display: flex;
  margin-top: 6em;
}
.create-profile .top-label {
  font-size: 16px;
  line-height: 1.2em;
  font-family: "National", sans-serif;
  padding-bottom: 15px;
}
.create-profile .create_form input[type=text],
.create-profile .create_form input[type=email],
.create-profile .create_form input[type=password],
.create-profile .create_form input[type=number] {
  font-family: "National", sans-serif;
  border-radius: 1.5px;
  font-size: 18px;
  height: 43px;
}
.create-profile .create_form input[type=text-disabled] {
  font-family: National, sans-serif;
  font-size: 18px;
  height: 43px;
  background-color: transparent;
  border-width: 0px;
  margin-left: -1px;
  margin-top: -15px;
}
.create-profile .create_form label {
  margin-top: 10px;
  font-family: National, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-weight: 500;
}
.create-profile .create_form label.disable {
  margin-top: -10px;
  font-family: National, sans-serif;
  font-size: 18px;
}
.create-profile .create_form label.terms {
  font-family: National, sans-serif;
  font-size: 16px;
}
.create-profile .create_form span.error {
  color: red;
  font-size: 15px;
  line-height: 15px;
  font-family: National, sans-serif;
  margin-top: 8px;
}
.create-profile .radio-icon {
  cursor: pointer;
  border: none;
  width: 28px;
  height: 28px;
  background: url("../img/icons/radio_unchecked.svg") no-repeat center;
  margin-right: 8px;
}
.create-profile .radio-icon.checked {
  background: url("../img/icons/radio_checked.svg") no-repeat center;
}
@media screen {
  .create-profile input[type=checkbox].radio {
    display: none;
  }
}
.create-profile input[type=checkbox].radio + label .link {
  text-decoration: underline;
  color: #000;
}
@media screen {
  .create-profile input[type=checkbox].radio + label {
    font-family: National, sans-serif;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    flex-direction: row;
    margin: 0;
    cursor: pointer;
    position: relative;
  }
}

.show_password_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.show_password {
  position: absolute;
  align-self: flex-end;
  height: 35px;
}
.show_password button {
  height: 35px;
  padding-top: 0;
  padding-bottom: 0;
}

.show_button {
  padding: 0.3em 1.4em;
  color: #4e515e;
}

.registration_container {
  background: #fff; /* White background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 0px; /* Space inside the box 0*/
  max-width: 200px; /* Maximum width of the form 200*/
  margin: 50px auto; /* Centering the form 10*/
}

.registration_form {
  padding: 20px;
  margin-top: 0px;
}
.registration_form input[type=email]:focus-visible,
.registration_form input[type=text]:focus-visible,
.registration_form input[type=password]:focus-visible {
  border: 3px solid red;
}

.trial_form {
  padding: 40px 20px;
}
@media screen and (min-width: 768px) {
  .trial_form {
    padding: 60px 40px 30px 40px;
  }
}

.lnk {
  margin-left: auto;
  margin-right: auto;
}

.registration_form h2 {
  color: #333; /* Dark text for the title */
  margin-bottom: 10px; /* Space below the title */
}

.registration_form p {
  color: #666; /* Lighter text for the subtitle */
  margin-bottom: 20px; /* Space below the subtitle */
}

.registration_form label {
  display: block; /* Ensure labels are on their own line */
  margin-bottom: 7px; /* Space below each label */
}

.registration_form input[type=text],
.registration_form input[type=password],
.registration_form input[type=radio] {
  width: 100%; /* Full width fields */
  padding: 10px; /* Padding inside the fields */
  margin-bottom: 15px; /* Space below the fields */
  border: 1px solid #ccc; /* Border around the fields */
  border-radius: 4px; /* Rounded corners on the fields */
}

.registration_form .row {
  margin-bottom: 10px; /* Space between rows */
}

.registration_form .error {
  color: red; /* Error message color */
  font-size: 0.8rem; /* Smaller font size for error messages */
  display: inline-block;
  height: 0px;
  width: 100%;
}

.registration_form .btn {
  width: 100%; /* Full width buttons */
  padding: 10px; /* Padding inside the buttons */
  margin-top: 10px; /* Space above the buttons */
  border: none; /* No border for buttons */
  border-radius: 40px; /* Rounded corners for buttons */
  background-color: #CE0E2D; /* Red background for primary button */
  color: #00365f; /* White text for buttons */
  cursor: pointer; /* Pointer cursor on hover */
}

.registration_form .btn:hover {
  background-color: #c0392b; /* Darker red on hover */
  color: white;
}

.registration_form .link {
  text-align: center; /* Center link text */
  display: block; /* Ensure links are on their own line */
  margin-top: 15px; /* Space above the link */
  color: #3498db; /* Blue color for links */
  text-decoration: none; /* No underline on links */
}

.registration_form .link:hover {
  text-decoration: underline; /* Underline on hover */
}

.gender-inputs .gender-option-male {
  display: inline-block;
  margin-right: 0px; /* Spacing between options */
  position: relative;
  padding: 10px 17px;
  border: 1px solid #ccc; /* Border color */
  border-radius: 4px 0px 0px 4px; /* Rounded corners for the border */
  cursor: pointer;
}

.gender-inputs .gender-option-female {
  display: inline-block;
  margin-right: 0px; /* Spacing between options */
  position: relative;
  padding: 10px 10px;
  border: 1px solid #ccc; /* Border color */
  border-radius: 0 4px 4px 0; /* Rounded corners for the border */
  cursor: pointer;
}

.gender-inputs .gender-radio {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.gender-checked {
  background-color: #cc233c;
  color: white;
}

.input-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 414px) {
  .input-group {
    flex-direction: row;
  }
}

.col.is-half {
  padding-right: 8px; /* Adjust the spacing between inputs */
}
@media screen and (min-width: 414px) {
  .col.is-half {
    padding-right: 50px;
  }
}

.gender-inputs {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
@media screen and (min-width: 414px) {
  .gender-inputs {
    margin-top: 0px;
  }
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns children to the start of the cross axis */
  justify-content: center;
  margin: 0 auto; /* Centers the container */
  width: fit-content; /* Shrink-wraps the content */
}

.step-container {
  display: flex;
  align-items: center;
  margin-bottom: 0px; /* Space between steps */
}

.step-number {
  color: #CE0E2D;
  font-size: 78px;
  font-weight: 300;
  font-family: "National", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; /* Space between number and text */
}

.step-subtext {
  color: #333;
}

.step-text {
  color: #043767;
  display: flex;
  margin-bottom: 5px;
  font-size: 20px;
  flex-direction: column;
}

.step-text h3, .step-text p {
  margin: 0;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkboxLabelContainer {
  display: flex;
  align-items: flex-start;
}

.checkboxLabel {
  margin-left: 0px;
}

.overlay {
  position: relative;
  border-radius: 8px; /* Rounded corners */
  max-width: 350px; /* Adjust as necessary */
  margin: auto;
  margin-top: 0px;
  margin-bottom: 30px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.973);
  text-align: center;
  padding: 20px;
}
@media screen and (min-width: 768px) {
  .overlay {
    max-width: 500px;
    margin-top: 60px;
    margin-bottom: 0px;
    right: 30px;
  }
}

.overlay img {
  max-width: 100%;
  height: auto;
}

.overlay-title {
  font-size: 18px;
  color: #043767;
  margin: 16px 0;
}
@media screen and (min-width: 1024px) {
  .overlay-title {
    font-size: 20px;
  }
}

.overlay-image-cover {
  height: 70px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .overlay-image-cover {
    height: 120px;
  }
}

.overlay-subtext {
  font-size: 12px;
  margin-bottom: 16px;
  padding: 15px 15px;
}
@media screen and (min-width: 1024px) {
  .overlay-subtext {
    font-size: 16px;
  }
}

.overlay button {
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  border-radius: 15px 15px 15px 15px;
  letter-spacing: 0.1px;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .overlay button {
    font-size: 15px;
  }
}

.thanks-button {
  margin-top: 70px;
  margin-bottom: 30px;
}

.forgot-login {
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
}
.forgot-login.no-padding {
  padding: 0;
}
.forgot-login .top-label {
  font-size: 18px;
  line-height: 1.2em;
  font-family: "National", sans-serif;
  padding-bottom: 25px;
  font-weight: 500;
}
.forgot-login .forgot-form input[type=text],
.forgot-login .forgot-form input[type=password] {
  font-family: "National", sans-serif;
  border-radius: 1.5px;
  font-size: 16px;
  height: 43px;
}
.forgot-login .forgot-form input[type=text-disabled] {
  font-family: National, sans-serif;
  font-size: 18px;
  height: 43px;
  background-color: transparent;
  border-width: 0px;
  margin-top: -10px;
  margin-left: -1px;
}
.forgot-login .forgot-form label {
  margin-top: 10px;
  font-family: National, sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.forgot-login .forgot-form label.disable {
  font-family: National, sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.forgot-login .forgot-form span.error {
  color: red;
  font-size: 15px;
  line-height: 15px;
  font-family: National, sans-serif;
  margin-top: 8px;
  margin-top: 8px;
}
.forgot-login .show_password_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.forgot-login .show_password {
  position: absolute;
  align-self: flex-end;
}
.forgot-login .show_button {
  padding: 0.3em 1.4em;
  color: #4e515e;
}

.field_place_holder {
  display: flex;
  margin-top: 6em;
}

.select-golfer {
  padding-top: 35px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.select-golfer .bottom_action {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.select-golfer .golfer-item {
  flex-direction: column;
  align-self: flex-center;
  max-width: 450px;
  min-width: 250px;
  align-items: center;
  justify-content: center;
}
.select-golfer .golfer-item.top {
  margin-top: -10px;
}
.select-golfer .golfer-item-box {
  display: flex;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  align-items: center;
}
.select-golfer .golfer-item-box .name-wrapper {
  margin-left: 20px;
}
.select-golfer .golfer-item-box .name-wrapper .name {
  font-size: 18px;
  font-family: "National", sans-serif;
  text-align: left;
  padding-bottom: 8px;
  font-weight: 500;
}
.select-golfer .golfer-item-box .name-wrapper .number {
  font-size: 16px;
  font-family: "National", sans-serif;
}
.select-golfer .golfer-item-box .icon {
  cursor: pointer;
  border: none;
  width: 26px;
  height: 26px;
  background: url("../img/icons/radio_unchecked.svg") no-repeat center;
}
.select-golfer .golfer-item-box .icon.checked {
  background: url("../img/icons/radio_checked.svg") no-repeat center;
}
.select-golfer .golfer-item-box .icon input[type=radio] {
  position: absolute;
  margin: 0;
  width: 1px;
  height: 1px;
}
.select-golfer .golfer-item-box .icon:focus-within {
  border: 3px solid red;
  border-radius: 100%;
}

.separator-label {
  font-size: 16px;
  font-family: "National", sans-serif;
  color: #00365f;
  font-weight: 500;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-top: solid 1px #e5e5e5;
  border-bottom: solid 1px #e5e5e5;
  width: 100%;
  padding-right: 2em;
}

@media screen {
  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin: 0 0 20px 0;
  }
}
@media screen {
  .tabs.rounded li {
    flex: 1 1 0;
    border-right: solid 1px #eff0f2;
    overflow: hidden;
  }
}
@media screen and (min-width: 768px) {
  .tabs.rounded li {
    flex: 0 0 160px;
  }
}
@media screen {
  .tabs.rounded li:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}
@media screen {
  .tabs.rounded li:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-right: none;
  }
}
@media screen {
  .tabs.rounded li a {
    width: 100%;
    color: #00365f;
    background-color: #fff;
    font-size: 14px;
    line-height: 32px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
  }
}
@media screen {
  .tabs.rounded li a.is-active {
    color: #fff;
    background-color: #00365f;
  }
}
@media screen {
  .tabs.light {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0 25px 0;
  }
}
@media screen {
  .tabs.light li {
    margin: 0 30px;
  }
}
@media screen {
  .tabs.light li:first-child {
    margin: 0 30px 0 0;
  }
}
@media screen {
  .tabs.light li:first-child {
    margin: 0 0 0 30px;
  }
}
@media screen {
  .tabs.light li a {
    color: #00365f;
    font-size: 15px;
    line-height: 25px;
    font-weight: 600;
    display: block;
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: nowrap;
  }
}
@media screen {
  .tabs.light li a.is-active {
    color: #cc233c;
    border-bottom: solid 2px #cc233c;
  }
}
.tabs.auto {
  margin: 0;
  padding: 0;
}
.tabs.auto li {
  flex: 0 0 auto;
  border: 1px solid #e5e5e5;
}
.tabs.auto li a {
  padding: 0.2em 2em;
  text-transform: none;
}
.tabs.auto li + li {
  margin-left: -1px;
}
.tabs.auto li:last-child {
  border-right: 1px solid #e5e5e5;
}

@media screen {
  .events__list {
    background-color: #fff;
  }
}
@media screen {
  .events__list li {
    border-bottom: solid 1px #e5e5e5;
  }
}
@media screen {
  .events__list li .event {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .events__list li .event {
    padding: 30px;
  }
}
@media screen {
  .events__list li .event .event__date {
    flex: none;
    width: 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px 0 0;
    margin: 0 20px 0 0;
    border-right: solid 1px #e5e5e5;
    box-sizing: content-box;
  }
}
@media screen {
  .events__list li .event .event__date .date-month {
    color: #305774;
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
  }
}
@media screen {
  .events__list li .event .event__date .date-day {
    color: #000;
    font-size: 22px;
    line-height: 24px;
  }
}
@media screen {
  .events__list li .event .event__details {
    display: flex;
    flex-direction: column;
    padding: 0 20px 0 0;
  }
}
@media screen {
  .events__list li .event .event__details .event__details__name {
    color: #305774;
    font-size: 16px;
    line-height: 18px;
    max-height: 36px;
    overflow: hidden;
  }
}
@media screen and (min-width: 768px) {
  .events__list li .event .event__details .event__details__name {
    font-size: 20px;
    line-height: 20px;
    max-width: 100%;
    max-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen {
  .events__list li .event .event__details .event__details__location {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    max-height: 40px;
    overflow: hidden;
  }
}
@media screen and (min-width: 768px) {
  .events__list li .event .event__details .event__details__location {
    font-size: 14px;
  }
}
@media screen {
  .events__list li .event .material-icons-outlined {
    flex: none;
    color: #00365f;
    margin: 0 0 0 auto;
  }
}

@media screen {
  .profile-head__container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .profile-head__container {
    height: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    padding-right: 10px;
  }
}
@media screen and (min-width: 1280px) {
  .profile-head__container {
    width: 1230px;
    margin: 0 auto;
    padding-right: 0;
  }
}
.profile-head__container .profile__details.trial {
  margin: auto;
  margin-top: 20px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .profile-head__container .profile__details.trial {
    text-align: left;
    margin: auto;
  }
}
@media screen {
  .profile-head__container .profile__details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
  }
}
@media screen and (min-width: 768px) {
  .profile-head__container .profile__details {
    flex: none;
    width: 65%;
    text-align: left;
    padding: 0 0 0 60px;
  }
}
@media screen and (min-width: 1280px) {
  .profile-head__container .profile__details {
    padding: 0 0 0 40px;
  }
}
@media screen {
  .profile-head__container .profile__details .profile__name {
    font-family: "National Book", sans-serif;
    color: #fff;
    font-size: 35px;
    line-height: 1;
    font-weight: 400;
    margin: 0 0 10px 0;
  }
}
@media screen and (min-width: 768px) {
  .profile-head__container .profile__details .profile__name {
    font-size: 53px;
  }
}
@media screen and (min-width: 1280px) {
  .profile-head__container .profile__details .profile__name {
    font-size: 66px;
  }
}
@media screen {
  .profile-head__container .profile__details .profile__action {
    width: 190px;
  }
}
.profile-head__container .profile__details .profile__action.trial {
  margin: auto;
  margin-top: 0px;
}
@media screen and (min-width: 768px) {
  .profile-head__container .profile__details .profile__action.trial {
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .profile-head__container .profile__details .profile__action {
    margin: 0;
  }
}
@media screen {
  .profile-head__container .profile__details .profile__ghin {
    color: #fff;
    font-size: 14px;
    line-height: 1;
    font-weight: 300;
    margin-top: -10px;
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1280px) {
  .profile-head__container .profile__details .profile__ghin {
    font-size: 18px;
    margin-top: -20px;
    margin-bottom: 20px;
  }
}
@media screen {
  .profile-head__container .profile__memberships {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .profile-head__container .profile__memberships {
    display: block;
    align-self: center;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 15px 25px;
    width: 280px;
    margin: 40px auto 0;
    max-height: 250px;
    overflow-y: auto;
  }
  .profile-head__container .profile__memberships h4, .profile-head__container .profile__memberships h3 {
    font-size: 16px;
    font-weight: 600;
    color: #00365f;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 8px;
  }
  .profile-head__container .profile__memberships ul {
    list-style: none;
  }
  .profile-head__container .profile__memberships ul li {
    padding: 10px 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.9);
    letter-spacing: 0.5px;
  }
  .profile-head__container .profile__memberships ul li .assoc {
    font-size: 15px;
    color: black;
    margin-bottom: 1px;
  }
  .profile-head__container .profile__memberships ul li .club {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
  }
  .profile-head__container .profile__memberships ul li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 1280px) {
  .profile-head__container .profile__memberships {
    padding: 20px 30px 15px;
    width: 340px;
    max-height: 292px;
  }
  .profile-head__container .profile__memberships h4, .profile-head__container .profile__memberships h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .profile-head__container .profile__memberships ul li {
    padding: 12px 0;
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  .profile-head__container .profile__memberships ul li {
    display: flex;
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .profile-head__container .profile__memberships ul li .club-logo__container .club-logo {
    height: 34px;
    width: auto;
    max-width: 34px;
    margin: 0 10px 0 0;
    display: block;
    object-fit: contain;
  }
}
@media screen and (min-width: 768px) {
  .profile-head__container .profile__memberships ul li .club-logo__container:empty + .club__details {
    margin-left: 44px;
  }
}
@media screen and (min-width: 768px) {
  .profile-head__container .profile__memberships ul li .club__details .assoc {
    margin-bottom: 2px;
  }
}
.member_slides_pos {
  height: 0;
  overflow: visible;
  position: relative;
  top: -100px;
}
.member_slides_pos .memberships_slideshow {
  height: 100px;
  padding: 10px 0;
  background: rgba(242, 242, 242, 0.8);
}
.member_slides_pos .memberships_slideshow h4 {
  font-size: 14px;
  font-weight: 700;
  color: #00365f;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0 40px;
}
.member_slides_pos .memberships_slideshow .slideshow-container {
  position: relative;
  margin: 13px 0 15px;
}
.member_slides_pos .memberships_slideshow .slideshow-container .slide {
  padding: 0 0 0 40px;
}
.member_slides_pos .memberships_slideshow .slideshow-container .slide .assoc {
  font-size: 15.5px;
  line-height: 1.2em;
}
.member_slides_pos .memberships_slideshow .slideshow-container .slide .club {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 1.2em;
}
.member_slides_pos .memberships_slideshow .prev, .member_slides_pos .memberships_slideshow .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  font-weight: 100;
  transition: 0.6s ease;
  user-select: none;
  background: none;
  border: none;
  box-shadow: none;
}
.member_slides_pos .memberships_slideshow .prev {
  left: 0;
}
.member_slides_pos .memberships_slideshow .next {
  right: 0;
}
.member_slides_pos .memberships_slideshow .prev:hover, .member_slides_pos .memberships_slideshow .next:hover {
  color: rgba(0, 0, 0, 0.8);
  background: none;
  border: none;
  box-shadow: none;
}
@media screen {
  .member_slides_pos .memberships_slideshow {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .member_slides_pos .memberships_slideshow .slideshow-container {
    display: flex;
    flex-direction: row;
    height: 34px;
    position: relative;
  }
}
@media screen {
  .member_slides_pos .memberships_slideshow .slideshow-container .slide {
    width: 100%;
    margin: 0;
    padding: 0 40px;
  }
}
@media screen {
  .member_slides_pos .memberships_slideshow .slideshow-container .slide li {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .member_slides_pos .memberships_slideshow .slideshow-container .slide li .club-logo__container .club-logo {
    height: 34px;
    width: auto;
    margin: 0 10px 0 0;
    display: block;
    object-fit: contain;
  }
}
@media screen {
  .member_slides_pos .memberships_slideshow .slideshow-container .prev,
  .member_slides_pos .memberships_slideshow .slideshow-container .next {
    width: 34px;
    height: 34px;
    margin-top: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 9;
  }
}
@media screen {
  .member_slides_pos .memberships_slideshow .slideshow-container .hidden {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .member_slides_pos {
    display: none;
  }
}
@media screen {
  .handicap__index.single {
    display: flex;
    flex-direction: row;
    margin: 0 0 10px 0;
  }
}
@media screen {
  .handicap__index.single.trial {
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .handicap__index.single.trial {
    justify-content: left;
  }
}
@media screen and (min-width: 768px) {
  .handicap__index.single {
    justify-content: left;
  }
}
@media screen and (min-width: 1280px) {
  .handicap__index.single {
    margin: 0 0 20px 0;
  }
}
.handicap__index.single .data {
  display: flex;
}
@media screen {
  .handicap__index.single .data .handicap__value {
    color: #fff;
    font-size: 42px;
    line-height: 1;
    font-weight: 400;
  }
}
@media screen and (min-width: 1280px) {
  .handicap__index.single .data .handicap__value {
    font-size: 60px;
  }
}
.handicap__index.single .data .cap-container {
  display: flex;
  flex-direction: row;
}
.handicap__index.single .data .cap {
  display: flex;
  margin-top: 10px;
  text-align: center;
}
@media screen {
  .handicap__index.single .data .handicap__trend {
    color: #fff;
    font-size: 14px;
    line-height: 1;
    font-weight: 300;
    margin-top: -10px;
  }
}
@media screen and (min-width: 1280px) {
  .handicap__index.single .data .handicap__trend {
    font-size: 18px;
    margin-top: -20px;
  }
}
@media screen {
  .handicap__index.single .reg {
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0 10px;
  }
}
@media screen and (min-width: 1280px) {
  .handicap__index.single .reg {
    height: 60px;
  }
}
@media screen {
  .handicap__index.single .reg .handicap__reg {
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }
}
@media screen {
  .handicap__index.double {
    margin: 0 0 10px 0;
  }
}
@media screen and (min-width: 768px) {
  .handicap__index.double {
    margin: 0 0 20px 0;
  }
}
@media screen {
  .handicap__index.double .reg {
    margin: 0 0 10px 0;
  }
}
@media screen {
  .handicap__index.double .reg .handicap__reg {
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }
}
@media screen {
  .handicap__index.double .data {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .handicap__index.double .data .col {
    flex: 1 1 0;
    display: flex;
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .handicap__index.double .data .col {
    flex: 0 0 auto;
  }
}
@media screen {
  .handicap__index.double .data .col:first-child {
    padding: 0 10px 0 0;
    border-right: solid 2px #fff;
  }
}
@media screen and (min-width: 768px) {
  .handicap__index.double .data .col:first-child {
    padding: 0 20px 0 0;
  }
}
@media screen {
  .handicap__index.double .data .col:last-child {
    padding: 0 0 0 10px;
  }
}
@media screen and (min-width: 768px) {
  .handicap__index.double .data .col:last-child {
    padding: 0 0 0 20px;
  }
}
@media screen {
  .handicap__index.double .data .col .handicap__value {
    color: #fff;
    font-size: 40px;
    line-height: 1;
    letter-spacing: -2px;
    margin: 0 5px 0 0;
  }
}
@media screen and (min-width: 768px) {
  .handicap__index.double .data .col .handicap__value {
    font-size: 48px;
  }
}
@media screen {
  .handicap__index.double .data .col .group {
    display: flex;
    flex-direction: column;
    padding: 2px 0;
  }
}
@media screen and (min-width: 768px) {
  .handicap__index.double .data .col .group {
    padding: 4px 0;
  }
}
@media screen {
  .handicap__index.double .data .col .group .handicap__hole {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 768px) {
  .handicap__index.double .data .col .group .handicap__hole {
    font-size: 21px;
    line-height: 22px;
    font-weight: 500;
  }
}
@media screen {
  .handicap__index.double .data .col .group .handicap__trend {
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
  }
}
@media screen and (min-width: 768px) {
  .handicap__index.double .data .col .group .handicap__trend {
    font-size: 13px;
    line-height: 24px;
  }
}
@media screen and (min-width: 1280px) {
  .handicap__index.double .data .col .group .handicap__trend {
    line-height: 1;
  }
}

@media screen {
  .panel {
    background-color: #fff;
  }
}
@media screen and (min-width: 1024px) {
  .panel {
    -webkit-box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.05);
  }
}

@media screen {
  .box {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .box:not(:only-of-type):not(:last-child) {
    margin: 0 0 25px 0;
  }
}
@media screen {
  .box.height100 {
    height: 100%;
  }
}
@media screen {
  .box .box-head {
    display: flex;
    flex-direction: column;
    padding: 0 25px;
  }
}
@media screen and (min-width: 768px) {
  .box .box-head {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1024px) {
  .box .box-head {
    padding: 0;
  }
}
@media screen {
  .box .box-head .box-title {
    color: #00365f;
    font-size: 24px;
    line-height: 1;
    font-weight: 300;
    margin: 0 0 15px 0;
  }
}
@media screen and (min-width: 768px) {
  .box .box-head .box-title {
    font-size: 28px;
    line-height: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .box .box-head .box-title {
    font-size: 35px;
  }
}
.box .box-head .box-title span {
  font-size: 16px;
  line-height: 18px;
  display: block;
}
.box .box-head .box-title strong {
  font-weight: 700;
}
@media screen {
  .box .box-head.box-head--row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.box .box-head-base {
  padding: 20px 0;
  background: #fff;
  text-align: center;
  font-size: 18px;
  border-bottom: 1px solid #eee;
}
@media screen and (min-width: 768px) {
  .box .box-head-base {
    padding: 25px 0;
    font-size: 20px;
  }
}
.box .box-head-sample {
  padding: 10px 0;
  text-align: center;
  font-size: 15px;
}
@media screen {
  .box .box-pre-body {
    padding: 10px 25px;
    border-top: solid 1px #d7d8d9;
  }
}
@media screen and (min-width: 768px) {
  .box .box-pre-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }
}
@media screen {
  .box .box-body {
    background-color: #fff;
  }
}
@media screen and (min-width: 768px) {
  .box .box-body.tablet-mod {
    padding: 25px 50px;
  }
}
@media screen {
  .box .box-body:not(:only-of-type):not(:last-child) {
    margin: 0 0 10px 0;
  }
}
@media screen and (min-width: 768px) {
  .box .box-body:not(:only-of-type):not(:last-child) {
    margin: 0 0 20px 0;
  }
}
@media screen {
  .box .box-body.std {
    padding: 20px 25px;
  }
}
@media screen {
  .box .box-body.grow {
    flex-grow: 1;
  }
}
@media screen {
  .box .box-body.center {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen {
  .box .box-footer {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    margin: 0 0 10px 0;
  }
}
@media screen and (min-width: 768px) {
  .box .box-footer {
    justify-content: flex-start;
    height: 20px;
    padding: 0;
    margin: 0 0 20px 0;
  }
}

@media screen {
  .powered-by__container {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .powered-by__container.margin0050 {
    margin-bottom: 5px;
  }
}
@media screen {
  .powered-by__container .powered-by {
    display: flex;
    flex-direction: row;
    flex: none;
    margin: 0 0 0 auto;
  }
}
@media screen {
  .powered-by__container .powered-by.no-auto {
    margin: 0 0 0 0;
  }
}
@media screen and (min-width: 768px) {
  .powered-by__container .powered-by.center-on-tablet {
    margin: 0 auto;
  }
}
@media screen {
  .powered-by__container .powered-by span {
    color: #000;
    font-size: 16px;
    line-height: 30px;
    margin: 0 4px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen {
  .powered-by__container .powered-by .powered-by__logo {
    width: 155px;
    height: 30px;
    object-fit: contain;
  }
}
@media screen {
  .powered-by__container .powered-by .powered-by__logo.opacity05 {
    opacity: 0.5;
  }
}
@media screen {
  .powered-by__container .powered-by .powered-by__logo.cisco {
    width: 90px;
    height: 50px;
    margin-left: 15px;
  }
}

@media screen {
  .custom-chart {
    position: relative;
    margin: 0 -20px;
    padding: 0 20px;
  }
}
@media screen {
  .custom-chart .cusutom-chart__container {
    overflow: visible;
    position: relative;
    margin: 0 -20px;
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
  }
}
@media screen and (min-width: 768px) {
  .custom-chart .cusutom-chart__container {
    border-left-width: 75px;
    border-right-width: 75px;
  }
}
@media screen {
  .custom-chart .cusutom-chart__container .custom-chart__timeline {
    height: 240px;
    margin: 20px 0 30px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
  }
}
@media screen and (min-width: 1024px) {
  .custom-chart .cusutom-chart__container .custom-chart__timeline {
    padding: 0 8px;
  }
}
@media screen {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar {
    background-color: #00365f;
    width: 40px;
    max-width: 70px;
    flex: 1;
    margin: 0 11px;
    position: relative;
  }
}
@media screen and (min-width: 768px) {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar {
    margin: 0 15px;
  }
}
@media screen and (min-width: 1280px) {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar {
    width: 50px;
  }
}
@media screen {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar.is-hidden {
    display: none;
  }
}
@media screen {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar .custom-chart__bar__value {
    color: #000;
    font-display: 14px;
    line-height: 20px;
    position: absolute;
    top: -25px;
    left: 50%;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
@media screen {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar .custom-chart__bar__date {
    color: #000;
    font-size: 11px;
    line-height: 20px;
    position: absolute;
    bottom: -30px;
    left: 50%;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
.custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar .hover_tooltip {
  visibility: hidden;
  position: absolute;
  width: 240px;
  height: 107px;
  top: -117px;
  left: -moz-calc(50% - 125px);
  left: -webkit-calc(50% - 125px);
  left: -o-calc(50% - 125px);
  left: calc(50% - 125px);
  border: 1px solid #ccc;
  background: #fff;
  font-size: 14px;
  line-height: 15px;
  padding: 15px;
  text-align: center;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar .hover_tooltip p {
  font-weight: 700;
  margin-bottom: 0;
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar .hover_tooltip .score_info {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
}
.custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar .hover_tooltip .score_info span {
  font-weight: bold;
  color: rgb(0, 0, 0);
}
.custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar .hover_tooltip .score_info .subscript {
  font-size: 0.8em;
  vertical-align: sub;
}
.custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar .hover_tooltip .score_info.trial {
  margin-top: 10px;
  justify-content: center;
}
.custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar .hover_tooltip.trial {
  width: 150px;
  left: calc(50% - 76px);
}
.custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar .hover_tooltip:after {
  content: "";
  display: none;
  position: absolute;
  bottom: -7px;
  left: 50%;
  width: 11px;
  height: 11px;
  background: #FFFFFF;
  border-right: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:hover {
  background: #0088ce;
}
.custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:hover .hover_tooltip {
  visibility: visible;
}
@media screen {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:hover::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 101;
    top: -18px;
    left: 50%;
    margin: 0 0 0 -6px;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border: solid 1px #ccc;
    border-left-color: #fff;
    border-top-color: #fff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar.no-hover {
  background-color: #00365f;
}
@media screen {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar.no-hover::after {
    display: none;
  }
}
@media screen {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:nth-child(1) .hover_tooltip, .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:nth-child(2) .hover_tooltip {
    left: -20px;
  }
}
@media screen and (min-width: 768px) {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:nth-child(1) .hover_tooltip, .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:nth-child(2) .hover_tooltip {
    left: calc(50% - 125px);
  }
}
@media screen {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:nth-child(4) .hover_tooltip, .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:nth-child(5) .hover_tooltip, .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:nth-child(6) .hover_tooltip {
    left: auto;
    right: -20px;
  }
}
@media screen and (min-width: 768px) {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:nth-child(4) .hover_tooltip, .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:nth-child(5) .hover_tooltip, .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:nth-child(6) .hover_tooltip {
    left: calc(50% - 125px);
  }
}
@media screen {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:nth-child(4) .hover_tooltip {
    left: calc(50% - 125px);
    right: unset;
  }
}
@media screen and (min-width: 768px) {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart__bar:nth-child(4) .hover_tooltip {
    left: calc(50% - 125px);
  }
}
@media screen {
  .custom-chart .cusutom-chart__container .custom-chart__timeline .custom-chart_h {
    border-bottom: solid 1px #e5e5e5;
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
  }
}
@media screen {
  .custom-chart .custom-chart__control {
    width: 24px;
    height: 40px;
    display: block;
    color: #00365f;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    border: none;
    background-color: transparent;
    position: absolute;
    top: 50%;
    padding: 0;
    margin: -20px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
@media screen and (min-width: 768px) {
  .custom-chart .custom-chart__control {
    width: 40px;
    height: 40px;
  }
}
@media screen and (min-width: 768px) {
  .custom-chart .custom-chart__control .material-icons-outlined {
    font-size: 32px;
  }
}
@media screen {
  .custom-chart .custom-chart__control.prev {
    left: 6px;
  }
}
@media screen and (min-width: 768px) {
  .custom-chart .custom-chart__control.prev {
    left: 24px;
  }
}
@media screen {
  .custom-chart .custom-chart__control.next {
    right: 6px;
  }
}
@media screen and (min-width: 768px) {
  .custom-chart .custom-chart__control.next {
    right: 24px;
  }
}

.lookup__list li {
  border-bottom: solid 1px #e5e5e5;
  background-color: #fff;
}
.lookup__list li:hover {
  background-color: #f6f6f6;
}
@media screen {
  .lookup__list li .item {
    padding: 7px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000;
    font-size: 18px;
  }
  .lookup__list li .item.my-courses-item {
    padding: 20px 20px 20px 10px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center right;
    background-size: 12px 6px;
    margin-right: 20px;
  }
  .lookup__list li .item.my-courses-item.no-search {
    background-image: url("../img/icons/chevron_blue_bottom.svg");
    cursor: pointer;
  }
  .lookup__list li .item.my-courses {
    cursor: pointer;
  }
}
@media screen and (min-width: 768px) {
  .lookup__list li .item {
    padding: 7px 20px;
  }
}
.lookup__list li .item .star {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 8px 20px 8px 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px;
  background-image: url("../img/icons/ic_star_43x43.svg");
}
.lookup__list li .item .star.active {
  background-image: url("../img/icons/ic_star_43x43_active.svg");
}
.lookup__list li .item .ic_c_minus {
  display: inline-block;
  width: 70px;
  height: 40px;
  min-width: 40px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 10px;
  background-size: 29px;
  background-image: url("../img/icons/ic_circle_minus.svg");
  cursor: pointer;
}
.lookup__list li .item .drag {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 8px 8px 8px 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px;
  background-image: url("../img/icons/drag_icon.svg");
}
.lookup__list li .item .radio-tee {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 8px 20px 8px 10px;
  cursor: pointer;
}
.lookup__list li .item .star-blank {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 8px 20px 8px 10px;
  background-repeat: no-repeat;
  background-position: center;
}
.lookup__list li .item .ic_c_plus {
  display: inline-block;
  width: 70px;
  height: 40px;
  min-width: 40px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40px 40px;
  background-image: url("../img/icons/ic_circle_plus.svg");
  cursor: pointer;
}
.lookup__list li .item .ic_c_plus.active {
  background-image: url("../img/icons/ic_circle_plus_filled.svg");
}
.lookup__list li .item .ic_check {
  display: inline-block;
  width: 70px;
  height: 40px;
  min-width: 40px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40px 40px;
  background-image: url("../img/icons/check_icon.svg");
  cursor: pointer;
}
.lookup__list li .item .btn-ic {
  border: none;
  background-color: transparent;
  margin: 0px 15px 5px 5px;
}
@media screen and (min-width: 768px) {
  .lookup__list li .item .ic_c_plus {
    width: 60px;
    background-size: 35px 35px;
  }
  .lookup__list li .item .ic_check {
    width: 60px;
    background-size: 35px 35px;
  }
}
@media screen and (min-width: 1440px) {
  .lookup__list li .item .ic_c_plus {
    width: 60px;
    background-size: 25px 25px;
  }
  .lookup__list li .item .ic_check {
    width: 60px;
    background-size: 25px 25px;
  }
}
@media screen {
  .lookup__list li .item .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .lookup__list li .item .details {
    display: flex;
    flex-direction: row;
  }
}
.lookup__list li .item .details.my-courses {
  padding: 15px 0 15px 0;
}
.lookup__list li .item .details.center {
  align-items: center;
}
.lookup__list li .item .details.center.my-courses-details {
  color: #00365f;
  font-size: 20px;
}
@media screen {
  .lookup__list li .item .details .name {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .lookup__list li .item .details .name {
    width: 40%;
  }
}
@media screen and (min-width: 768px) {
  .lookup__list li .item .details .tee {
    width: 50%;
  }
}
@media screen {
  .lookup__list li .item .details .club:not(.abbr) {
    font-weight: 500;
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  .lookup__list li .item .details .club:not(.abbr) {
    font-size: 18px;
  }
}
@media screen {
  .lookup__list li .item .index {
    flex: none;
    margin: 0 0 0 auto;
    width: 120px;
    text-align: center;
    justify-content: center;
  }
}
.lookup__list li .item.header {
  color: #73737d;
  font-size: 14px;
  text-transform: uppercase;
  background-color: #fff;
  cursor: default;
}
.lookup__list li .item.header .star {
  background: none;
}
.lookup__list li .item.header .ic_c_plus {
  background: none;
}
.lookup__list li .item.header.my-courses {
  padding: 25px 20px;
}
@media screen {
  .lookup__list li .item.header [data-abbr]:after {
    content: attr(data-abbr);
  }
}
@media screen and (min-width: 768px) {
  .lookup__list li .item.header [data-abbr]:after {
    content: attr(data-full);
  }
}
.lookup__list li .not_found {
  background-color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 50px 0;
}
.lookup__list .accordion-body {
  background-color: #f6f6f6;
  display: none;
  padding: 0 20px;
  box-sizing: border-box;
}
.lookup__list .accordion-body.is-open {
  display: block;
  padding: 40px 20px;
}
.lookup__list .row {
  max-width: 380px;
  margin-left: calc(30% - 5px);
}

/* general table */
@media screen {
  .tbl {
    display: flex;
    flex-direction: column;
  }
  .tbl-row {
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    flex-direction: row;
  }
  .tbl-cell {
    padding: 15px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .tbl--2cols .tbl-cell {
    flex: 0 0 50%;
  }
  .tbl--3cols .tbl-cell {
    flex: 0 0 33.33%;
  }
  .tbl--4cols .tbl-cell {
    flex: 0 0 25%;
  }
  .tbl--5cols .tbl-cell {
    flex: 0 0 20%;
  }
  .tbl--6cols .tbl-cell {
    flex: 0 0 16.6%;
  }
  .tbl.phone__column .tbl-row {
    flex-direction: column;
  }
  .tbl.phone__column .tbl--2cols .tbl-cell,
  .tbl.phone__column .tbl--3cols .tbl-cell,
  .tbl.phone__column .tbl--4cols .tbl-cell,
  .tbl.phone__column .tbl--5cols .tbl-cell,
  .tbl.phone__column .tbl--6cols .tbl-cell {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .tbl.phone__column .tbl-row {
    flex-direction: row;
  }
  .tbl.phone__column .tbl--2cols .tbl-cell {
    flex: 0 0 50%;
  }
  .tbl.phone__column .tbl--3cols .tbl-cell {
    flex: 0 0 33.33%;
  }
  .tbl.phone__column .tbl--4cols .tbl-cell {
    flex: 0 0 25%;
  }
  .tbl.phone__column .tbl--5cols .tbl-cell {
    flex: 0 0 20%;
  }
  .tbl.phone__column .tbl--6cols .tbl-cell {
    flex: 0 0 16.6%;
  }
}
/* general styles */
.tbl-cell {
  background-color: #fff;
  font-size: 16px;
  line-height: 16px;
}
.tbl-cell.text-center {
  justify-content: center;
  text-align: center;
}
.tbl-cell .subscript {
  font-size: 0.6em;
  vertical-align: sub;
}

.tbl-row.active .tbl-cell {
  background-color: #f6f6f6;
}

/* centered content */
.tbl .tbl-cell.text-center {
  justify-content: center;
  text-align: center;
}
.tbl.phone__column .tbl-cell.text-center {
  justify-content: flex-start;
  text-align: left;
}

@media screen and (min-width: 768px) {
  .tbl .tbl-cell.text-center, .tbl.phone__column .tbl-cell.text-center {
    justify-content: center;
    text-align: center;
  }
}
.golfer__list {
  /* table header for each row*/
  /* left action button for each row*/
}
.golfer__list .tbl-cell.header {
  font-size: 14px;
  text-transform: uppercase;
  color: #73737d;
  font-weight: 400;
  font-style: normal;
}
.golfer__list .tbl-cell .cap {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  background-color: #f6f6f6;
  font-size: 14px;
  text-transform: uppercase;
  color: #73737d;
  font-weight: 400;
  font-style: normal;
  line-height: inherit;
  line-height: 16px;
}
.golfer__list .tbl-cell.score {
  color: #00365f;
  font-weight: 500;
}
.golfer__list .tbl-cell.date {
  font-weight: 300;
}
.golfer__list .tbl-cell.rating {
  font-weight: 300;
}
.golfer__list .tbl-cell.diff {
  font-weight: 300;
}
.golfer__list .tbl-cell.course {
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .golfer__list .tbl--5cols:not(.keep-full-width) .tbl-cell {
    flex: 0 0 20.9%;
  }
  .golfer__list .tbl--5cols:not(.keep-full-width) .tbl-cell.grow {
    flex-grow: 1;
  }
  .golfer__list .tbl--5cols:not(.keep-full-width) .tbl-cell.small-cell {
    flex-basis: 4%;
  }
  .golfer__list .tbl--6cols.has-grow .tbl-cell {
    flex: 0 0 13.5%;
  }
  .golfer__list .tbl--6cols.has-grow .tbl-cell.grow {
    flex-grow: 1;
  }
  .golfer__list .tbl--6cols.has-grow .tbl-cell.small-cell {
    flex-basis: 4%;
  }
}
@media screen and (min-width: 1280px) {
  .golfer__list .tbl--6cols.has-grow .tbl-cell {
    flex: 0 0 13.5%;
  }
  .golfer__list .tbl--6cols.has-grow .tbl-cell.small-cell {
    flex-basis: 4%;
  }
  .golfer__list .tbl--5cols.has-grow .tbl-cell {
    flex: 0 0 20.9%;
  }
  .golfer__list .tbl--5cols.has-grow .tbl-cell.small-cell {
    flex-basis: 4%;
  }
}
@media screen {
  .golfer__list .tbl-cell {
    padding-left: 38%;
  }
  .golfer__list .tbl-cell .cap {
    width: 35%;
    display: flex;
    height: 100%;
  }
  .golfer__list .tbl.small-cap .tbl-cell {
    padding-left: 25%;
  }
  .golfer__list .tbl.small-cap .tbl-cell .cap {
    width: 20%;
  }
  .golfer__list [data-cap]:before {
    content: attr(data-cap);
    margin-right: 8px;
  }
}
@media screen and (min-width: 768px) {
  .golfer__list .tbl-cell {
    padding-left: 15px;
  }
  .golfer__list .tbl-cell .cap {
    display: none;
  }
  .golfer__list .tbl.small-cap .tbl-cell {
    padding-left: 15px;
  }
  .golfer__list .tbl.small-cap .tbl-cell .cap {
    display: none;
  }
  .golfer__list [data-cap]:before {
    content: "";
  }
}
@media screen {
  .golfer__list .tbl-cell .ic_c_minus {
    background-color: transparent;
    display: inline-block;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 35px;
    height: 35px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../img/icons/ic_circle_minus.svg");
    background-size: cover;
    border: none;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .golfer__list .tbl-cell .ic_c_minus.hidden {
    background-image: none;
  }
}
@media screen and (min-width: 768px) {
  .golfer__list .tbl-cell .ic_c_minus {
    position: relative;
    top: -2px;
    left: 0;
    margin-right: 15px;
    vertical-align: middle;
  }
}
@media screen and (min-width: 1440px) {
  .golfer__list .tbl-cell .ic_c_minus {
    width: 25px;
    height: 25px;
    margin-right: 18px;
  }
}
@media screen {
  .golfer__list .hide-on-tablet {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .golfer__list .hide-on-tablet {
    display: block;
  }
  .golfer__list .tbl-cell {
    font-size: 18px;
    line-height: 21px;
  }
  .golfer__list .tbl-cell.score {
    background-color: #f6f6f6;
    font-size: 21px;
  }
  .golfer__list .tbl-cell.score.highlight {
    background-color: #ffffd6;
  }
  .golfer__list .tbl-cell.score.nineHole {
    background-color: rgba(218, 26, 49, 0.25);
  }
}
.golfer__list .table-legend__container {
  justify-content: flex-end;
  flex-shrink: 0;
  margin: 0 0 5px;
}
.golfer__list .table-legend__item {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.golfer__list .legend__item-color {
  width: 46px;
  height: 14px;
  border: 1px solid #fff;
  margin: 0 8px 0 0;
}
.golfer__list .faded-yellow {
  background-color: #ffffd6;
}
.golfer__list .faded-red {
  background-color: rgba(218, 26, 49, 0.25);
}
.golfer__list .legend__item-name {
  color: #999;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
}
.golfer__list .section_tabs {
  background: #fff;
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
}
.golfer__list .section_tabs .tabs {
  justify-content: center;
  margin-bottom: 0;
}
.golfer__list .section_tabs .tabs li {
  border: 1px solid #e5e5e5;
  flex: 0 0 auto;
}
.golfer__list .section_tabs .tabs li a {
  padding: 2px 20px;
}
.golfer__list .section_tabs .tabs li + li {
  margin-left: -1px;
}
@media screen and (min-width: 768px) {
  .golfer__list .section_tabs .tabs {
    justify-content: flex-end;
  }
}
.golfer__list h4 {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #00365f;
  background: #fff;
  text-transform: uppercase;
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
}
.golfer__list .rightView {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .golfer__list .rightView {
    justify-content: flex-end;
    margin-top: -15px;
    margin-right: 20px;
  }
}

@media screen {
  .handicap__list .tbl-cell {
    font-size: 15px;
    font-weight: 400;
    padding: 20px 0;
    text-align: center;
  }
  .handicap__list .tbl-cell.revision {
    background-color: #f6f6f6;
  }
  .handicap__list .tbl-cell.handicap {
    font-size: 17px;
  }
  .handicap__list .tbl-cell.header {
    font-weight: 700;
    color: #00365f;
  }
}
@media screen and (min-width: 768px) {
  .handicap__list .tbl-cell {
    font-size: 18px;
    padding: 25px 0;
  }
  .handicap__list .tbl-cell.handicap {
    font-size: 22px;
  }
  .handicap__list .tbl-cell.header {
    font-size: 16px;
  }
}

.info-modal-trigger {
  color: #00365f;
  font-size: 12px;
  width: 18px;
  height: 18px;
  font-weight: 700;
  border-radius: 50%;
  border: 2px solid #00365f;
  background-color: initial;
  display: inline-block;
  padding: 0 !important;
  cursor: pointer;
}
.info-modal-trigger.margin-left {
  margin-left: 4px;
}
.info-modal-trigger.margin-right {
  margin-right: 4px;
}
.info-modal-trigger.white {
  color: #fff;
  border: 2px solid #fff;
}
@media screen and (min-width: 768px) {
  .info-modal-trigger.mobile-only-table-pcc {
    display: none;
  }
}

@media screen {
  .tbl-cell .btn.blank {
    width: 16px;
    height: 16px;
    position: relative;
  }
}
@media screen {
  .tbl-cell .btn.blank .material-icons-outlined {
    top: -4px;
    left: -4px;
    position: absolute;
  }
}

@media screen {
  .tbl-cell {
    min-height: 16px;
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen {
  .tbl-cell .default_select {
    width: 140px;
  }
}
@media screen {
  .tbl-cell .default_select .ds__control {
    border: none;
    height: 16px;
    min-height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}
@media screen {
  .tbl-cell .default_select .ds__control .ds__value-container {
    padding: 0;
    height: 16px;
    line-height: 16px;
  }
}
@media screen {
  .tbl-cell .default_select .ds__control .ds__value-container .ds__single-value small {
    display: none;
  }
}
@media screen {
  .tbl-cell .default_select .ds__control .ds__indicators {
    width: 16px;
    height: 16px;
  }
}
@media screen {
  .tbl-cell .default_select .ds__control .ds__indicators .ds__indicator {
    padding: 0;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-image: url("../img/icons/ic_expand_more_24px.svg");
  }
}
@media screen {
  .tbl-cell .default_select .ds__control .ds__indicators .ds__indicator svg {
    display: none;
  }
}

.golfer__list .tbl.tbl--5cols.has-grow.keep-full-width .tbl-row .tbl-cell,
.golfer__list .tbl.phone__column tbl--5cols has-grow keep-full-width .tbl-row .tbl-cell {
  box-sizing: border-box;
  flex-grow: 1 !important;
}
@media screen and (min-width: 768px) {
  .golfer__list .tbl.tbl--4cols .tbl-row .tbl-cell {
    flex-grow: 1;
    flex-shrink: 1;
  }
}
@media screen and (min-width: 768px) {
  .golfer__list .tbl .tbl-cell .trigger-container {
    margin: 0 0 0 8px;
    flex: none;
    width: 22px;
    height: 22px;
  }
}
@media screen and (min-width: 768px) {
  .golfer__list .tbl .tbl-cell .trigger-container .btn.blank {
    width: 22px;
    height: 22px;
    padding: 0;
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .golfer__list .tbl .tbl-cell .trigger-container .btn.blank .material-icons-outlined {
    position: static;
  }
}
@media screen and (min-width: 768px) {
  .golfer__list .tbl .tbl-cell.pcc {
    flex: none;
    width: 80px;
    flex-basis: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .golfer__list .chc_row_c .tbl-row .tbl-cell {
    flex: none;
    width: 25%;
  }
}
@media screen and (min-width: 768px) {
  .golfer__list .chc_row_c .tbl-row .tbl-cell .default_select.smallwidth {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .golfer__list .chc_row_c .tbl-row .tbl-cell .default_select.smallwidth .ds__control {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .golfer__list .chc_row_c .tbl-row .tbl-cell.tees-select {
    width: 30%;
  }
}
@media screen and (min-width: 768px) {
  .golfer__list .chc_row_c .tbl-row .tbl-cell.golfer-name-cell {
    width: 20%;
  }
}

@media screen and (min-width: 768px) {
  .golfer__list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.course-handicap {
    width: 100px;
  }
}
@media screen and (min-width: 768px) {
  .golfer__list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.playing-handicap {
    width: 100px;
  }
}
@media screen and (min-width: 768px) {
  .golfer__list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.shots-off {
    width: 100px;
  }
}

@media screen and (min-width: 768px) {
  .golfer_list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.golfer-name-cell,
  .golfer_list .chc_row_c.tbl.phone__column.small-cap .tbl-row.tbl--handicap-calc .tbl-cell.golfer-name-cell {
    width: 100px;
  }
}
@media screen and (min-width: 1280px) {
  .golfer_list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.golfer-name-cell,
  .golfer_list .chc_row_c.tbl.phone__column.small-cap .tbl-row.tbl--handicap-calc .tbl-cell.golfer-name-cell {
    width: auto;
    flex-grow: 1;
  }
}
@media screen and (min-width: 768px) {
  .golfer_list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.tees-select,
  .golfer_list .chc_row_c.tbl.phone__column.small-cap .tbl-row.tbl--handicap-calc .tbl-cell.tees-select {
    width: 70px;
  }
}
@media screen and (min-width: 1280px) {
  .golfer_list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.tees-select,
  .golfer_list .chc_row_c.tbl.phone__column.small-cap .tbl-row.tbl--handicap-calc .tbl-cell.tees-select {
    width: 160px;
  }
}
@media screen and (min-width: 768px) {
  .golfer_list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.handicap-index,
  .golfer_list .chc_row_c.tbl.phone__column.small-cap .tbl-row.tbl--handicap-calc .tbl-cell.handicap-index {
    width: 100px;
  }
}
@media screen and (min-width: 1280px) {
  .golfer_list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.handicap-index,
  .golfer_list .chc_row_c.tbl.phone__column.small-cap .tbl-row.tbl--handicap-calc .tbl-cell.handicap-index {
    width: 120px;
  }
}
@media screen and (min-width: 768px) {
  .golfer_list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.course-handicap,
  .golfer_list .chc_row_c.tbl.phone__column.small-cap .tbl-row.tbl--handicap-calc .tbl-cell.course-handicap {
    width: 90px;
  }
}
@media screen and (min-width: 1280px) {
  .golfer_list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.course-handicap,
  .golfer_list .chc_row_c.tbl.phone__column.small-cap .tbl-row.tbl--handicap-calc .tbl-cell.course-handicap {
    width: 120px;
  }
}
@media screen and (min-width: 768px) {
  .golfer_list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.playing-handicap,
  .golfer_list .chc_row_c.tbl.phone__column.small-cap .tbl-row.tbl--handicap-calc .tbl-cell.playing-handicap {
    width: 90px;
  }
}
@media screen and (min-width: 1280px) {
  .golfer_list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.playing-handicap,
  .golfer_list .chc_row_c.tbl.phone__column.small-cap .tbl-row.tbl--handicap-calc .tbl-cell.playing-handicap {
    width: 100px;
  }
}
@media screen and (min-width: 768px) {
  .golfer_list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.shots-off,
  .golfer_list .chc_row_c.tbl.phone__column.small-cap .tbl-row.tbl--handicap-calc .tbl-cell.shots-off {
    width: 100px;
  }
}
@media screen and (min-width: 1280px) {
  .golfer_list .chc_row_c.tbl.tbl--handicap-calc .tbl-row.tbl--handicap-calc .tbl-cell.shots-off,
  .golfer_list .chc_row_c.tbl.phone__column.small-cap .tbl-row.tbl--handicap-calc .tbl-cell.shots-off {
    width: 100px;
  }
}

.golfer-head__container {
  position: relative;
}
@media screen {
  .golfer-head__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .golfer-head__container {
    height: calc(100% - 40px);
  }
}
@media screen and (min-width: 1280px) {
  .golfer-head__container {
    width: 1230px;
    margin: 0 auto;
  }
}
@media screen {
  .golfer-head__container .details {
    padding: 0 0 0 20px;
    color: #fff;
  }
  .golfer-head__container .details .name-row .name {
    font-family: "National Book", sans-serif;
    font-size: 40px;
    line-height: 1em;
    margin-top: 40px;
  }
  .golfer-head__container .details .name-row .following {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 5px 5px 5px 27px;
    margin: 10px 0 0 15px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: left 2px;
    background-size: 18px 18px;
    font-weight: 500;
    background-image: url("../img/icons/ic_starw_43x43.svg");
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 14px;
  }
  .golfer-head__container .details .name-row .following.active {
    background-image: url("../img/icons/ic_starw_43x43_active.svg");
  }
  .golfer-head__container .details .club {
    display: block;
    font-size: 18px;
  }
  .golfer-head__container .details .info-rows {
    display: inline-flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .golfer-head__container .details .cap-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .golfer-head__container .details .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0 0 0;
  }
  .golfer-head__container .details .info div {
    flex-shrink: 0;
    padding: 0 10px;
    font-size: 16px;
    line-height: 1em;
    font-weight: 700;
  }
  .golfer-head__container .details .info div small {
    font-size: 15px;
    font-weight: 400;
  }
  .golfer-head__container .details .info .score {
    flex-grow: 1;
    font-size: 42px;
    line-height: 1em;
    font-weight: 500;
    padding: 0;
  }
  .golfer-head__container .details .info .trend, .golfer-head__container .details .info .low {
    text-align: center;
    border-left: 1px solid #fff;
  }
}
@media screen and (min-width: 768px) {
  .golfer-head__container .details {
    padding: 0 0 0 30px;
  }
  .golfer-head__container .details .name-row {
    display: flex;
    align-items: center;
  }
  .golfer-head__container .details .name-row .name {
    font-size: 52px;
    margin-top: 0;
  }
  .golfer-head__container .details .name-row .following {
    position: static;
  }
  .golfer-head__container .details .club {
    font-size: 20px;
  }
  .golfer-head__container .details .info-rows {
    flex-direction: row;
  }
  .golfer-head__container .details .info {
    margin: 10px 20px 0 0;
  }
  .golfer-head__container .details .info div {
    padding: 0 8px;
    font-size: 19px;
  }
  .golfer-head__container .details .info div small {
    font-size: 18px;
  }
  .golfer-head__container .details .info .score {
    font-size: 48px;
  }
  .golfer-head__container .details .info .trend, .golfer-head__container .details .info .low {
    text-align: left;
  }
}
@media screen and (min-width: 1280px) {
  .golfer-head__container .details {
    padding: 0 0 0 50px;
  }
  .golfer-head__container .details .name-row .name {
    font-size: 56px;
  }
  .golfer-head__container .details .name-row .following {
    position: static;
  }
  .golfer-head__container .details .club {
    font-size: 20px;
  }
  .golfer-head__container .details .info-rows {
    flex-direction: row;
  }
  .golfer-head__container .details .info {
    margin: 10px 20px 0 0;
  }
  .golfer-head__container .details .info div {
    font-size: 19px;
  }
  .golfer-head__container .details .info div small {
    font-size: 18px;
  }
  .golfer-head__container .details .info .score {
    font-size: 54px;
  }
  .golfer-head__container .details .info .trend, .golfer-head__container .details .info .low {
    text-align: left;
  }
}

.toolTip {
  max-width: 80%;
  line-height: 1.4;
}
@media screen and (min-width: 768px) {
  .toolTip {
    max-width: 350px;
  }
}

.ReactModal__Overlay {
  z-index: 1000;
  background-color: rgba(127, 127, 127, 0.5);
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  background: #fff;
  overflow: auto;
  outline: none;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 100%;
}
.modal-dialog .modal-content {
  position: relative;
  z-index: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
}
.modal-dialog .modal-content .modal_close {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
  font-size: 20px;
  background: none;
  border: none;
}
.modal-dialog .modal-content .modal_close:hover {
  background: none;
  border: none;
}
.modal-dialog .modal-content .modal-body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% 5% 0 5%;
}
.modal-dialog .modal-content .modal-body h3 {
  font-size: 22px;
  line-height: 1em;
  margin-bottom: 20px;
  color: #00365f;
  text-align: center;
}
.modal-dialog .modal-content .modal-body p {
  text-align: center;
  font-size: 18px;
  line-height: 1.4em;
  font-weight: 300;
}
.modal-dialog .modal-content .modal-body .content {
  align-items: center;
  justify-content: center;
}
.modal-dialog .modal-content .modal-body .error {
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: #cc233c;
  padding-top: 1em;
}
.modal-dialog .modal-content .modal-body .actions {
  display: flex;
  flex-wrap: wrap;
  padding-top: 35px;
}
.modal-dialog .modal-content .modal-body .actions .btn,
.modal-dialog .modal-content .modal-body .actions .btn.fill,
.modal-dialog .modal-content .modal-body .actions .btn.outline {
  flex: 1 0 200px;
  margin: 10px;
}
.modal-dialog .modal-content .modal-body .actions.centered {
  justify-content: center;
}
.modal-dialog .modal-content .modal-body .actions.centered .btn,
.modal-dialog .modal-content .modal-body .actions.centered .btn.fill,
.modal-dialog .modal-content .modal-body .actions.centered .btn.outline {
  flex-grow: 0;
  margin: 0 10px;
}
.modal-dialog .modal-content .modal-body .socials {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-dialog .modal-content .modal-body .socials a,
.modal-dialog .modal-content .modal-body .socials a:hover,
.modal-dialog .modal-content .modal-body .socials a:visited {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 140px;
  color: #00365f;
  text-transform: uppercase;
  font-weight: 500;
}
.modal-dialog .modal-content .modal-body .socials span {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: auto 22px;
  background-position: center center;
  margin-right: 15px;
}
.modal-dialog .modal-content .modal-body .socials span.twitter {
  background-image: url("../img/icons/ic_social_twitter.svg");
  background-color: rgb(42, 170, 224);
}
.modal-dialog .modal-content .modal-body .socials span.facebook {
  background-image: url("../img/icons/ic_social_fb.svg");
  background-color: rgb(41, 83, 150);
}
.modal-dialog .modal-content .modal-body .socials span.linkedin {
  background-image: url("../img/icons/ic_social_linkedin.svg");
  background-color: rgb(1, 115, 178);
}
.modal-dialog .modal-content .modal-body .login p {
  line-height: 1.2em;
}
.modal-dialog .modal-content .modal-body .success-icon {
  height: 50px;
  background: url("../img/icons/check_icon.svg") no-repeat center;
  margin-bottom: 0.5em;
}

@media screen and (min-width: 768px) {
  .modal-dialog {
    max-height: 350px;
  }
}
@media screen and (min-width: 1280px) {
  .modal-dialog .modal-content .modal-body h3 {
    font-size: 26px;
  }
  .modal-dialog .modal-content .modal-body p {
    font-size: 22px;
    line-height: 1.3em;
  }
}
@media screen {
  .item-list {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .item-list .item-list__head {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .item-list .item-list__head .item-list__title {
    color: #000;
    font-size: 14px;
    line-height: 1;
    width: 100%;
    display: block;
    padding: 20px 25px;
    text-transform: uppercase;
    border-bottom: solid 1px #e5e5e5;
  }
}
@media screen {
  .item-list .item-list__actions {
    padding: 20px 25px;
    border-bottom: solid 1px #e5e5e5;
  }
}
@media screen {
  .item-list .item-list__action {
    align-self: center;
    margin-top: 15px;
  }
}
@media screen and (min-width: 768px) {
  .item-list .item-list__action {
    align-self: flex-end;
    margin-top: -38px;
    margin-right: 20px;
    margin-bottom: 18px;
  }
}
@media screen {
  .item-list.courses .item-list__body {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .item-list.courses .item-list__body .item {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    border-bottom: solid 1px #e5e5e5;
    background-color: #fff;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen and (min-width: 768px) {
  .item-list.courses .item-list__body .item {
    padding: 30px 40px;
  }
}
@media screen and (min-width: 768px) {
  .item-list.courses .item-list__body .item .item__details {
    position: relative;
  }
}
@media screen {
  .item-list.courses .item-list__body .item .item__details .item__name {
    color: #00365f;
    font-size: 17px;
    line-height: 20px;
    font-weight: 400;
  }
}
@media screen and (min-width: 768px) {
  .item-list.courses .item-list__body .item .item__details .item__name {
    font-size: 20px;
    line-height: 24px;
  }
}
@media screen {
  .item-list.courses .item-list__body .item .item__details .item__extras {
    color: #00365f;
    font-size: 17px;
    line-height: 20px;
    font-weight: 400;
  }
}
@media screen and (min-width: 768px) {
  .item-list.courses .item-list__body .item .item__details .item__extras {
    font-size: 20px;
    line-height: 24px;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    -moz-transform: translate3d(0, -50%, 0);
    -ms-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}
@media screen {
  .item-list.courses .item-list__body .item .item__details .item__location {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
}
@media screen {
  .item-list.courses .item-list__body .item:hover, .item-list.courses .item-list__body .item:focus {
    background-color: #f6f6f6;
  }
}

.overflow-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen {
  .selected {
    display: flex;
    flex-direction: column;
    padding: 0 0 16px 0;
    margin: 0 0 0 0;
    border-bottom: solid 1px #e5e5e5;
  }
}
@media screen {
  .selected .selected__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen {
  .selected .selected__item .selected__details {
    color: #00365f;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .selected .selected__item .selected__controls {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen {
  .selected .selected__item .selected__controls a {
    color: #0067e6;
    font-size: 14px;
    line-height: 20px;
    display: block;
  }
}

.tooltip {
  height: 20px;
  width: 20px;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  display: inline-block;
  background-color: #667c8e;
}

@media screen {
  .score-panel {
    padding: 30px 20px 20px;
    background-color: #f6f6f6;
  }
}
@media screen {
  .score-panel .score-panel__head {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 16px 0;
  }
}
@media screen {
  .score-panel .score-panel__head .score-panel__title {
    color: #000;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    position: relative;
  }
}
@media screen {
  .score-panel .score-panel__head .score-panel__title sup {
    position: absolute;
    top: -12px;
    right: -36px;
  }
}
@media screen {
  .score-panel .score-panel__head .score-panel__title sup .score-panel__info {
    border: none;
    background-color: transparent;
  }
}
@media screen {
  .score-panel .score-panel__head .score-panel__title sup .score-panel__info .material-icons {
    color: #00365f;
  }
}
.score-legend {
  color: #757575;
  background: white;
  padding: 15px 10px;
}
.score-legend b {
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .score-info {
    margin-left: 0px;
  }
}
.score-info {
  color: #999999;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.25px;
  margin-left: 25px;
}
.score-info .title {
  font-weight: 700;
}

@media screen {
  .posted-score {
    padding: 35px;
  }
}
@media screen {
  .posted-score .posted-score__title {
    font-family: "National Book", sans-serif;
    color: #00365f;
    font-size: 24px;
    line-height: 1;
    margin: 0 0 10px 0;
  }
}
@media screen and (min-width: 768px) {
  .posted-score .posted-score__title {
    text-align: center;
  }
}
@media screen and (min-width: 1280px) {
  .posted-score .posted-score__title {
    font-size: 35px;
    margin: 0 0 35px;
  }
}

.score-advanced-stats-status {
  background: #eff0f2;
  color: #00365f;
  text-align: center;
  padding: 30px 0px 0px 0px;
  font-size: 18px;
}

@media screen {
  .share-round_summary__container .share-round_summary__row {
    margin: 10px -8px;
  }
}
@media screen {
  .share-round_summary__container .share-round_summary__button {
    width: 380px;
  }
}

@media screen {
  .recent-scores__slider {
    padding: 20px;
  }
}
@media screen {
  .recent-scores__slider.typeB {
    padding: 20px 25px;
  }
}

@media screen {
  .your-trend {
    padding: 35px;
  }
}
@media screen {
  .your-trend .your-trend__title {
    color: #000;
    font-size: 22px;
    line-height: 1;
    text-align: center;
  }
}
.your-trend .your-trend__title .info-button {
  color: #00365f;
  font-size: 15px;
  width: 22px;
  height: 22px;
  font-weight: 700;
  border-radius: 50%;
  border: 2px solid #00365f;
  background-color: initial;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}
.your-trend .your-trend__title .info-message {
  color: #cc233c;
  font-size: 14px;
  max-width: 750px;
  margin: 15px 0;
  display: inline-block;
}
.your-trend .your-trend__title .info-message a {
  color: #cc233c !important;
  text-decoration: underline;
}
@media screen {
  .your-trend .your-trend__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.your-trend .your-trend__container .your-trend__extra {
  white-space: pre-wrap;
}
@media screen {
  .your-trend .your-trend__container .your-trend__extra {
    color: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
}
@media screen and (min-width: 768px) {
  .your-trend .your-trend__container .your-trend__extra {
    text-align: center;
  }
}
@media screen and (min-width: 1280px) {
  .your-trend .your-trend__container .your-trend__extra {
    font-size: 20px;
  }
}
@media screen {
  .your-trend .your-trend__container .your-trend__col {
    flex: none;
    width: 50%;
    margin: 0 0 30px 0;
  }
}
@media screen {
  .your-trend .your-trend__container .your-trend__col:nth-child(1) {
    border-right: solid 1px #e5e5e5;
  }
}
@media screen and (min-width: 768px) {
  .your-trend .your-trend__container .your-trend__col:nth-child(1) {
    width: 100%;
    border: none;
  }
}
@media screen and (min-width: 768px) {
  .your-trend .your-trend__container .your-trend__col:nth-child(2) {
    width: 33.333333%;
    border-right: solid 1px #e5e5e5;
  }
}
@media screen and (min-width: 768px) {
  .your-trend .your-trend__container .your-trend__col:nth-child(2) label.regular.black.centered.large {
    color: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
  }
}
@media screen and (min-width: 1280px) {
  .your-trend .your-trend__container .your-trend__col:nth-child(2) label.regular.black.centered.large {
    font-size: 20px;
  }
}
@media screen and (min-width: 768px) {
  .your-trend .your-trend__container .your-trend__col:nth-child(2) .your-trend__score {
    color: #000;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }
}
@media screen and (min-width: 1280px) {
  .your-trend .your-trend__container .your-trend__col:nth-child(2) .your-trend__score {
    font-size: 20px;
  }
}
@media screen {
  .your-trend .your-trend__container .your-trend__col:nth-child(3) {
    width: 45%;
    padding: 0 10px;
    border-right: solid 1px #e5e5e5;
  }
}
@media screen and (min-width: 768px) {
  .your-trend .your-trend__container .your-trend__col:nth-child(3) {
    width: 33.333333%;
    padding: 0;
  }
}
@media screen {
  .your-trend .your-trend__container .your-trend__col:nth-child(4) {
    width: 45%;
    padding: 0 10px;
  }
}
@media screen and (min-width: 768px) {
  .your-trend .your-trend__container .your-trend__col:nth-child(4) {
    width: 33.333333%;
    padding: 0;
  }
}
@media screen {
  .your-trend .your-trend__container .your-trend__col .your-trend__score {
    color: #00365f;
    font-size: 48px;
    text-align: center;
    display: block;
  }
}
.your-trend .your-trend__container .your-trend__col .your-trend__score .your-trend__differential {
  display: flex !important;
  justify-content: center;
}
@media screen {
  .your-trend .score-card__row {
    margin: 10px -8px;
  }
}
@media screen {
  .your-trend .score-card__button {
    width: 380px;
  }
}

@media screen {
  .modal .modal__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-animation: fade_in 600ms both;
    -moz-animation: fade_in 600ms both;
    -o-animation: fade_in 600ms both;
    animation: fade_in 600ms both;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .modal .modal__content {
    width: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    position: relative;
    pointer-events: none;
    margin: auto;
    border-top: solid 32px transparent;
    border-right: solid 16px transparent;
    border-bottom: solid 32px transparent;
    border-left: solid 16px transparent;
  }
}
@media screen {
  .modal .modal__content .modal__container {
    pointer-events: initial;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    padding: 40px 35px;
    position: relative;
  }
}
@media screen and (min-width: 768px) {
  .modal .modal__content .modal__container {
    padding: 70px 100px;
  }
}
@media screen {
  .modal .modal__content .modal__container.regular {
    width: calc(100vw - 40px);
  }
}
@media screen and (min-width: 768px) {
  .modal .modal__content .modal__container.regular {
    width: 600px;
  }
}
@media screen and (min-width: 1024px) {
  .modal .modal__content .modal__container.regular {
    width: 770px;
  }
}
@media screen and (min-width: 1280px) {
  .modal .modal__content .modal__container.regular {
    width: 800px;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__close {
    color: #00365f;
    background-color: transparent;
    border: none;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .modal .modal__content .modal__container .modal__close {
    top: 20px;
    right: 20px;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__close .material-icons-outlined {
    font-size: 32px;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__body h2 {
    color: #00365f;
    font-size: 20px;
    line-height: 1.25;
    font-weight: 500;
    text-align: center;
    margin: 0 0 20px 0;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__body p {
    font-size: 18px;
    line-height: 1.5;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__body h3 {
    color: #00365f;
    font-size: 18px;
    line-height: 1.25;
    margin: 0 0 10px 0;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__body h3.uppercase {
    text-transform: uppercase;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__body ul.blank {
    list-style: none;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__body ul.blank li {
    color: #000;
    font-size: 18px;
    line-height: 1;
    margin: 0 0 10px 0;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__body ul.blank li:last-child {
    margin: 0;
  }
}

@media screen {
  .custom__modal .modal__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-animation: fade_in 600ms both;
    -moz-animation: fade_in 600ms both;
    -o-animation: fade_in 600ms both;
    animation: fade_in 600ms both;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .custom__modal .modal__content {
    width: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    position: relative;
    pointer-events: none;
    margin: auto;
    border-top: solid 64px transparent;
    border-bottom: solid 64px transparent;
  }
}
@media screen {
  .custom__modal .modal__content .modal__container {
    pointer-events: initial;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
  }
}
.custom__modal .modal__content .modal__container.very-narrow {
  width: calc(100% - 32px);
  margin: 16px;
}
@media screen and (min-width: 768px) {
  .custom__modal .modal__content .modal__container.very-narrow {
    width: 520px;
  }
}
@media screen {
  .custom__modal .modal__content .modal__container .modal__head {
    height: 64px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #CC0000;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .custom__modal .modal__content .modal__container .modal__head .modal__icon {
    margin: 0 16px 0 0;
    background: url("../img/icons/alert-icon.svg") no-repeat center;
    width: 30px;
    height: 30px;
  }
}
@media screen {
  .custom__modal .modal__content .modal__container .modal__head .modal__title {
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin: 0;
  }
  .custom__modal .modal__content .modal__container .modal__head .modal__title.long {
    padding: 16px 0px;
  }
}
.custom__modal .modal__content .modal__container .modal__body {
  padding: 48px;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.custom__modal .modal__content .modal__container .modal__body p {
  color: #333333;
  text-align: justify;
  font-size: 16px;
  line-height: 1.2;
}
.custom__modal .modal__content .modal__container .modal__body strong {
  font-weight: bold;
}
.custom__modal .modal__content .modal__container .modal__body .btn {
  width: auto;
  height: 40px;
  border: none;
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
  font-family: "National", sans-serif;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none !important;
  padding: 0 16px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
@media screen {
  .custom__modal .modal__content .modal__container .modal__body .btn {
    color: #fff;
    background-color: #CC0000;
  }
}
@media screen {
  .custom__modal .modal__content .modal__container .modal__body .btn:hover {
    background-color: rgb(178.5, 0, 0);
  }
}

.delete-round-button {
  color: #174571 !important;
  text-decoration: none !important;
  line-height: 16px !important;
}

.delete-round-modal {
  max-width: 516px;
  max-height: 570px;
  border-radius: 5px;
  height: auto;
}
.delete-round-modal .modal_close {
  top: 8px !important;
  right: 10px !important;
  z-index: 1;
  color: #515151;
}
.delete-round-modal .icon {
  background: url("../img/icons/alert-icon-2.svg") no-repeat center;
  width: 24px;
  height: 24px;
  background-size: cover;
  margin-bottom: 4px;
}
.delete-round-modal .actions .btn {
  margin-bottom: 6px !important;
}
.delete-round-modal .lnk {
  padding: 0.75em 2.4em;
  font-weight: 600 !important;
  margin-bottom: 8px;
}

.delete-round-modal-header {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: #f2f2f6;
  border: none;
  width: 100%;
  padding: 10px 15px;
  text-align: left !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-style: italic;
  color: #515151;
}

.delete-round-modal-text-1 {
  font-weight: 600 !important;
  margin-bottom: 15px;
  font-size: 24px !important;
  padding: 0 15px;
}

.delete-round-modal-text-2 {
  font-size: 18px !important;
  padding: 0 15px;
  color: #475467;
  font-weight: 300 !important;
}

.delete-round-alert {
  background: #f5f0e2;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  justify-content: center;
  display: inline-flex;
  align-items: center;
}

.delete-round-note {
  background: #f5f0e2;
  padding: 8px;
  font-size: 16px !important;
  color: #b0481a;
  border-radius: 8px;
  font-weight: 400 !important;
}
.delete-round-note span {
  font-style: italic;
  font-weight: 600;
}

.delete-round-modal button.btn {
  flex-basis: 290px !important;
}

@media screen and (min-width: 1280px) {
  .delete-round-modal .btn {
    font-size: 16px !important;
  }
}
@media screen {
  .pre-table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    height: 45px;
  }
}
@media screen and (min-width: 768px) {
  .pre-table {
    padding: 0;
  }
}
@media screen {
  .pre-table .table-name {
    color: #000;
    font-size: 18px;
    line-height: 40px;
  }
}

@media screen {
  .table-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .table-controls.mobile-only {
    display: none;
  }
}
@media screen {
  .table-controls.tablet-and-above {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .table-controls.tablet-and-above {
    display: flex;
  }
}
@media screen {
  .table-controls li {
    padding: 0 10px;
    border-right: solid 1px #000;
  }
}
@media screen {
  .table-controls li:first-child {
    padding: 0 10px 0 0;
  }
}
@media screen {
  .table-controls li:last-child {
    padding: 0 0 0 10px;
    border: none;
  }
}

@media screen {
  .table-location {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .table-location strong {
    color: #00365f;
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
    margin: 0 0 5px 0;
  }
}
@media screen and (min-width: 768px) {
  .table-location strong {
    margin: 0;
  }
}
@media screen {
  .table-location .table-location__bar {
    background-color: rgba(0, 54, 95, 0.25);
    border-radius: 5px;
    height: 5px;
  }
}
@media screen and (min-width: 768px) {
  .table-location .table-location__bar {
    display: none;
  }
}
@media screen {
  .table-location .table-location__bar .table-location__indicator {
    background-color: #00365f;
    height: 5px;
    border-radius: 5px;
  }
}

@media screen {
  .data-table__wrapper {
    position: relative;
    margin: 0 0 20px 0;
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e5e5e5;
  }
}
@media screen and (min-width: 768px) {
  .data-table__wrapper {
    border: none;
  }
}
@media screen {
  .data-table__wrapper .fixed-shadow {
    width: 25vw;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    -webkit-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.15);
  }
}
@media screen and (min-width: 768px) {
  .data-table__wrapper .fixed-shadow {
    display: none;
  }
}

@media screen {
  .data-table__scroll {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 60vw;
    margin: 0 15vw 0 25vw;
    box-sizing: content-box;
  }
}
@media screen and (min-width: 768px) {
  .data-table__scroll {
    overflow-x: visible;
    width: auto;
    margin: 0;
  }
}
@media screen {
  .data-table__scroll.LastScoreTable {
    width: 45vw;
    margin: 0 30vw 0 25vw;
  }
}
@media screen and (min-width: 768px) {
  .data-table__scroll.LastScoreTable {
    overflow-x: visible;
    width: auto;
    margin: 0;
  }
}

@media screen {
  .data-table {
    table-layout: fixed;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
  }
}
@media screen and (min-width: 768px) {
  .data-table {
    border-collapse: collapse;
  }
}
@media screen {
  .data-table.scores {
    width: 100vw;
  }
}
@media screen and (min-width: 768px) {
  .data-table.scores {
    width: auto;
  }
}
@media screen and (min-width: 768px) {
  .data-table.scores.advanced-stats {
    margin: 0 0 40px 0;
  }
}
@media screen and (min-width: 768px) {
  .data-table.scores colgroup col.end {
    width: 80px;
  }
}
@media screen and (min-width: 768px) {
  .data-table.scores colgroup col.col {
    width: 49px;
  }
}
@media screen {
  .data-table.scores thead tr th,
  .data-table.scores thead tr td,
  .data-table.scores tbody tr th,
  .data-table.scores tbody tr td {
    white-space: nowrap;
    flex: none;
    width: 15vw;
    height: 40px;
    font-size: 16px;
    line-height: 19px;
    vertical-align: middle;
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .data-table.scores thead tr th,
  .data-table.scores thead tr td,
  .data-table.scores tbody tr th,
  .data-table.scores tbody tr td {
    line-height: 1;
  }
}
@media screen {
  .data-table.scores thead tr th.fixed,
  .data-table.scores thead tr td.fixed,
  .data-table.scores tbody tr th.fixed,
  .data-table.scores tbody tr td.fixed {
    position: absolute;
  }
}
@media screen and (min-width: 768px) {
  .data-table.scores thead tr th.fixed,
  .data-table.scores thead tr td.fixed,
  .data-table.scores tbody tr th.fixed,
  .data-table.scores tbody tr td.fixed {
    position: static;
  }
}
@media screen {
  .data-table.scores thead tr th.fixed.start,
  .data-table.scores thead tr td.fixed.start,
  .data-table.scores tbody tr th.fixed.start,
  .data-table.scores tbody tr td.fixed.start {
    color: #00365f;
    width: 25vw;
    left: 0;
    text-align: left;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .data-table.scores thead tr th.fixed.start,
  .data-table.scores thead tr td.fixed.start,
  .data-table.scores tbody tr th.fixed.start,
  .data-table.scores tbody tr td.fixed.start {
    width: 120px;
    display: table-cell;
  }
}
@media screen {
  .data-table.scores thead tr th.fixed.end,
  .data-table.scores thead tr td.fixed.end,
  .data-table.scores tbody tr th.fixed.end,
  .data-table.scores tbody tr td.fixed.end {
    width: 15vw;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .data-table.scores thead tr th.fixed.end,
  .data-table.scores thead tr td.fixed.end,
  .data-table.scores tbody tr th.fixed.end,
  .data-table.scores tbody tr td.fixed.end {
    width: 80px;
    display: table-cell;
  }
}
@media screen {
  .data-table.scores thead tr th.fixed.pre-end,
  .data-table.scores thead tr td.fixed.pre-end,
  .data-table.scores tbody tr th.fixed.pre-end,
  .data-table.scores tbody tr td.fixed.pre-end {
    width: 15vw;
    right: 15vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .data-table.scores thead tr th.fixed.pre-end,
  .data-table.scores thead tr td.fixed.pre-end,
  .data-table.scores tbody tr th.fixed.pre-end,
  .data-table.scores tbody tr td.fixed.pre-end {
    display: table-cell;
  }
}
@media screen {
  .data-table.scores thead tr th.fixed.pre-end-total,
  .data-table.scores thead tr td.fixed.pre-end-total,
  .data-table.scores tbody tr th.fixed.pre-end-total,
  .data-table.scores tbody tr td.fixed.pre-end-total {
    right: 15vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .data-table.scores thead tr th.fixed.pre-end-total,
  .data-table.scores thead tr td.fixed.pre-end-total,
  .data-table.scores tbody tr th.fixed.pre-end-total,
  .data-table.scores tbody tr td.fixed.pre-end-total {
    display: table-cell;
  }
}
@media screen {
  .data-table.scores thead tr th {
    color: #fff;
    background-color: #00365f;
    font-size: 16px;
    font-weight: 700;
    border-right: solid 1px #00365f;
  }
}
@media screen {
  .data-table.scores thead tr th.fixed.start {
    color: #fff;
    text-transform: uppercase;
  }
}
@media screen {
  .data-table.scores tbody tr td input[type=text] {
    width: 100%;
    height: 39px;
    border: none;
    margin: 0;
    display: block;
    text-align: center;
  }
}
@media screen {
  .data-table.scores tbody tr:nth-child(odd) td {
    background-color: #f9fafa;
    border-bottom: solid 1px #e0e0e0;
  }
}
@media screen {
  .data-table.scores tbody tr:nth-child(even) td {
    background-color: #f2f3f5;
    border-bottom: solid 1px #e0e0e0;
  }
}
@media screen {
  .data-table.scores tbody tr:nth-child(odd) td, .data-table.scores tbody tr:nth-child(even) td {
    border-right: solid 1px #e0e0e0;
  }
}
@media screen {
  .data-table.scores tbody tr:nth-child(odd) td.start, .data-table.scores tbody tr:nth-child(even) td.start {
    border-bottom: solid 1px #e0e0e0;
  }
}
@media screen {
  .data-table.scores tbody tr:nth-child(odd) td.end, .data-table.scores tbody tr:nth-child(even) td.end {
    background-color: #d6e1e8;
    border-bottom: solid 1px #fff;
  }
}
@media screen {
  .data-table.scores tbody tr:nth-child(odd) td:last-child, .data-table.scores tbody tr:nth-child(even) td:last-child {
    border-right: solid 1px #e0e0e0;
  }
}
@media screen {
  .data-table.scores tbody tr:last-child td {
    border-bottom: none;
  }
}

@media screen {
  .data-table.scores.advanced-stats tbody tr.first-child {
    border-top: solid 1px #e5e5e5;
  }
}
@media screen {
  .data-table.scores.advanced-stats tbody {
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e5e5e5;
  }
}

.hidden-advanced-stats {
  height: 0px;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .data-table.Front.advanced-stats, .data-table.Front.scores {
    width: 100%;
    table-layout: fixed;
  }
}
@media screen and (min-width: 768px) {
  .data-table.Front.advanced-stats thead tr th,
  .data-table.Front.advanced-stats thead tr td,
  .data-table.Front.advanced-stats tbody tr th,
  .data-table.Front.advanced-stats tbody tr td, .data-table.Front.scores thead tr th,
  .data-table.Front.scores thead tr td,
  .data-table.Front.scores tbody tr th,
  .data-table.Front.scores tbody tr td {
    width: auto;
  }
}
@media screen and (min-width: 768px) {
  .data-table.Front.advanced-stats thead tr th.start,
  .data-table.Front.advanced-stats thead tr td.start,
  .data-table.Front.advanced-stats tbody tr th.start,
  .data-table.Front.advanced-stats tbody tr td.start, .data-table.Front.scores thead tr th.start,
  .data-table.Front.scores thead tr td.start,
  .data-table.Front.scores tbody tr th.start,
  .data-table.Front.scores tbody tr td.start {
    width: 120px;
  }
}
@media screen and (min-width: 768px) {
  .data-table.Front.advanced-stats thead tr th.end,
  .data-table.Front.advanced-stats thead tr td.end,
  .data-table.Front.advanced-stats tbody tr th.end,
  .data-table.Front.advanced-stats tbody tr td.end, .data-table.Front.scores thead tr th.end,
  .data-table.Front.scores thead tr td.end,
  .data-table.Front.scores tbody tr th.end,
  .data-table.Front.scores tbody tr td.end {
    width: 80px;
  }
}
@media screen and (min-width: 768px) {
  .data-table.Back.advanced-stats, .data-table.Back.scores {
    width: 100%;
    table-layout: fixed;
  }
}
@media screen and (min-width: 768px) {
  .data-table.Back.advanced-stats thead tr th,
  .data-table.Back.advanced-stats thead tr td,
  .data-table.Back.advanced-stats tbody tr th,
  .data-table.Back.advanced-stats tbody tr td, .data-table.Back.scores thead tr th,
  .data-table.Back.scores thead tr td,
  .data-table.Back.scores tbody tr th,
  .data-table.Back.scores tbody tr td {
    width: auto;
  }
}
@media screen and (min-width: 768px) {
  .data-table.Back.advanced-stats thead tr th.pre-end,
  .data-table.Back.advanced-stats thead tr td.pre-end,
  .data-table.Back.advanced-stats tbody tr th.pre-end,
  .data-table.Back.advanced-stats tbody tr td.pre-end, .data-table.Back.scores thead tr th.pre-end,
  .data-table.Back.scores thead tr td.pre-end,
  .data-table.Back.scores tbody tr th.pre-end,
  .data-table.Back.scores tbody tr td.pre-end {
    width: auto;
  }
}
@media screen and (min-width: 768px) {
  .data-table.Back.advanced-stats thead tr th.start,
  .data-table.Back.advanced-stats thead tr td.start,
  .data-table.Back.advanced-stats tbody tr th.start,
  .data-table.Back.advanced-stats tbody tr td.start, .data-table.Back.scores thead tr th.start,
  .data-table.Back.scores thead tr td.start,
  .data-table.Back.scores tbody tr th.start,
  .data-table.Back.scores tbody tr td.start {
    width: 120px;
  }
}
@media screen and (min-width: 768px) {
  .data-table.Back.advanced-stats thead tr th.end,
  .data-table.Back.advanced-stats thead tr td.end,
  .data-table.Back.advanced-stats tbody tr th.end,
  .data-table.Back.advanced-stats tbody tr td.end, .data-table.Back.scores thead tr th.end,
  .data-table.Back.scores thead tr td.end,
  .data-table.Back.scores tbody tr th.end,
  .data-table.Back.scores tbody tr td.end {
    width: 60px;
  }
}

@media screen {
  .score-markup {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: 30px 31px;
  }
}
@media screen {
  .score-markup.score-circle {
    background-image: url("../img/icons/score_state_circle.png");
  }
}
@media screen {
  .score-markup.score-double-circle {
    background-image: url("../img/icons/score_state_double_circle.png");
  }
}
@media screen {
  .score-markup.score-square {
    background-image: url("../img/icons/score_state_square.png");
  }
}
@media screen {
  .score-markup.score-double-square {
    background-image: url("../img/icons/score_state_double_square.png");
  }
}

@media screen {
  .data-table__wrapper .data-table__scroll .data-table.scores .score-markup,
  .data-table__wrapper .data-table__scroll .data-table.scores .react-icon-select .react-icon-select__control {
    background-color: transparent;
  }
}

@media screen {
  .loader {
    width: 120px;
    height: 40px;
    display: inline-block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: initial;
    background-image: url("../img/icons/loader.svg");
  }
}

@media screen {
  .loader-small {
    width: 100px;
    height: 20px;
    display: inline-block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: initial;
    background-image: url("../img/icons/loader-small.svg");
  }
}

@media screen {
  .loader-small-reverse {
    width: 100px;
    height: 20px;
    display: inline-block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: initial;
    background-image: url("../img/icons/loader-small-reverse.svg");
  }
}

.donut {
  font-size: 15px;
}
@media screen {
  .donut {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }
}
@media screen {
  .donut .donut__container {
    position: relative;
  }
}
@media screen {
  .donut .donut__container .recharts-wrapper svg .recharts-pie .recharts-pie-labels .donut__label {
    vertical-align: top;
  }
}
@media screen {
  .donut .donut__container .recharts-wrapper svg .recharts-pie .recharts-pie-labels .donut__label .label-value {
    font-size: 2.3em;
    line-height: 32px;
  }
}
@media screen {
  .donut .donut__container .recharts-wrapper svg .recharts-pie .recharts-pie-labels .donut__label .label-percent {
    font-size: 1.6em;
    line-height: 32px;
    font-weight: 300;
  }
}
@media screen {
  .donut .donut__container .donut-mid {
    width: 180px;
    height: 180px;
    display: block;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 85px;
    left: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen {
  .donut .donut__container .donut-mid .donut-mid__percentage {
    color: #000;
    font-family: "National Book", sans-serif;
    font-size: 4.4em;
    line-height: 64px;
    letter-spacing: -6px;
    text-align: center;
    position: relative;
    margin: 0;
  }
}
@media screen {
  .donut .donut__container .donut-mid .donut-mid__percentage sup {
    color: #000;
    font-size: 26px;
    line-height: 20px;
    font-weight: 700;
    position: absolute;
    top: 0;
    right: -10px;
  }
}
@media screen {
  .donut .donut__container .donut-mid .donut-mid__label {
    text-transform: uppercase;
    font-size: 27px;
  }
}
@media screen {
  .donut .donut-legend {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;
  }
}
@media screen and (min-width: 1280px) {
  .donut .donut-legend {
    padding: 20px;
  }
}
@media screen {
  .donut .donut-legend li {
    flex: none;
    color: #000;
    font-size: 18px;
    line-height: 24px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen {
  .donut .donut-legend li .donut-legend__color {
    display: block;
    width: 8px;
    height: 8px;
    margin: 0 8px 0 0;
    border-radius: 2px;
  }
}
@media screen {
  .donut .donut-legend li .donut-legend__color.light-blue {
    background-color: #0088ce;
  }
}
@media screen {
  .donut .donut-legend li .donut-legend__color.dark-blue {
    background-color: #00466e;
  }
}
@media screen {
  .donut .donut-legend li .donut-legend__color.gray {
    background-color: #667c8e;
  }
}
@media screen {
  .donut .donut-legend li .donut-legend__color.light-red {
    background-color: #ca243e;
  }
}
@media screen {
  .donut .donut-legend li .donut-legend__color.dark-red {
    background-color: #6d0020;
  }
}
.donut .donut-stats {
  margin: 50px 0 0 0;
}
@media screen {
  .donut .donut-stats {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .donut .donut-stats .donut-stat__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-right: solid 1px #e5e5e5;
  }
}
@media screen {
  .donut .donut-stats .donut-stat__item:last-child {
    border-right: none;
  }
}
@media screen {
  .donut .donut-stats .donut-stat__item .donut-stat__label {
    color: #000;
    font-size: 20px;
    line-height: 20px;
  }
}
@media screen {
  .donut .donut-stats .donut-stat__item .donut-stat {
    color: #00466e;
    font-size: 36px;
    line-height: 1;
    font-weight: 300;
  }
}
@media screen {
  .donut.small .recharts-wrapper svg .recharts-pie .recharts-pie-labels .donut__label .label-value {
    font-size: 1.7em;
  }
}
@media screen {
  .donut.small .donut-mid {
    width: 100px;
    height: 100px;
    top: 60px;
    left: 60px;
  }
}
@media screen {
  .donut.small .donut-mid .donut-mid__percentage {
    font-size: 2.9em;
    line-height: 40px;
  }
}
@media screen {
  .donut.small .donut-mid .donut-mid__percentage sup {
    font-size: 15px;
    line-height: 10px;
    top: 0;
    right: -10px;
  }
}
@media screen {
  .donut.small .donut-mid .donut-mid__label {
    font-size: 15px;
  }
}

.putting {
  font-size: 15px;
}
@media screen {
  .putting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.putting .putting_chart-title {
  top: 0;
  width: 100%;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  -ms-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  line-height: 25px;
  text-align: center;
  padding: 5px 0 0 0;
  position: relative;
}
@media screen {
  .putting .putting_chart-title {
    font-size: 18px;
  }
}
@media screen and (min-width: 1280px) {
  .putting .putting_chart-title {
    font-size: 20px;
  }
}
@media screen {
  .putting .putting__container {
    position: relative;
  }
}
@media screen {
  .putting .putting__container .recharts-wrapper svg .recharts-pie .recharts-pie-labels .putting__label {
    vertical-align: top;
  }
}
@media screen {
  .putting .putting__container .recharts-wrapper svg .recharts-pie .recharts-pie-labels .putting__label .label-value {
    font-size: 1.3em;
    line-height: 25px;
  }
}
@media screen {
  .putting .putting__container .recharts-wrapper svg .recharts-pie .recharts-pie-labels .putting__label .label-percent {
    font-size: 1em;
    line-height: 25px;
    font-weight: 300;
  }
}
@media screen {
  .putting .putting__container .putting-mid {
    width: 90px;
    height: 90px;
    display: block;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 55px;
    left: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen {
  .putting .putting__container .putting-mid .putting-mid__percentage {
    color: #000;
    font-family: "National Book", sans-serif;
    font-size: 2.5em;
    line-height: 40px;
    letter-spacing: -6px;
    text-align: center;
    position: relative;
    margin: 0;
  }
}
@media screen {
  .putting .putting__container .putting-mid .putting-mid__percentage sup {
    color: #000;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    position: absolute;
    top: 0;
    right: -10px;
  }
}
@media screen {
  .putting .putting__container .putting-mid .putting-mid__label {
    text-transform: uppercase;
    font-size: 15px;
  }
}
@media screen {
  .putting .putting-legend {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 0;
  }
}
@media screen {
  .putting .putting-legend li {
    flex: none;
    color: #000;
    font-size: 16px;
    line-height: 24px;
    padding: 0 0 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen {
  .putting .putting-legend li .putting-legend__color {
    display: block;
    width: 8px;
    height: 8px;
    margin: 0 2px 0 0;
    border-radius: 2px;
  }
}
@media screen {
  .putting .putting-legend li .putting-legend__color.light-blue {
    background-color: #0088ce;
  }
}
@media screen {
  .putting .putting-legend li .putting-legend__color.dark-blue {
    background-color: #00466e;
  }
}
@media screen {
  .putting .putting-legend li .putting-legend__color.light-red {
    background-color: #ca243e;
  }
}
@media screen {
  .putting .putting-stats {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .putting .putting-stats .putting-stat__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-right: solid 1px #e5e5e5;
  }
}
@media screen {
  .putting .putting-stats .putting-stat__item:last-child {
    border-right: none;
  }
}
@media screen {
  .putting .putting-stats .putting-stat__item .putting-stat__label {
    color: #000;
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen {
  .putting .putting-stats .putting-stat__item .putting-stat {
    color: #00466e;
    font-size: 32px;
    line-height: 1;
    font-weight: 300;
  }
}
@media screen {
  .putting.small .recharts-wrapper svg .recharts-pie .recharts-pie-labels .putting__label .label-value {
    font-size: 1.7em;
  }
}
@media screen {
  .putting.small .putting-mid {
    width: 100px;
    height: 100px;
    top: 60px;
    left: 60px;
  }
}
@media screen {
  .putting.small .putting-mid .putting-mid__percentage {
    font-size: 2.9em;
    line-height: 40px;
  }
}
@media screen {
  .putting.small .putting-mid .putting-mid__percentage sup {
    font-size: 15px;
    line-height: 10px;
    top: 0;
    right: -10px;
  }
}
@media screen {
  .putting.small .putting-mid .putting-mid__label {
    font-size: 15px;
  }
}

@media screen {
  .advanced-stats-panel {
    width: 100%;
  }
}
@media screen {
  .advanced-stats-panel .head-stats__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 20px;
    border-bottom: solid 1px #e5e5e5;
  }
}
@media screen and (min-width: 375px) {
  .advanced-stats-panel .head-stats__container {
    flex-direction: row;
  }
}
@media screen {
  .advanced-stats-panel .head-stats__container .head-stat__item {
    flex: none;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
  }
  .advanced-stats-panel .head-stats__container .head-stat__item.putts-box {
    flex-grow: 0;
    width: 25%;
  }
  .advanced-stats-panel .head-stats__container .head-stat__item.gir-box {
    flex-grow: 1;
  }
}
@media screen {
  .advanced-stats-panel .head-stats__container .head-stat__item.gir-box {
    width: calc(100% - 40px);
    padding: 10px 0;
    margin: 0 20px;
    border-top: solid 1px #e5e5e5;
  }
}
@media screen and (min-width: 375px) {
  .advanced-stats-panel .head-stats__container .head-stat__item.gir-box {
    width: 75%;
    margin: 0;
    padding: 0 10px;
    position: relative;
    border-top: none;
  }
  .advanced-stats-panel .head-stats__container .head-stat__item.gir-box:before {
    content: "";
    position: absolute;
    left: 0;
    top: 5%;
    height: 90%;
    border-left: solid 1px #e5e5e5;
  }
}
@media screen {
  .advanced-stats-panel .head-stats__container .head-stat__item .head-stat__label {
    display: block;
    text-align: center;
    color: #000;
    font-size: 16px;
    line-height: 1;
    margin: 0 0 10px 0;
  }
}
@media screen and (min-width: 1280px) {
  .advanced-stats-panel .head-stats__container .head-stat__item .head-stat__label {
    font-size: 20px;
  }
}
@media screen {
  .advanced-stats-panel .head-stats__container .head-stat__item .head-stat__value {
    color: #00365f;
    font-size: 36px;
    line-height: 1;
    font-weight: 300;
    text-align: center;
  }
}
.advanced-stats-panel .centered-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen {
  .gir-donut .gir-donut__container {
    position: relative;
  }
}
@media screen {
  .gir-donut .gir-donut__container .gir-donut-mid {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media screen {
  .gir-donut .gir-donut__container .gir-donut-mid .gir-donut-mid__percentage {
    font-family: "National Book", sans-serif;
    font-size: 20px;
    line-height: 1;
    position: relative;
  }
}
@media screen {
  .gir-donut .gir-donut__container .gir-donut-mid .gir-donut-mid__percentage sup {
    font-size: 16px;
    vertical-align: super;
  }
}

.gir-chart .gir_chart-title {
  top: 0;
  width: 100%;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  -ms-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  line-height: 25px;
  text-align: center;
  padding: 5px 0 5px 0;
  position: relative;
}
@media screen {
  .gir-chart .gir_chart-title {
    font-size: 18px;
  }
}
@media screen and (min-width: 1280px) {
  .gir-chart .gir_chart-title {
    font-size: 20px;
  }
}
.gir-chart .gir-chart__body {
  width: 100%;
  height: 160px;
  position: relative;
}
.gir-chart .gir-chart__body .gir-chart-label {
  font-size: 12px;
  line-height: 16px;
  display: block;
  position: absolute;
}
@media screen and (min-width: 414px) {
  .gir-chart .gir-chart__body .gir-chart-label {
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) {
  .gir-chart .gir-chart__body .gir-chart-label {
    font-size: 12px;
  }
}
@media screen and (min-width: 1280px) {
  .gir-chart .gir-chart__body .gir-chart-label {
    font-size: 16px;
  }
}
.gir-chart .gir-chart__body .gir-chart-label.north {
  top: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  -ms-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}
.gir-chart .gir-chart__body .gir-chart-label.east {
  right: 8px;
  top: calc(50% - 20px);
}
@media screen and (min-width: 768px) {
  .gir-chart .gir-chart__body .gir-chart-label.east {
    right: 0;
  }
}
.gir-chart .gir-chart__body .gir-chart-label.south {
  bottom: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  -ms-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}
.gir-chart .gir-chart__body .gir-chart-label.west {
  left: 8px;
  top: calc(50% - 20px);
}
@media screen and (min-width: 768px) {
  .gir-chart .gir-chart__body .gir-chart-label.west {
    left: 0;
  }
}
.gir-chart .gir-chart__body .gir-chart-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #00365f;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gir-chart .gir-chart__body .gir-chart-circle span {
  color: #fff;
}
.gir-chart .gir-chart__body .gir-chart-circle strong {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
}
.gir-chart .gir-chart__body .gir-chart-circle strong sup {
  font-size: 12px;
  vertical-align: super;
}
.gir-chart .gir-chart__body .gir-chart-line {
  display: block;
  background-color: #cc233c;
  position: absolute;
}
.gir-chart .gir-chart__body .gir-chart-line::before, .gir-chart .gir-chart__body .gir-chart-line::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 4px 4px;
  border-color: transparent transparent #cc233c transparent;
  position: absolute;
}
.gir-chart .gir-chart__body .gir-chart-line.horizontal {
  width: 100%;
  height: 2px;
  top: 50%;
  left: 0;
  margin: -1px 0 0 0;
}
.gir-chart .gir-chart__body .gir-chart-line.horizontal::before {
  left: -4px;
  top: -1px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.gir-chart .gir-chart__body .gir-chart-line.horizontal::after {
  right: -4px;
  top: -1px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.gir-chart .gir-chart__body .gir-chart-line.vertical {
  height: calc(100% - 40px);
  width: 2px;
  top: 20px;
  left: 50%;
  margin: 0 0 0 -1px;
}
.gir-chart .gir-chart__body .gir-chart-line.vertical::before {
  top: -4px;
  left: -3px;
}
.gir-chart .gir-chart__body .gir-chart-line.vertical::after {
  bottom: -4px;
  left: -3px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.gir-chart .gir-chart__footer {
  padding-block: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.gir-chart .gir-chart__footer button {
  margin-left: 8px;
}

@media screen {
  .static-umbrella {
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella {
    padding: 0 0 10px 0;
    margin: 10px 20px 0 20px;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container {
    width: 100%;
    height: 80px;
    padding: 40px 0 0 0;
    position: relative;
    box-sizing: content-box;
    overflow: hidden;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella .static-umbrella__container {
    height: 110px;
    padding: 30px 0 0 0;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .umbrella {
    width: 720px;
    height: 720px;
    border-radius: 50%;
    transform-origin: center center;
    border-style: solid;
    border-width: 200px;
    position: absolute;
    top: 40px;
    left: 50%;
    margin: 0 0 0 -360px;
    -webkit-box-shadow: 0 0 0 5px #fff;
    -moz-box-shadow: 0 0 0 5px #fff;
    -ms-box-shadow: 0 0 0 5px #fff;
    box-shadow: 0 0 0 5px #fff;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella .static-umbrella__container .umbrella {
    top: 30px;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .umbrella.layer-one {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .umbrella.layer-two {
    -webkit-transform: rotate(300deg);
    -moz-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .umbrella.layer-three {
    -webkit-transform: rotate(-30deg);
    -moz-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .umbrella.red {
    border-style: solid;
    border-right-color: #cc233c;
    border-bottom-color: #cc233c;
    border-left-color: transparent;
    border-top-color: transparent;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .umbrella.blue {
    border-style: solid;
    border-right-color: #00365f;
    border-bottom-color: #00365f;
    border-left-color: transparent;
    border-top-color: transparent;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .umbrella__separator {
    width: 4px;
    height: 720px;
    background-color: #fff;
    display: block;
    transform-origin: center center;
    position: absolute;
    left: calc(50% - 2px);
    z-index: 9;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .umbrella__separator.one {
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .umbrella__separator.two {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels {
    height: 100%;
    position: relative;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label {
    position: absolute;
    width: 80px;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label {
    width: 120px;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label .label__title {
    white-space: nowrap;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label .material-icons-outlined {
    font-size: 32px;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label .label__percentage {
    color: #fff;
    font-size: 48px;
    line-height: 1;
    position: relative;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label .label__percentage sup {
    font-size: 18px;
    vertical-align: super;
    position: absolute;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.left {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    height: 100%;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.left .label__percentage {
    font-size: 32px;
    text-align: center;
    position: absolute;
    bottom: 5px;
    width: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.left .label__percentage {
    font-size: 40px;
    bottom: 10px;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.left .label__title {
    font-size: 16px;
    line-height: 20px;
    display: block;
    position: absolute;
    left: 0;
    top: -40px;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.left .label__title {
    font-size: 18px;
    top: -30px;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.left .material-icons-outlined {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.left .material-icons-outlined {
    top: 10px;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.mid {
    left: 50%;
    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.mid {
    bottom: 10px;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.mid .label__title {
    color: #fff;
    display: block;
    font-size: 18px;
    line-height: 20px;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.right {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    height: 100%;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.right .label__percentage {
    font-size: 32px;
    text-align: center;
    position: absolute;
    bottom: 5px;
    width: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.right .label__percentage {
    font-size: 40px;
    bottom: 10px;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.right .label__title {
    font-size: 16px;
    line-height: 20px;
    display: block;
    position: absolute;
    right: 0;
    top: -40px;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.right .label__title {
    font-size: 18px;
    top: -30px;
  }
}
@media screen {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.right .material-icons-outlined {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.5;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella .static-umbrella__container .static-umbrella__labels .static-umbrella__label.right .material-icons-outlined {
    top: 10px;
  }
}
@media screen {
  .static-umbrella .static-umbrella__title {
    color: #000;
    font-size: 18px;
    line-height: 1;
    text-align: center;
    margin: 0 0 5px 0;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella .static-umbrella__title {
    font-size: 20px;
  }
}
@media screen {
  .static-umbrella .static-umbrella__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 0 0;
  }
}
@media screen {
  .static-umbrella .static-umbrella__footer .footer-item {
    font-size: 16px;
    white-space: nowrap;
  }
}
@media screen and (min-width: 1280px) {
  .static-umbrella .static-umbrella__footer .footer-item {
    font-size: 18px;
  }
}

@media screen {
  .card__table {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin: 0 0 40px 0;
  }
}
@media screen and (min-width: 768px) {
  .card__table {
    margin: 0 0 40px 0;
  }
}
@media screen {
  .card__table .table__head {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__head {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__head .head__row .head__cell {
    color: #73737d;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    height: 50px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 1280px) {
  .card__table .table__head .head__row .head__cell {
    font-size: 14px;
    height: 60px;
  }
}
@media screen {
  .card__table .table__head .head__row .head__cell.stats {
    justify-content: flex-end;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__head .head__row,
  .card__table .table__head .card,
  .card__table .table__body .head__row,
  .card__table .table__body .card {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__head .head__row .head__cell.esc-score,
  .card__table .table__head .head__row .card__cell.esc-score,
  .card__table .table__head .card .head__cell.esc-score,
  .card__table .table__head .card .card__cell.esc-score,
  .card__table .table__body .head__row .head__cell.esc-score,
  .card__table .table__body .head__row .card__cell.esc-score,
  .card__table .table__body .card .head__cell.esc-score,
  .card__table .table__body .card .card__cell.esc-score {
    flex: none;
    width: 100px;
  }
}
@media screen and (min-width: 1280px) {
  .card__table .table__head .head__row .head__cell.esc-score,
  .card__table .table__head .head__row .card__cell.esc-score,
  .card__table .table__head .card .head__cell.esc-score,
  .card__table .table__head .card .card__cell.esc-score,
  .card__table .table__body .head__row .head__cell.esc-score,
  .card__table .table__body .head__row .card__cell.esc-score,
  .card__table .table__body .card .head__cell.esc-score,
  .card__table .table__body .card .card__cell.esc-score {
    width: 140px;
  }
}
.card__table .table__head .head__row .head__cell.esc-score.nineHole,
.card__table .table__head .head__row .card__cell.esc-score.nineHole,
.card__table .table__head .card .head__cell.esc-score.nineHole,
.card__table .table__head .card .card__cell.esc-score.nineHole,
.card__table .table__body .head__row .head__cell.esc-score.nineHole,
.card__table .table__body .head__row .card__cell.esc-score.nineHole,
.card__table .table__body .card .head__cell.esc-score.nineHole,
.card__table .table__body .card .card__cell.esc-score.nineHole {
  background-color: rgba(218, 26, 49, 0.25);
}
.card__table .table__head .head__row .head__cell.esc-score.deletedScore,
.card__table .table__head .head__row .card__cell.esc-score.deletedScore,
.card__table .table__head .card .head__cell.esc-score.deletedScore,
.card__table .table__head .card .card__cell.esc-score.deletedScore,
.card__table .table__body .head__row .head__cell.esc-score.deletedScore,
.card__table .table__body .head__row .card__cell.esc-score.deletedScore,
.card__table .table__body .card .head__cell.esc-score.deletedScore,
.card__table .table__body .card .card__cell.esc-score.deletedScore {
  background-color: #fafafa;
}
@media screen and (min-width: 768px) {
  .card__table .table__head .head__row .head__cell.date,
  .card__table .table__head .head__row .card__cell.date,
  .card__table .table__head .card .head__cell.date,
  .card__table .table__head .card .card__cell.date,
  .card__table .table__body .head__row .head__cell.date,
  .card__table .table__body .head__row .card__cell.date,
  .card__table .table__body .card .head__cell.date,
  .card__table .table__body .card .card__cell.date {
    flex: none;
    width: 80px;
  }
}
@media screen and (min-width: 1280px) {
  .card__table .table__head .head__row .head__cell.date,
  .card__table .table__head .head__row .card__cell.date,
  .card__table .table__head .card .head__cell.date,
  .card__table .table__head .card .card__cell.date,
  .card__table .table__body .head__row .head__cell.date,
  .card__table .table__body .head__row .card__cell.date,
  .card__table .table__body .card .head__cell.date,
  .card__table .table__body .card .card__cell.date {
    width: 100px;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__head .head__row .head__cell.rating-slope,
  .card__table .table__head .head__row .card__cell.rating-slope,
  .card__table .table__head .card .head__cell.rating-slope,
  .card__table .table__head .card .card__cell.rating-slope,
  .card__table .table__body .head__row .head__cell.rating-slope,
  .card__table .table__body .head__row .card__cell.rating-slope,
  .card__table .table__body .card .head__cell.rating-slope,
  .card__table .table__body .card .card__cell.rating-slope {
    flex: none;
    width: 80px;
  }
}
@media screen and (min-width: 1280px) {
  .card__table .table__head .head__row .head__cell.rating-slope,
  .card__table .table__head .head__row .card__cell.rating-slope,
  .card__table .table__head .card .head__cell.rating-slope,
  .card__table .table__head .card .card__cell.rating-slope,
  .card__table .table__body .head__row .head__cell.rating-slope,
  .card__table .table__body .head__row .card__cell.rating-slope,
  .card__table .table__body .card .head__cell.rating-slope,
  .card__table .table__body .card .card__cell.rating-slope {
    width: 100px;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__head .head__row .head__cell.differential,
  .card__table .table__head .head__row .card__cell.differential,
  .card__table .table__head .card .head__cell.differential,
  .card__table .table__head .card .card__cell.differential,
  .card__table .table__body .head__row .head__cell.differential,
  .card__table .table__body .head__row .card__cell.differential,
  .card__table .table__body .card .head__cell.differential,
  .card__table .table__body .card .card__cell.differential {
    flex: none;
    width: 80px;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__head .head__row .head__cell.pcc,
  .card__table .table__head .head__row .card__cell.pcc,
  .card__table .table__head .card .head__cell.pcc,
  .card__table .table__head .card .card__cell.pcc,
  .card__table .table__body .head__row .head__cell.pcc,
  .card__table .table__body .head__row .card__cell.pcc,
  .card__table .table__body .card .head__cell.pcc,
  .card__table .table__body .card .card__cell.pcc {
    flex: none;
    width: 60px;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__head .head__row .head__cell.course,
  .card__table .table__head .head__row .card__cell.course,
  .card__table .table__head .card .head__cell.course,
  .card__table .table__head .card .card__cell.course,
  .card__table .table__body .head__row .head__cell.course,
  .card__table .table__body .head__row .card__cell.course,
  .card__table .table__body .card .head__cell.course,
  .card__table .table__body .card .card__cell.course {
    flex: none;
    width: 230px;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1280px) {
  .card__table .table__head .head__row .head__cell.course,
  .card__table .table__head .head__row .card__cell.course,
  .card__table .table__head .card .head__cell.course,
  .card__table .table__head .card .card__cell.course,
  .card__table .table__body .head__row .head__cell.course,
  .card__table .table__body .head__row .card__cell.course,
  .card__table .table__body .card .head__cell.course,
  .card__table .table__body .card .card__cell.course {
    flex: 1 1 0;
    width: auto;
    max-width: 390px;
  }
}
.card__table .table__head .head__row .head__cell.stats,
.card__table .table__head .head__row .card__cell.stats,
.card__table .table__head .card .head__cell.stats,
.card__table .table__head .card .card__cell.stats,
.card__table .table__body .head__row .head__cell.stats,
.card__table .table__body .head__row .card__cell.stats,
.card__table .table__body .card .head__cell.stats,
.card__table .table__body .card .card__cell.stats {
  white-space: nowrap;
}
@media screen and (min-width: 768px) {
  .card__table .table__head .head__row .head__cell.stats,
  .card__table .table__head .head__row .card__cell.stats,
  .card__table .table__head .card .head__cell.stats,
  .card__table .table__head .card .card__cell.stats,
  .card__table .table__body .head__row .head__cell.stats,
  .card__table .table__body .head__row .card__cell.stats,
  .card__table .table__body .card .head__cell.stats,
  .card__table .table__body .card .card__cell.stats {
    flex: none;
    width: 100px;
    margin: 0 0 0 auto;
  }
}
@media screen {
  .card__table .table__head .head__row .head__cell.stats .btn.comp,
  .card__table .table__head .head__row .card__cell.stats .btn.comp,
  .card__table .table__head .card .head__cell.stats .btn.comp,
  .card__table .table__head .card .card__cell.stats .btn.comp,
  .card__table .table__body .head__row .head__cell.stats .btn.comp,
  .card__table .table__body .head__row .card__cell.stats .btn.comp,
  .card__table .table__body .card .head__cell.stats .btn.comp,
  .card__table .table__body .card .card__cell.stats .btn.comp {
    margin-top: 5px;
  }
}
.card__table .table__head .head__row.active,
.card__table .table__head .card.active,
.card__table .table__body .head__row.active,
.card__table .table__body .card.active {
  background: #0088ce;
  color: #fff;
}
.card__table .table__head .head__row.active .card__cell.esc-score,
.card__table .table__head .card.active .card__cell.esc-score,
.card__table .table__body .head__row.active .card__cell.esc-score,
.card__table .table__body .card.active .card__cell.esc-score {
  background: #0088ce;
}
.card__table .table__head .head__row.active .card__cell strong.dark-blue,
.card__table .table__head .card.active .card__cell strong.dark-blue,
.card__table .table__body .head__row.active .card__cell strong.dark-blue,
.card__table .table__body .card.active .card__cell strong.dark-blue {
  color: #fff;
}
.card__table .h6_attributes {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #00365f;
  background: #fff;
  text-transform: uppercase;
  padding: 20px 0;
}
.card__table .h6_attributes.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}
.card__table .h6_attributes.border-top {
  border-top: 1px solid #e5e5e5;
}
@media screen {
  .card__table .table__body {
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .card__table .table__body .card {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__body .card {
    flex-direction: row;
    height: 60px;
    border-bottom: solid 1px #e5e5e5;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__body .card:first-of-type {
    border-top: solid 1px #e5e5e5;
  }
}
@media screen {
  .card__table .table__body .card .card__cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    padding: 0 15px 0 0;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__body .card .card__cell {
    justify-content: center;
    padding: 0 10px;
    font-size: 14px;
    line-height: 16px;
    height: 45px;
  }
}
@media screen and (min-width: 1280px) {
  .card__table .table__body .card .card__cell {
    font-size: 18px;
    line-height: 20px;
    height: 60px;
  }
}
@media screen {
  .card__table .table__body .card .card__cell:last-child {
    border-bottom: solid 1px #e5e5e5;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__body .card .card__cell:last-child {
    border: none;
  }
}
@media screen {
  .card__table .table__body .card .card__cell strong {
    font-weight: 700;
  }
}
@media screen {
  .card__table .table__body .card .card__cell strong.dark-blue {
    color: #00365f;
  }
}
@media screen {
  .card__table .table__body .card .card__cell strong.medium_blue_2 {
    color: #6b8ea3;
  }
}
.card__table .table__body .card .card__cell .subscript {
  font-size: 0.6em;
  vertical-align: sub;
}
@media screen {
  .card__table .table__body .card .card__cell::before {
    display: block;
    width: 150px;
    flex-shrink: 0;
    padding: 15px 20px;
    margin: 0 15px 0 0;
    box-sizing: border-box;
    color: #000;
    background-color: #eff3f6;
    -webkit-box-shadow: 0 200px 0 0 #eff3f6;
    -moz-box-shadow: 0 200px 0 0 #eff3f6;
    -ms-box-shadow: 0 200px 0 0 #eff3f6;
    box-shadow: 0 200px 0 0 #eff3f6;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__body .card .card__cell::before {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__body .card .card__cell.esc-score {
    background-color: #f6f6f6;
    height: 100%;
  }
}
@media screen {
  .card__table .table__body .card .card__cell.esc-score::before {
    content: "Score";
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__body .card .card__cell.date {
    letter-spacing: -0.5px;
  }
}
@media screen and (min-width: 1280px) {
  .card__table .table__body .card .card__cell.date {
    font-size: 17px;
  }
}
@media screen {
  .card__table .table__body .card .card__cell.date::before {
    content: "Date";
  }
}
@media screen {
  .card__table .table__body .card .card__cell.rating-slope::before {
    content: "Rating / Slope";
  }
}
@media screen {
  .card__table .table__body .card .card__cell.pcc::before {
    content: "PCC";
  }
}
@media screen {
  .card__table .table__body .card .card__cell.differential::before {
    content: "Differential";
  }
}
@media screen {
  .card__table .table__body .card .card__cell.course::before {
    content: "Course";
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__body .card .card__cell.course strong {
    max-width: 100%;
    max-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__body .card .card__cell.course strong {
    max-height: 40px;
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__body .card .card__cell.course strong span {
    max-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media screen and (min-width: 768px) {
  .card__table .table__body .card .card__cell.stats {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
@media screen and (min-width: 1280px) {
  .card__table .table__body .card .card__cell.stats {
    font-size: 17px;
  }
}
@media screen {
  .card__table .table__body .card .card__cell.stats::before {
    content: "Stats";
  }
}
.card__table .table__body .card_more {
  display: flex;
  flex-grow: 1;
  background-color: #eff0f2;
  justify-content: space-around;
  padding: 0 5px;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
}
.card__table .table__body .card_more .donut,
.card__table .table__body .card_more .advanced-stats-panel {
  position: relative;
  max-width: 470px;
  margin: 0 auto;
}
.card__table .table__body .card_more .donut {
  border-bottom: 1px solid #ccc;
}
.card__table .table__body .card_more .donut:after {
  border-right: none;
}
.card__table .table__body .card_more .advanced-stats-panel {
  padding: 20px 0;
}
.card__table .table__body .card_more .donut:after {
  border-right: none;
}
.card__table .table__body .card_more::after {
  content: "";
  display: block;
  border-right: solid 1px #e5e5e5;
  height: calc(100% - 40px);
  width: 0;
  position: absolute;
  top: 20px;
  bottom: 20px;
}
.card__table .table__body .card_more.disable_divider::after {
  display: none;
}
@media screen and (min-width: 768px) {
  .card__table .table__body .card_more {
    flex-direction: row;
    align-items: flex-end;
    position: relative;
  }
  .card__table .table__body .card_more .donut,
  .card__table .table__body .card_more .advanced-stats-panel {
    width: 50%;
  }
  .card__table .table__body .card_more .donut {
    border-bottom: none;
  }
}
.card__table .table__body .right-item {
  display: flex;
  justify-content: flex-end;
}
.card__table .row-action-right {
  display: flex;
  justify-content: flex-end;
  background-color: #eff0f2;
  padding: 0 0 20px;
}
.card__table .row-action-right .rar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .card__table .row-action-right .rar_container {
    width: 50%;
  }
}
.card__table .row-action-right .rar_container > *:last-child {
  margin-top: 10%;
}
.card__table .row-action {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 30px 0 20px;
}
.card__table .row-action .rar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .card__table .row-action .rar_container {
    width: 50%;
  }
}
.card__table .row-action .rar_container > *:last-child {
  margin-top: 10%;
}

.pre-card__table {
  position: relative;
}
@media screen {
  .pre-card__table .pre-table__head {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: transparent;
    height: 60px;
    width: auto;
    padding: 0 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen {
  .pre-card__table .pre-table__head .graph-view {
    background-color: transparent;
    border: none;
    color: #000;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    height: 40px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen {
  .pre-card__table .pre-table__head .graph-view .icon {
    color: #00365f;
    margin: 0 0 0 10px;
  }
}
@media screen {
  .pre-card__table .pre-table__head .graph-view .open,
  .pre-card__table .pre-table__head .graph-view .close {
    font-size: 16px;
  }
}
@media screen {
  .pre-card__table .pre-table__head .graph-view .open {
    display: block;
  }
}
@media screen {
  .pre-card__table .pre-table__head .graph-view .close {
    display: none;
  }
}
@media screen {
  .pre-card__table .pre-table__head .graph-view.active .open {
    display: none;
  }
}
@media screen {
  .pre-card__table .pre-table__head .graph-view.active .close {
    display: block;
  }
}
.pre-card__table .pre-table__body .panel {
  box-shadow: none;
}
@media screen {
  .pre-card__table .pre-table__body {
    padding-top: 60px;
    background-color: #fff;
  }
}
@media screen {
  .pre-card__table .pre-table__foot {
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e5e5e5;
    background-color: #fff;
    min-height: 60px;
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen {
  .pre-card__table .pre-table__foot .round-with-advanced-stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0 auto;
  }
}
@media screen {
  .pre-card__table .pre-table__foot .round-with-advanced-stats .tiny {
    font-size: 14px;
  }
}
@media screen {
  .pre-card__table .pre-table__foot .round-with-advanced-stats .btn.comp {
    margin: 0 0 0 10px;
  }
}
.pre-card__table .pre-table__foot .round-with-advanced-stats .legend9Holes {
  width: 46px;
  height: 30px;
  border: 1px solid #fff;
  margin: 0 5px 0 0;
  margin-left: 5px;
  background-color: rgba(218, 26, 49, 0.25);
}

.add-info__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 60px;
  border-bottom: solid 1px #e5e5e5;
  padding: 20px;
}
@media screen and (min-width: 768px) {
  .add-info__row {
    justify-content: flex-start;
  }
}
.add-info__row .add-info__cell {
  color: #73737d;
  font-weight: 700;
}
@media screen and (min-width: 768px) {
  .add-info__row .add-info__cell {
    margin: 0 20px 0 0;
  }
}
@media screen and (min-width: 768px) {
  .add-info__row .add-info__cell:last-child {
    margin: 0;
  }
}

.deleted-score {
  padding: 0 !important;
  margin-right: 14px !important;
}
@media screen and (min-width: 1280px) {
  .deleted-score {
    width: 84px !important;
    height: 26px !important;
  }
}
.deleted-score span {
  background-color: #f4e9e9;
  color: #ad1312;
  width: 70px !important;
  height: 22px !important;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
}
@media screen and (min-width: 1280px) {
  .deleted-score span {
    width: 84px !important;
    height: 26px !important;
    font-size: 15px !important;
    justify-content: center !important;
    margin-right: 0 !important;
  }
}
@media screen and (min-width: 768px) {
  .deleted-score span {
    justify-content: center !important;
  }
}

@media screen {
  .filter__list {
    background-color: #fff;
    padding: 15px 25px;
    margin: 0 0 10px 0;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  }
}
@media screen and (min-width: 768px) {
  .filter__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 20px 0;
  }
}
@media screen and (min-width: 768px) {
  .filter__list label {
    margin: 0 20px 0 0;
  }
}
@media screen {
  .filter__list .default_select {
    margin: 0 0 10px 0;
  }
}
@media screen and (min-width: 768px) {
  .filter__list .default_select {
    flex: none;
    width: 240px;
    margin: 0 20px 0 0;
  }
}
@media screen and (min-width: 768px) {
  .filter__list .default_select:last-of-type {
    margin: 0;
  }
}

.contact-buttons-container {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen {
  .contact-buttons-container.in-modal .contact-buttons-item {
    margin: 30px 0;
  }
}
@media screen {
  .contact-buttons-container.in-modal .contact-buttons-item .btn.fill.blue.sixty {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .contact-buttons-container.in-modal .contact-buttons-item .btn.fill.blue.sixty {
    width: 65%;
  }
}

.contact-buttons-item {
  margin: 30px;
  text-align: center;
}
@media screen {
  .contact-buttons-item .btn.fill.blue.sixty {
    width: 100%;
    padding: 0.75em 12px;
  }
}
@media screen and (min-width: 768px) {
  .contact-buttons-item .btn.fill.blue.sixty {
    width: 60%;
  }
}

@media screen {
  .contact-buttons-item .btn.fill.blue.sixty {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen {
  .my-card-section {
    padding-top: 40px;
    padding-bottom: 0;
  }
}

.my-card-scrollable {
  max-width: 100%;
  overflow-x: auto;
  margin: 0 20px 40px 20px;
}

@media screen {
  .my-card {
    width: 930px;
    height: 528px;
    border-radius: 20px;
    background-color: #fff;
    border: solid 1px #a3a5aa;
  }
}
@media screen and (min-width: 768px) {
  .my-card {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
}
.my-card .my-card__head {
  display: flex;
  flex-direction: column;
}
.my-card .my-card__head .my-card-head__row {
  display: flex;
  flex-direction: row;
  margin: 0 5px;
}
.my-card .my-card__head .my-card-head__row.border-bot {
  border-bottom: solid 1px #a3a5aa;
}
.my-card .my-card__head .my-card-head__row.padding-top-bot {
  padding-top: 10px;
  padding-bottom: 10px;
}
.my-card .my-card__head .my-card-head__row .my-card-head__col {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}
.my-card .my-card__head .my-card-head__row .my-card-head__col.border-right {
  border-right: solid 1px #a3a5aa;
}
.my-card .my-card__head .my-card-head__row .my-card-head__col .my-card-head__cell {
  font-size: 18px;
  line-height: 24px;
  padding: 5px 15px;
}
.my-card .my-card__head .my-card-head__row .my-card-head__col .my-card-head__cell .my-card-head__label {
  color: #a3a5aa;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 10px 0 0;
}
.my-card .my-card__head .my-card-head__row .my-card-head__col .my-card__logo {
  margin: auto 15px auto auto;
  width: 260px;
  height: 70px;
  background-image: url("../img/logos/usga_ghin_logo.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center left;
  background-size: contain;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.my-card .my-card__head .my-card-head__row .my-card-head__col .my-card__sentry-logo {
  height: 70px;
  width: auto;
}
.my-card .my-card__mid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 32px;
  padding: 10px 0;
  color: #fff;
  background-color: #a3a5aa;
}
.my-card .my-card__body .my-card-body__cell {
  font-size: 18px;
  line-height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  height: 52px;
  border-right: solid 1px #a3a5aa;
}
.my-card .my-card__body .my-card-body__cell:nth-child(4n) {
  border-right: none;
}
.my-card .my-card__body .my-card-body__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.my-card .my-card__body .my-card-body__row.two-cols .my-card-body__cell {
  flex: none;
  width: 50%;
}
.my-card .my-card__body .my-card-body__row.two-cols .my-card-body__cell:nth-child(4n+2), .my-card .my-card__body .my-card-body__row.two-cols .my-card-body__cell:nth-child(4n+1) {
  background-color: #f6f6f6;
}
.my-card .my-card__body .my-card-body__row.three-cols .my-card-body__cell {
  flex: none;
  width: 33.333333%;
}
.my-card .my-card__body .my-card-body__row.three-cols .my-card-body__cell:nth-child(6n+3), .my-card .my-card__body .my-card-body__row.three-cols .my-card-body__cell:nth-child(6n+2), .my-card .my-card__body .my-card-body__row.three-cols .my-card-body__cell:nth-child(6n+1) {
  background-color: #f6f6f6;
}
.my-card .my-card__body .my-card-body__row.four-cols .my-card-body__cell {
  flex: none;
  width: 25%;
}
.my-card .my-card__body .my-card-body__row.four-cols .my-card-body__cell:nth-child(8n+4), .my-card .my-card__body .my-card-body__row.four-cols .my-card-body__cell:nth-child(8n+3), .my-card .my-card__body .my-card-body__row.four-cols .my-card-body__cell:nth-child(8n+2), .my-card .my-card__body .my-card-body__row.four-cols .my-card-body__cell:nth-child(8n+1) {
  background-color: #f6f6f6;
}

#handicap_calculator {
  padding: 10px 0;
}
#handicap_calculator .promo_banner {
  display: none;
  margin-bottom: 30px;
}
#handicap_calculator .promo_banner img {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  #handicap_calculator {
    display: block;
  }
}
#handicap_calculator h1 {
  color: #00365f;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.2em;
  margin: 25px 0 15px 0;
  padding-left: 15px;
}
@media screen and (min-width: 768px) {
  #handicap_calculator h1 {
    font-size: 28px;
  }
}
@media screen and (min-width: 1280px) {
  #handicap_calculator h1 {
    font-size: 35px;
    padding-left: 0;
  }
}
#handicap_calculator .header_club_selected {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  background-color: #fff;
  margin-bottom: 20px;
}
#handicap_calculator .header_club_selected h4 {
  color: #00365f;
  font-size: 20px;
  font-weight: 400;
  text-transform: none;
  border-bottom: none;
  text-align: left;
  padding: 0 30px 0 0;
  max-width: 350px;
}
#handicap_calculator .header_club_selected > a {
  color: #0067e6;
}
#handicap_calculator .header_club_selected .tabs.auto {
  width: 100%;
  margin: 20px auto 0 auto;
}
@media screen and (min-width: 1024px) {
  #handicap_calculator .header_club_selected .tabs.auto {
    width: auto;
    margin: 0 0 0 auto;
  }
}
#handicap_calculator .section_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0 15px 0;
  align-items: flex-start;
}
#handicap_calculator .section_header h1 {
  margin: 0 0 15px 0;
}
#handicap_calculator .section_header .tabs {
  width: 100%;
}
#handicap_calculator .section_header .tabs li {
  flex: 1 1 auto;
}
#handicap_calculator .section_header .tabs li a {
  font-size: 15px;
  text-transform: none;
  line-height: 1em;
  white-space: nowrap;
  padding: 0.8em 1em;
}
@media screen and (min-width: 768px) {
  #handicap_calculator .section_header .tabs li a {
    padding: 0.8em 2em;
  }
}
#handicap_calculator .section_header.filters {
  padding: 35px 25px;
  border-bottom: solid 1px #e5e5e5;
}
@media screen {
  #handicap_calculator .section_header.patched {
    padding: 0 20px;
    margin: 0 0 15px 0;
  }
}
@media screen and (min-width: 768px) {
  #handicap_calculator .section_header {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
    align-items: flex-end;
  }
  #handicap_calculator .section_header h1 {
    margin: 0;
  }
  #handicap_calculator .section_header .tabs {
    width: auto;
  }
}
@media screen and (min-width: 1280px) {
  #handicap_calculator .section_header .tabs li a {
    font-size: 16px;
  }
}
#handicap_calculator .row-bottom-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
}
#handicap_calculator .alert {
  padding: 10px;
  font-size: 16px;
  text-align: center;
}
#handicap_calculator .alert.alert-primary {
  color: #fff;
  background-color: #00365f;
}
@media screen and (min-width: 1024px) {
  #handicap_calculator .alert {
    font-size: 18px;
  }
}
#handicap_calculator .btn-wrapper {
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  background-color: #fff;
}
#handicap_calculator .btn-wrapper .btn.fill {
  width: 100%;
}
@media screen and (min-width: 768px) {
  #handicap_calculator .btn-wrapper .btn.fill {
    width: auto;
  }
}

@media screen and (min-width: 768px) {
  #handicap_calculator {
    padding: 20px 0 30px;
  }
}
.manually-form {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  padding: 10px 20px;
  align-items: flex-end;
}
.manually-form .full-width {
  width: -webkit-fill-available;
}
.manually-form .margin-top {
  margin-top: 20px;
}
.manually-form .error {
  display: flex;
  color: #eb0000;
  margin-top: 10px;
}
.manually-form .center {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .manually-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 20px;
  }
  .manually-form .full-width {
    width: auto;
  }
  .manually-form .margin-top {
    margin-left: 10px;
    margin-right: 10px;
  }
  .manually-form .center {
    text-align: center;
  }
}
.manually-form .ic_c_plus {
  display: inline-block;
  width: 70px;
  height: 40px;
  min-width: 40px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40px 40px;
  background-image: url("../img/icons/ic_circle_plus.svg");
}
.manually-form .ic_c_plus.active {
  background-image: url("../img/icons/ic_circle_plus_filled.svg");
}
.manually-form .ic_c_minus {
  display: inline-block;
  width: 70px;
  height: 40px;
  min-width: 40px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40px 40px;
  background-image: url("../img/icons/ic_circle_minus.svg");
}
.manually-form .btn-ic {
  border: none;
  background-color: transparent;
  margin: 0px 15px 5px 5px;
}
@media screen and (min-width: 768px) {
  .manually-form .ic_c_plus {
    width: 60px;
    background-size: 35px 35px;
  }
  .manually-form .ic_c_minus {
    width: 60px;
    background-size: 35px 35px;
  }
}
@media screen and (min-width: 1440px) {
  .manually-form .ic_c_plus {
    width: 60px;
    background-size: 25px 25px;
  }
  .manually-form .ic_c_minus {
    width: 60px;
    background-size: 25px 25px;
  }
}

.infoNote {
  padding: 20px;
  color: #757575;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.25px;
}
.infoNote .note {
  font-weight: 700;
}

.header_club_selected .handicap-allowance__container {
  width: 100%;
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1280px) {
  .header_club_selected .handicap-allowance__container {
    justify-content: flex-end;
  }
}
.header_club_selected .handicap-allowance__container label {
  padding: 15px;
  font-size: 14px;
  text-transform: uppercase;
  color: #73737d;
  font-weight: 400;
  font-style: normal;
  line-height: inherit;
  line-height: 16px;
  margin: 0;
  white-space: nowrap;
}
.header_club_selected .handicap-allowance__container .btn {
  margin: 0 15px 0 0;
}
.header_club_selected .handicap-allowance__container .default_select {
  width: 120px;
}

@media screen and (min-width: 768px) {
  .manually-form.hand-form {
    align-items: stretch;
  }
}
.manually-form.hand-form .col {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .manually-form.hand-form .col {
    width: auto;
  }
}
.manually-form.hand-form .col .error:empty {
  display: none;
}
@media screen and (min-width: 768px) {
  .manually-form.hand-form .col label {
    min-height: 32px;
    max-height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
.manually-form.hand-form .col label.type-alpha {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
@media screen and (min-width: 768px) {
  .manually-form.hand-form .col label.type-alpha {
    justify-content: flex-start;
  }
}
.manually-form.hand-form .col label.type-alpha .material-icons-outlined {
  height: 19px;
  margin: 0 0 0 2px;
}
.manually-form.hand-form .col .default_select {
  max-height: 42px;
  height: 42px;
}
@media screen and (min-width: 768px) {
  .manually-form.hand-form .col .default_select {
    min-width: 165px;
    max-width: 165px;
  }
}
.manually-form.hand-form .col .default_select .ds__control {
  max-height: 42px;
  height: 42px;
}
.manually-form.hand-form .col .default_select .ds__control .ds__value-container .ds__single-value {
  font-size: 24px;
}
@media screen and (min-width: 768px) {
  .manually-form.hand-form .col .default_select .ds__menu {
    min-width: 165px;
    max-width: 165px;
  }
}

@media screen and (min-width: 768px) {
  .manually-form.add-glf-frm {
    align-items: stretch;
  }
}
.manually-form.add-glf-frm .col {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .manually-form.add-glf-frm .col {
    flex: 1 1 auto;
  }
}
.manually-form.add-glf-frm .col.w-40 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .manually-form.add-glf-frm .col.w-40 {
    flex: none;
    width: 40px;
  }
}
.manually-form.add-glf-frm .col .error:empty {
  display: none;
}
.manually-form.add-glf-frm .col .default_select {
  height: 42px;
  max-height: 42px;
}
.manually-form.add-glf-frm .col .default_select .ds__control {
  height: 42px;
  max-height: 42px;
}
.manually-form.add-glf-frm .col .default_select .ds__control .ds__value-container .ds__single-value {
  font-size: 23px;
}
@media screen and (min-width: 768px) {
  .manually-form.add-glf-frm .col .default_select .ds__menu {
    min-width: 165px;
    max-width: 165px;
  }
}
.manually-form.add-glf-frm .col .btn-ic {
  width: 40px;
  padding: 0;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .manually-form.add-glf-frm .col .btn-ic {
    margin: 24px 0 0 0;
  }
}
@media screen {
  .my-courses {
    padding: 35px;
  }
}

@media screen and (min-width: 768px) {
  .item-list.courses .item-list__action .course-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 55px;
    white-space: normal;
    text-align: right;
    text-transform: uppercase;
    color: #00365f;
  }
}
@media screen {
  .tabs-my-courses {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    margin: 0 0 20px 0;
  }
}
@media screen {
  .tabs-my-courses.rounded li {
    flex: 1 1 0;
    border-right: solid 1px #eff0f2;
    overflow: hidden;
  }
}
@media screen and (min-width: 768px) {
  .tabs-my-courses.rounded li {
    flex: 0 0 180px;
  }
}
@media screen {
  .tabs-my-courses.rounded li:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}
@media screen {
  .tabs-my-courses.rounded li:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-right: none;
  }
}
@media screen {
  .tabs-my-courses.rounded li a {
    width: 100%;
    color: #00365f;
    background-color: #fff;
    font-size: 16px;
    line-height: 40px;
    display: block;
    text-align: center;
    cursor: pointer;
  }
}
@media screen {
  .tabs-my-courses.rounded li a.is-active {
    color: #fff;
    background-color: #00365f;
  }
}
@media screen {
  .box-head.my-courses-head {
    margin-top: 25px;
  }
  .box-head.my-courses-headh1 {
    padding-left: 0px;
  }
  .box-actions.my-courses-actions {
    margin-top: 20px;
  }
}
.accordion-container .accordion-item .accordion-item__head.my-courses-button {
  background-image: url("../img/icons/chevron_blue_right.png");
  background-size: 8px 11px;
}

.add-courses-button {
  display: flex;
  justify-content: center;
  padding: 5px 20px;
}

.my-courses-location {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.p-row .l-col.blank {
  display: none;
}
@media screen and (min-width: 1024px) {
  .p-row .l-col.blank {
    display: block;
  }
}
.profile-page__head {
  background-color: #fff;
  margin: 0 0 20px 0;
}
@media screen and (min-width: 768px) {
  .profile-page__head .p-row {
    display: flex;
    flex-direction: row;
  }
}
.profile-page__head .p-row .l-col {
  padding: 20px;
  border-bottom: solid 1px rgba(112, 112, 112, 0.1);
}
@media screen and (min-width: 768px) {
  .profile-page__head .p-row .l-col {
    flex: none;
    width: 40%;
    border: none;
    padding: 35px 0 35px 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
.profile-page__head .p-row .r-col {
  padding: 20px;
}
@media screen and (min-width: 768px) {
  .profile-page__head .p-row .r-col {
    flex: none;
    width: 60%;
    padding: 35px 35px 35px 0;
  }
}
.profile-page__head .profile-name {
  font-family: "National", sans-serif;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .profile-page__head .profile-name {
    font-size: 32px;
  }
}
.profile-page__head .profile-details {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .profile-page__head .profile-details {
    font-size: 18px;
  }
}
.profile-page__head .membership-container h4 {
  color: #00365f;
  text-transform: uppercase;
  margin: 0 0 10px 0;
}
@media screen and (min-width: 768px) {
  .profile-page__head .membership-container h4 {
    font-size: 20px;
    font-weight: 300;
  }
}
.profile-page__head .membership-container .membership-list {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .profile-page__head .membership-container .membership-list {
    height: 110px;
    overflow-y: auto;
  }
}
.profile-page__head .membership-container .membership-list .membership-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 12px 0;
}
.profile-page__head .membership-container .membership-list .membership-item:last-of-type {
  margin: 0;
}
.profile-page__head .membership-container .membership-list .membership-item .membership-item__badge {
  margin: 0 16px 0 0;
}
.profile-page__head .membership-container .membership-list .membership-item .membership-item__badge img {
  width: 48px;
  height: 48px;
  display: block;
}
.profile-page__head .membership-container .membership-list .membership-item .membership-item__details {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 24px;
}
@media screen and (min-width: 768px) {
  .profile-page__head .membership-container .membership-list .membership-item .membership-item__details strong {
    font-size: 16px;
    line-height: 18px;
  }
}
.profile-page__head .membership-container .membership-list .membership-item .membership-item__details span {
  opacity: 0.8;
}
@media screen and (min-width: 768px) {
  .profile-page__head .membership-container .membership-list .membership-item .membership-item__details span {
    font-size: 14px;
    line-height: 24px;
  }
}

.profile-page__body {
  background-color: #fff;
}

.accordion-container .accordion-item {
  border-bottom: solid 1px rgba(112, 112, 112, 0.1);
}
.accordion-container .accordion-item:last-of-type {
  border: none;
}
.accordion-container .accordion-item .accordion-item__head {
  padding: 20px 0;
  margin: 0 20px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center right;
  background-size: 12px 6px;
  background-image: url("../img/icons/chevron_blue_bottom.svg");
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .accordion-container .accordion-item .accordion-item__head {
    margin: 0 35px;
  }
}
@media screen and (min-width: 768px) {
  .accordion-container .accordion-item .accordion-item__head .p-row {
    display: flex;
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .accordion-container .accordion-item .accordion-item__head .p-row .l-col {
    flex: none;
    width: calc(40% - 5px);
  }
}
.accordion-container .accordion-item .accordion-item__head .p-row .r-col {
  display: block;
}
@media screen and (min-width: 768px) {
  .accordion-container .accordion-item .accordion-item__head .p-row .r-col {
    flex: none;
    width: 60%;
  }
}
.accordion-container .accordion-item .accordion-item__head.is-open {
  background-image: url("../img/icons/chevron_blue_top.svg");
}
.accordion-container .accordion-item .accordion-item__head.is-open .p-row .r-col {
  display: none;
}
.accordion-container .accordion-item .accordion-item__head .accordion-item__label {
  color: #73737d;
  font-size: 16px;
  line-height: 20px;
}
.accordion-container .accordion-item .accordion-item__head .accordion-item__preview {
  color: #000;
  font-size: 18px;
  line-height: 24px;
}
.accordion-container .accordion-item .accordion-item__head .accordion-item__preview .psw {
  color: #73737d;
  font-size: 32px;
  line-height: 24px;
  max-height: 24px;
}
.accordion-container .accordion-item .accordion-item__head .accordion-item__preview .cat-title {
  color: #73737d;
  font-size: 14px;
  line-height: 18px;
}
.accordion-container .accordion-item .accordion-item__head .accordion-item__preview .default {
  color: #000;
  font-size: 18px;
  line-height: 24px;
}
.accordion-container .accordion-item .accordion-item__body {
  background-color: #f6f6f6;
  display: none;
  padding: 0 20px;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .accordion-container .accordion-item .accordion-item__body .p-row {
    display: flex;
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .accordion-container .accordion-item .accordion-item__body .p-row .l-col {
    display: block;
    flex: none;
    width: calc(40% - 5px);
  }
}
@media screen and (min-width: 768px) {
  .accordion-container .accordion-item .accordion-item__body .p-row .r-col {
    flex: none;
    width: 60%;
    max-width: 380px;
  }
}
.accordion-container .accordion-item .accordion-item__body.is-open {
  display: block;
  padding: 40px 20px;
}
@media screen and (min-width: 768px) {
  .accordion-container .accordion-item .accordion-item__body.is-open {
    padding: 40px 35px;
  }
}
.accordion-container .accordion-item .accordion-item__body input[type=text].large2,
.accordion-container .accordion-item .accordion-item__body input[type=password].large2,
.accordion-container .accordion-item .accordion-item__body input[type=email].large2 {
  font-size: 16px;
  border-color: #e5e5e5;
  color: #000;
  max-height: 41px;
  height: 41px;
}
.accordion-container .accordion-item .accordion-item__body input[type=text].large2:focus,
.accordion-container .accordion-item .accordion-item__body input[type=password].large2:focus,
.accordion-container .accordion-item .accordion-item__body input[type=email].large2:focus {
  border-color: #e5e5e5;
}
.accordion-container .accordion-item .accordion-item__body input[type=text].large2.error,
.accordion-container .accordion-item .accordion-item__body input[type=password].large2.error,
.accordion-container .accordion-item .accordion-item__body input[type=email].large2.error {
  border-color: #ca243e;
}

.panel-heading {
  color: #000;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 20px 0;
  text-transform: uppercase;
}
.panel-heading.no-wrap {
  white-space: nowrap;
}
.panel-heading.no-margin {
  margin: 0;
}

.color-muted {
  color: #73737d;
}

.switch-heading {
  color: #000;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
}

.switch-description {
  line-height: 1 !important;
  color: #000;
  opacity: 0.7;
  margin: 0 !important;
}

span.error {
  color: red;
  font-size: 15px;
  line-height: 15px;
  font-family: National, sans-serif;
  margin-top: 8px;
}

.default_select {
  max-height: 41px;
  height: 41px;
}
.default_select .ds__control {
  max-height: 41px;
  height: 41px;
}
.default_select .ds__control .ds__value-container .ds__single-value {
  font-size: 16px;
  color: #000;
}

.date_picker_wrapper {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
  align-items: center;
}
.date_picker_wrapper .picker_title {
  font-family: National, sans-serif;
  font-size: 18px;
  color: #000;
}
@media screen {
  .date_picker_wrapper .change_month {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.date_picker_wrapper .change_month {
  font-family: National, sans-serif;
  font-size: 28px;
  color: #000;
}

.svg-color svg {
  color: #949494;
}

.choose {
  display: flex;
  flex-direction: column;
}
.choose .choose_body {
  display: flex;
  flex-direction: column;
}
.choose .choose_body .choose-option {
  border: solid 1px #e5e5e5;
  padding: 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 8px;
  margin: 0 0 16px 0;
  background-color: #fff;
  transition: all 300ms ease-in-out 0ms;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.choose .choose_body .choose-option:last-child {
  margin: 0;
}
.choose .choose_body .choose-option:hover {
  background-color: #f6f6f6;
}
.choose .choose_body .choose-option strong {
  font-size: 19px;
  line-height: 1;
  font-weight: 700;
  margin: 0 0 8px 0;
}
.choose .choose_body .choose-option span {
  margin: 0 0 8px 0;
}
.choose .choose_body .choose-option span:last-child {
  margin: 0;
}
.choose .choose_footer {
  border-top: solid 1px #e5e5e5;
  margin: 16px 0 0 0;
  padding: 16px 0 0 0;
}

.pre_authorization {
  display: flex;
  flex-direction: column;
}
.pre_authorization .pre_authorization_body {
  font-size: 20px;
  margin-bottom: 20px;
}
.pre_authorization .pre_authorization_body .title {
  font-size: 22px;
  color: #00365f;
  margin-bottom: 30px;
}
.pre_authorization .pre_authorization_body .title .str {
  font-weight: 700;
}
.pre_authorization .pre_authorization_body .items {
  list-style: inside;
}
.pre_authorization .pre_authorization_body .items .item {
  font-size: 18px;
  margin-left: 40px;
  margin-top: 5px;
  font-weight: 700;
}
.pre_authorization .pre_authorization_body .items .item .em {
  font-weight: 300;
}
.pre_authorization .pre_authorization_body .footer_buttons {
  flex: 1 0 120px;
  margin: 0 10px;
}
@media screen and (min-width: 768px) {
  .pre_authorization .pre_authorization_body .footer_buttons {
    flex: 1 0 200px;
  }
}
.pre_authorization .pre_authorization_footer {
  margin: 16px 0 0 0;
  padding: 16px 0 0 0;
  display: flex;
}

.end-of-year-button {
  padding: 0;
  border: none;
  background: none;
}

.end-of-year-span {
  font-family: "National";
}

.end-of-year-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.end-of-year-padding {
  padding: 30px !important;
}

.end-of-year-share-image {
  display: block;
  margin: auto;
}

.end-of-year-body {
  width: 440px;
  height: 783px;
}

.buttons-container {
  z-index: 10000;
  position: absolute;
  left: 10px;
  margin-right: 5px;
  bottom: 0px;
}
.buttons-container .share-button {
  margin-right: 10px;
}
.buttons-container .twitter_icon {
  background: url("../img/icons/twitter.svg") no-repeat center;
  background-size: 24px !important;
  width: 50px !important;
  height: 50px !important;
  background-color: #000000;
  border-radius: 100px;
  margin: 0 2px 1px 0;
}

@media only screen and (min-width: 391px) and (max-width: 1310px) {
  .end-of-year-body {
    width: 300px;
    height: 534px;
  }
}
@media only screen and (min-width: 1310px) and (max-width: 1440px) {
  .end-of-year-body {
    width: 339px;
    height: 603px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1730px) {
  .end-of-year-body {
    width: 428px;
    height: 761px;
  }
}
@media only screen and (max-width: 391px) {
  .end-of-year-body {
    width: 200px;
    height: 356px;
  }
  .end-of-year-padding {
    padding: 5px !important;
  }
}
.Reacttrial-modal__Overlay {
  z-index: 1000;
  background-color: rgba(127, 127, 127, 0.5);
}

.trial-modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  background: #fff;
  overflow: auto;
  outline: none;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 500px;
  border-radius: 10px;
}
.trial-modal-dialog .trial-modal-content {
  position: relative;
  z-index: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
}
.trial-modal-dialog .trial-modal-content .trial-modal_close {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
  font-size: 20px;
  background: none;
  border: none;
}
.trial-modal-dialog .trial-modal-content .trial-modal_close:hover {
  background: none;
  border: none;
}
.trial-modal-dialog .trial-modal-content .trial-modal-body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0% 5% 10% 5%;
}
.trial-modal-dialog .trial-modal-content .trial-modal-body h3 {
  font-size: 22px;
  line-height: 1em;
  margin-bottom: 20px;
  color: #00365f;
  text-align: center;
}
.trial-modal-dialog .trial-modal-content .trial-modal-body p {
  text-align: center;
  font-size: 18px;
  line-height: 1.4em;
  font-weight: 300;
}
.trial-modal-dialog .trial-modal-content .trial-modal-body .content {
  align-items: center;
  justify-content: center;
}
.trial-modal-dialog .trial-modal-content .trial-modal-body .error {
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: #cc233c;
  padding-top: 1em;
}
.trial-modal-dialog .trial-modal-content .trial-modal-body .actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 35px;
  align-items: center;
}
.trial-modal-dialog .trial-modal-content .trial-modal-body .actions .btn {
  max-width: 50%;
  align-items: center;
  margin-bottom: 5%;
}
.trial-modal-dialog .trial-modal-content .trial-modal-body .socials {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.trial-modal-dialog .trial-modal-content .trial-modal-body .socials a,
.trial-modal-dialog .trial-modal-content .trial-modal-body .socials a:hover,
.trial-modal-dialog .trial-modal-content .trial-modal-body .socials a:visited {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 140px;
  color: #00365f;
  text-transform: uppercase;
  font-weight: 500;
}
.trial-modal-dialog .trial-modal-content .trial-modal-body .socials span {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: auto 22px;
  background-position: center center;
  margin-right: 15px;
}
.trial-modal-dialog .trial-modal-content .trial-modal-body .socials span.twitter {
  background-image: url("../img/icons/ic_social_twitter.svg");
  background-color: rgb(42, 170, 224);
}
.trial-modal-dialog .trial-modal-content .trial-modal-body .socials span.facebook {
  background-image: url("../img/icons/ic_social_fb.svg");
  background-color: rgb(41, 83, 150);
}
.trial-modal-dialog .trial-modal-content .trial-modal-body .socials span.linkedin {
  background-image: url("../img/icons/ic_social_linkedin.svg");
  background-color: rgb(1, 115, 178);
}
.trial-modal-dialog .trial-modal-content .trial-modal-body .login p {
  line-height: 1.2em;
}
.trial-modal-dialog .trial-modal-content .trial-modal-body .success-icon {
  height: 50px;
  background: url("../img/icons/check_icon.svg") no-repeat center;
  margin-bottom: 0.5em;
}

.modal-footer .modal-footer::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 110px; /* Adjust based on how much of the bottom you want grayed out */
  background-color: rgba(0, 0, 0, 0.1); /* Light gray overlay, adjust opacity as needed */
  z-index: 1;
}

@media screen and (min-width: 1280px) {
  .trial-modal-dialog .trial-modal-content .trial-modal-body h3 {
    font-size: 26px;
  }
  .trial-modal-dialog .trial-modal-content .trial-modal-body p {
    font-size: 22px;
    line-height: 1.3em;
  }
}
@media screen {
  .usga-calendar {
    width: 240px;
    height: 80px;
    background-size: 70% 70%;
    margin-bottom: 10px;
    display: inline-block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-image: url("../img/icons/usga-calendar.svg");
  }
}

@media screen {
  .usga-flag {
    width: 240px;
    height: 80px;
    background-size: 70% 70%;
    margin-bottom: 10px;
    display: inline-block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-image: url("../img/icons/usga-flag.svg");
  }
}

.challenge-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
  background-color: white;
  font-family: "National" !important;
}

.challenge-content {
  width: 100%;
  padding: 10px 20px;
  font-family: "National" !important;
}

.challenge-title {
  font-weight: bold !important;
  font-family: "National" !important;
  color: gray;
}

.challenge-title h2 {
  margin: 0;
  font-family: "National" !important;
}

.challenge-leaderboard-title {
  margin-bottom: 10px !important;
  font-weight: bold !important;
  margin-block: 0 !important;
  font-family: "National" !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  color: black;
}

.challenge-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "National" !important;
  height: 40px;
}

.challenge-caption {
  color: gray;
}

.challenge-filter-container {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.challenge-filter-container-label {
  margin-right: 14px;
}

.challenge-filter {
  background-color: white;
  width: 200px;
}

.challenge-filter-label {
  font-size: 14px;
  font-weight: bold;
  color: grey;
  padding: 10px 0 0 10px;
}

.challenge-chips-container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-self: end;
}

.table-container {
  min-height: 100vh;
  max-width: 100%;
  overflow-x: auto;
}

.table-container-compact {
  font-size: 10px;
}

.table-container-regular {
  font-size: 14px;
}

.table-row-compact {
  height: 37px;
}

.table-row-comfortable {
  height: 53px;
}

.table-row-expanded {
  height: 69px;
}

.position-cell {
  display: flex;
  align-items: center;
}

.position-display {
  width: 50%;
}

.follow-icon {
  width: 50%;
}

.icon-star,
.icon-star-outline {
  color: rgb(0, 54, 95);
}

.header-round,
.header-total {
  font-family: "National Book", sans-serif;
  font-size: 12px !important;
  font-weight: bold;
  color: gray !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.header-position {
  font-family: "National", sans-serif;
  font-size: 12px !important;
  font-weight: bold;
  color: rgb(16, 24, 40) !important;
  padding-right: 0 !important;
}

.header-name {
  font-family: "National", sans-serif;
  font-size: 12px !important;
  font-weight: bold;
  color: rgb(16, 24, 40) !important;
}

.body-round,
.body-total {
  border-left: 1px solid rgb(224, 224, 224);
  justify-content: center;
}

.name-cell {
  font-family: "National", sans-serif;
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  max-width: 100%;
  display: flex;
  align-items: center;
}

.name-display {
  overflow: hidden;
  text-overflow: ellipsis;
}

.handicap-display {
  margin-left: 10px;
  font-family: "National Book", sans-serif;
  font-size: 18px;
  color: gray;
}

.round-cell {
  font-family: "National Narrow", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: rgb(102, 112, 133);
}

.total-positive {
  font-weight: bold;
}

.total-negative {
  font-weight: bold;
  color: red;
}

.MuiBox-root.css-1tbggly {
  display: none !important;
}

@media (max-width: 600px) {
  .name-cell,
  .round-cell,
  .total-negative,
  .total-positive,
  .handicap-display,
  .position-cell,
  .icon-star,
  .icon-star-outline {
    font-size: 0.8em !important;
  }
  .header-name,
  .header-position,
  .header-round,
  .header-total {
    font-size: 12px !important;
    font-family: "National" !important;
  }
}
.filters-modal-container {
  background-color: white;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 20px 20px 0 0;
  padding: 25px;
}

.filters-close-button {
  display: inline-block;
  padding: 25px;
  border: none;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
}

.filters-modal-title {
  margin-top: 10px;
  margin-bottom: 26px;
  font-size: 20px;
  font-weight: 700;
}

.filters-modal-following {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 500;
  color: #101828;
}

.filters-modal-status {
  font-weight: 500;
  color: #101828;
}
.filters-modal-status-title {
  text-transform: uppercase;
  padding: 16px 0;
}
.filters-modal-status-option {
  border: solid 1px #98A2B3;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 5px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filters-modal-status-option-text {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.filters-modal-status-option-text span:nth-child(even) {
  color: #667085;
  font-size: 14px;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.modal-container {
  background: white;
  padding: 25px 20px;
  border-radius: 12px;
  width: 400px;
  height: 500px;
  max-width: 90%;
  text-align: left;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  font-family: "Arial", sans-serif;
}

/* Close Button */
.close-modal {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

/* Dots Navigation */
.dots-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  /* Adjust spacing for the dots above buttons */
}

.dots-navigation .dot {
  width: 10px;
  height: 10px;
  background-color: #ddd;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dots-navigation .dot.active {
  background-color: #003366;
}

/* Step Number */
.step-number {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #003366;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
  border: 3px solid #ddd;
}

/* Step Title */
.step-title {
  font-size: 22px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #000000;
}

/* Step Description */
.step-description {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.5;
}

.additional-content p {
  font-size: 13px;
  color: #666;
}

.modal-navigation {
  margin-bottom: 10px;
  text-align: center !important;
}

.modal-navigation .prev-button,
.modal-navigation .next-button,
.modal-navigation .close-button {
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  width: 150px;
  font-weight: bold;
}

.modal-navigation .prev-button {
  background-color: white;
  color: #003366;
  border: 2px solid #003366;
  margin-right: 10px;
}

.modal-navigation .next-button {
  background-color: white;
  color: #003366;
  border: 2px solid #003366;
}

.modal-navigation .close-button {
  background-color: white;
  color: #003366;
  border: 2px solid #003366;
  align-items: center;
}

.modal-navigation .prev-button:hover,
.modal-navigation .next-button:hover,
.modal-navigation .close-button:hover {
  background-color: #f0f0f0;
}

.bottom-navigation {
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

/* Score Calculation Container */
.score-calculation {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 20px 0;
  gap: 15px;
}

.score-item {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 70px;
}

.score-label {
  font-size: 10px;
  color: #6c757d;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
}

.score-value {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.score-small {
  font-size: 12px;
  color: #6c757d;
  margin-left: 5px;
}

.score-operator {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  margin-bottom: -20px;
}

@media screen {
  .form {
    padding: 20px 25px;
  }
}
@media screen and (min-width: 768px) {
  .form {
    padding: 40px 0;
    width: 400px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1280px) {
  .form {
    width: 460px;
  }
}

@media screen {
  label {
    color: #00365f;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    margin: 0 0 10px 0;
    display: block;
  }
}
@media screen {
  label.white {
    color: #fff;
  }
}
@media screen {
  label.centered {
    text-align: center;
  }
}
@media screen {
  label.regular {
    font-size: 16px;
    font-weight: 400;
  }
}
@media screen {
  label.large {
    font-size: 18px;
  }
}
@media screen {
  label.black {
    color: #000;
  }
}

@media screen {
  input[type=text],
  input[type=number],
  input[type=tel],
  input[type=password],
  input[type=email] {
    height: 35px;
    font-size: 16px;
    line-height: 25px;
    padding: 5px 10px;
    margin: 0;
    border: solid 1px #eff0f2;
    border-radius: 1px;
  }
}
@media screen {
  input[type=text]:disabled,
  input[type=number]:disabled,
  input[type=tel]:disabled,
  input[type=password]:disabled,
  input[type=email]:disabled {
    background-color: #f6f6f6;
  }
}
@media screen {
  input[type=text]:focus,
  input[type=number]:focus,
  input[type=tel]:focus,
  input[type=password]:focus,
  input[type=email]:focus {
    border-color: #cacaca;
  }
}
@media screen {
  input[type=text].large,
  input[type=number].large,
  input[type=tel].large,
  input[type=password].large,
  input[type=email].large {
    color: #00365f;
    height: 42px;
    font-size: 24px;
    line-height: 42px;
    border-color: #cacaca;
  }
}
@media screen {
  input[type=text].large:focus,
  input[type=number].large:focus,
  input[type=tel].large:focus,
  input[type=password].large:focus,
  input[type=email].large:focus {
    border-color: #00365f;
  }
}
@media screen {
  input[type=text].centered-text,
  input[type=number].centered-text,
  input[type=tel].centered-text,
  input[type=password].centered-text,
  input[type=email].centered-text {
    text-align: center;
  }
}
input[type=text].full-width,
input[type=number].full-width,
input[type=tel].full-width,
input[type=password].full-width,
input[type=email].full-width {
  width: 100%;
}
input[type=text].full-height,
input[type=number].full-height,
input[type=tel].full-height,
input[type=password].full-height,
input[type=email].full-height {
  height: 41px;
}

@media screen {
  input[type=password] {
    font-size: 24px;
  }
}

@media screen {
  input[type=text].search {
    height: 40px;
    width: 100%;
    min-width: 240px;
    font-size: 16px;
    line-height: 40px;
    padding: 0 60px 0 20px;
    border-radius: 20px;
  }
}

@media screen {
  .search-container {
    margin: 0 0 15px 0;
    position: relative;
  }
}
@media screen {
  .search-container:after {
    content: "search";
    display: block;
    font-family: "Material Icons Outlined";
    font-size: 24px;
    line-height: 40px;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 0;
    top: 0;
  }
}

@media screen {
  .btn {
    flex: none;
    width: auto;
    height: auto;
    font-size: 15px;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 2px;
    font-family: inherit;
    padding: 0.75em 2.4em;
    border-radius: 20px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    will-change: background-color;
    -webkit-transition: background-color 300ms ease-in-out 0ms;
    -moz-transition: background-color 300ms ease-in-out 0ms;
    -ms-transition: background-color 300ms ease-in-out 0ms;
    transition: background-color 300ms ease-in-out 0ms;
    -webkit-tap-highlight-color: transparent;
  }
}
@media screen and (min-width: 768px) {
  .btn {
    font-size: 16px;
  }
}
@media screen and (min-width: 1280px) {
  .btn {
    font-size: 19px;
  }
}
@media screen {
  .btn.empty {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
  }
}
@media screen {
  .btn.blank {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen {
  .btn.blank.lh24 {
    line-height: 24px;
    font-size: 16px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;
    padding-right: 0;
  }
}
@media screen {
  .btn.blank.medium {
    font: 400 11px system-ui;
    text-transform: none;
    line-height: 15px;
    height: 15px;
  }
}
@media screen {
  .btn.blank.no-pad {
    padding: 0;
  }
}
@media screen {
  .btn.blank.blue {
    color: #00365f;
    text-transform: none;
  }
}
@media screen {
  .btn.fill.cardinal {
    color: #fff;
    background-color: #cc233c;
  }
}
@media screen {
  .btn.fill.cardinal:hover {
    background-color: rgb(182.2343096234, 31.2656903766, 53.5983263598);
  }
}
@media screen {
  .btn.fill.blue {
    color: #fff;
    background-color: #00365f;
  }
}
@media screen {
  .btn.fill.blue:hover {
    background-color: rgb(0, 39.5052631579, 69.5);
  }
}
.btn.fill.blue.sixty {
  min-width: 60%;
}
@media screen {
  .btn:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
@media screen {
  .btn.outline {
    display: block;
    border: none;
    will-change: box-shadow;
  }
}
.btn.outline.full {
  width: 100%;
}
@media screen {
  .btn.outline.blue {
    color: #00365f;
    -webkit-box-shadow: inset 0 0 0 1px #00365f;
    -moz-box-shadow: inset 0 0 0 1px #00365f;
    -ms-box-shadow: inset 0 0 0 1px #00365f;
    box-shadow: inset 0 0 0 1px #00365f;
    background-color: transparent;
  }
}
@media screen {
  .btn.lnk {
    font-size: 16px;
    line-height: 35px;
    font-weight: 500;
    background-color: transparent;
    text-decoration: underline;
    text-transform: none;
    letter-spacing: normal;
  }
}
@media screen {
  .btn.lnk.white {
    color: #fff;
  }
}
@media screen {
  .btn.lnk.blue {
    color: #0067e6;
  }
}
@media screen {
  .btn.lnk.no-pad {
    padding: 0;
  }
}
@media screen {
  .btn.lnk.small {
    font-size: 14px;
    line-height: 20px;
  }
}
.btn.zero-pad {
  padding-left: 0;
  padding-right: 0;
}
.btn.no-underline {
  text-decoration: none;
}
.btn.too_large_on_phone {
  padding: 0.75em 1em;
}
@media screen and (min-width: 768px) {
  .btn.too_large_on_phone {
    padding: 0.75em 2.4em;
  }
}
.btn.has_badge {
  padding: 0.5em 2.4em;
}
.btn .badge {
  display: inline-block;
  font-size: 1.2em;
  padding: 0.25em 0.4em;
  letter-spacing: normal;
  line-height: 1em;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  background-color: #fff;
}
.btn.fill.cardinal .badge {
  color: #cc233c;
}
@media screen {
  .btn.comp {
    padding: 0;
    background-color: transparent;
    border: none;
  }
}
@media screen {
  .btn.comp .btn-in {
    padding: 0 10px;
    margin: 0 5px 0 0;
    height: 30px;
    line-height: 30px;
    color: #0088ce;
    background-color: #f6f6f6;
  }
}
@media screen {
  .btn.comp .btn-out {
    color: #00365f;
  }
}
.btn.comp .open {
  display: none;
}
.btn.comp .close {
  display: inline-block;
}
.btn.comp.active .open {
  display: inline-block;
}
.btn.comp.active .close {
  display: none;
}
.btn.add, .btn.delete, .btn.check {
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}
.btn.add::before, .btn.delete::before, .btn.check::before {
  content: "";
  display: block;
  margin: 0 5px 0 0;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: contain;
}
.btn.add {
  color: #00365f;
  background-color: transparent;
  border: none;
}
.btn.add::before {
  background-image: url("../img/icons/ic_circle_plus.svg");
}
.btn.delete {
  color: #cc233c;
  background-color: transparent;
  border: none;
  padding: 0;
}
.btn.delete::before {
  background-image: url("../img/icons/ic_circle_minus.svg");
}
.btn.check {
  color: #00365f;
  background-color: transparent;
  border: none;
}
.btn.check::before {
  background-image: url("../img/icons/check_icon.svg");
}

.item-list.courses {
  position: relative;
}
@media screen and (min-width: 768px) {
  .item-list.courses .item-list__action {
    position: absolute;
    top: 0;
    right: 20px;
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .item-list.courses .item-list__action .btn.lnk.type-z-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 200px;
    height: 55px;
    white-space: normal;
    text-align: right;
  }
}
@media screen and (min-width: 1280px) {
  .item-list.courses .item-list__action .btn.lnk.type-z-button {
    max-width: none;
  }
}
.item-list.courses .item-list__action .btn.lnk.type-z-button.filter {
  height: 70px;
}

@media screen {
  input[type=checkbox].regular {
    display: none;
  }
}
input[type=checkbox].regular + label .link {
  text-decoration: underline;
  color: #000;
  display: contents;
}
@media screen {
  input[type=checkbox].regular + label {
    font-size: 15px;
    line-height: 23px;
    display: flex;
    flex-direction: row;
    margin: 0;
    cursor: pointer;
    position: relative;
  }
}
@media screen {
  input[type=checkbox].regular + label::before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    margin: 0 8px 0 0;
    background-color: #fff;
    border: solid 1px #818181;
    cursor: pointer;
  }
}
@media screen {
  input[type=checkbox].regular:checked + label::after {
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    height: 10px;
    border-bottom: solid 4px #00365f;
    border-right: solid 4px #00365f;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 2px;
    left: 6px;
  }
}
@media screen {
  input[type=checkbox].focusable {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0;
  }
}
@media screen {
  input[type=checkbox].focusable + label {
    font-size: 15px;
    line-height: 23px;
    display: flex;
    flex-direction: row;
    margin: 0;
    cursor: pointer;
    position: relative;
  }
}
@media screen {
  input[type=checkbox].focusable + label::before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    margin: 0 8px 0 0;
    background-color: #fff;
    border: solid 1px #818181;
    cursor: pointer;
  }
}
@media screen {
  input[type=checkbox].focusable:checked + label::after {
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    height: 10px;
    border-bottom: solid 4px #00365f;
    border-right: solid 4px #00365f;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 2px;
    left: 6px;
  }
}
input[type=checkbox].focusable:focus-visible + label {
  outline: 3px solid red;
}

@media screen {
  .switch-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen {
  .switch-container input[type=checkbox] {
    display: none;
  }
}
@media screen {
  .switch-container input[type=checkbox] + label {
    color: #000;
    font-size: 14px;
    line-height: 30px;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
}
@media screen {
  .switch-container input[type=checkbox] + label .switch {
    width: 52px;
    height: 26px;
    border-radius: 13px;
    background-color: #bbbbbb;
    display: flex;
    flex-direction: row;
    align-items: center;
    will-change: background-color;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
    margin: 0 0 0 10px;
  }
}
@media screen {
  .switch-container input[type=checkbox] + label .switch::before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 5px;
    will-change: transform;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
    pointer-events: none;
  }
}
@media screen {
  .switch-container input[type=checkbox] + label .switch + .switch-status::before {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .switch-container input[type=checkbox] + label .switch + .switch-status::before {
    content: "OFF";
    display: block;
    color: #000;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 0 8px;
  }
}
.switch-container input[type=checkbox] + label .switch + .switch-status.visible {
  width: 33px;
}
@media screen {
  .switch-container input[type=checkbox] + label .switch + .switch-status.visible::before {
    content: "OFF";
    display: block;
    color: #000;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 0 8px;
  }
}
@media screen {
  .switch-container input[type=checkbox]:checked + label .switch {
    background-color: #00365f;
  }
}
@media screen {
  .switch-container input[type=checkbox]:checked + label .switch::before {
    -webkit-transform: translate3d(26px, 0, 0);
    -moz-transform: translate3d(26px, 0, 0);
    -ms-transform: translate3d(26px, 0, 0);
    transform: translate3d(26px, 0, 0);
  }
}
@media screen and (min-width: 768px) {
  .switch-container input[type=checkbox]:checked + label .switch + .switch-status::before {
    content: "ON";
    color: #00365f;
  }
}
@media screen {
  .switch-container input[type=checkbox]:checked + label .switch + .switch-status.visible::before {
    content: "ON";
    color: #00365f;
  }
}

@media screen {
  .radio_container.inline {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .radio_container.is-full {
    width: 100%;
  }
}
@media screen {
  .radio_container input[type=radio].pill {
    position: absolute;
    transform: scale(0);
  }
}
@media screen {
  .radio_container input[type=radio].pill + label {
    flex: 1 1 0;
    height: 35px;
    color: #00365f;
    font-size: 14px;
    line-height: 35px;
    display: block;
    text-align: center;
    border: solid 1px #e5e5e5;
  }
}
@media screen {
  .radio_container input[type=radio].pill + label:first-of-type {
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
  }
}
@media screen {
  .radio_container input[type=radio].pill + label:last-of-type {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    border-left: none;
  }
}
@media screen {
  .radio_container input[type=radio].pill:focus-visible + label {
    outline: 2px solid red !important;
  }
}
@media screen {
  .radio_container input[type=radio].pill:checked + label {
    color: #fff;
    background-color: #00365f;
    border-color: #00365f;
  }
}

@media screen {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 35px;
    border: solid 1px #e5e5e5;
    background-color: #fff;
    padding: 0 10px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: calc(100% - 10px) center;
    background-image: url("../img/icons/ic_expand_more_24px.svg");
    background-size: 24px 24px;
  }
}
@media screen {
  select option {
    border: none;
  }
}

.tees_select > .ds__menu > .ds__menu-list > .ds__option--is-disabled {
  background-color: #eff0f2 !important;
}

.right-side {
  position: relative;
  left: -10px;
  top: -10px;
  margin-bottom: -10px;
  flex: 0 1 200px;
  height: 30px;
  border-color: transparent;
}
.right-side > .rs__control {
  background: transparent;
  border-width: 0;
  border-color: transparent;
  box-shadow: none;
}
.right-side > .rs__control > .rs__indicators > .rs__indicator-separator {
  display: none;
}
.right-side > .rs__control > .rs__indicators .rs__indicator {
  padding-left: 0;
  color: hsl(0, 0%, 50%);
}
.right-side > .rs__control div[class$=-Input] {
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  color: transparent;
}
.right-side > .rs__control .rs__single-value > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.right-side > .rs__control .rs__single-value > div small {
  display: none;
}
.right-side > .rs__menu {
  position: fixed;
  height: 100vh;
  z-index: 1000;
  overflow-y: scroll;
  left: 0;
  top: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
}
.right-side > .rs__menu > .rs__menu-list {
  padding-bottom: 0;
  padding-top: 0;
}
.right-side > .rs__menu .rs__option {
  border-bottom: 1px solid #e5e5e5;
  padding: 15px 12px;
  font-size: 17px;
  line-height: 20px;
  font-weight: 500;
}
.right-side > .rs__menu .rs__option small {
  display: block;
  color: #73737d;
  font-weight: 400;
}
.right-side .rs__control--menu-is-open svg {
  transform: rotate(-90deg);
}

@media screen and (min-width: 768px) {
  .right-side > .rs__menu {
    position: absolute;
    width: 300px;
    height: auto;
    max-height: 280px;
    left: 100%;
    top: 50%;
    transform: translate(0, -50%);
  }
}
@media screen {
  .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%;
  }
}
@media screen {
  .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: calc(100% - 10px) center;
    background-size: 24px 24px;
    background-image: url("../img/icons/ic_today_24px.svg");
  }
}
.react-datepicker-wrapper .react-datepicker__input-container input:focus {
  border: 3px solid red;
}
.react-datepicker-wrapper .react-datepicker__input-container input.using-mouse:focus {
  border: solid 1px #eff0f2 !important;
}

@media screen {
  .react-datepicker-popper {
    width: 100vw;
    left: -25px !important;
  }
}
@media screen and (min-width: 768px) {
  .react-datepicker-popper {
    width: 360px;
    left: 0 !important;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker {
    width: 100%;
    border: solid 1px #e5e5e5;
    border-radius: 0;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__navigation {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    border: none;
    top: 10px;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous {
    left: 10px;
    background-image: url("../img/icons/ic_chevron_left_24px.svg");
  }
}
.react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous:focus-visible {
  outline: 3px solid red;
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next {
    right: 10px;
    background-image: url("../img/icons/ic_chevron_right_24px.svg");
  }
}
.react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next:focus-visible {
  outline: 3px solid red;
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container {
    width: 100%;
  }
}
.react-datepicker-popper .react-datepicker .react-datepicker__month-container:focus-visible {
  outline: 3px solid red;
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header {
    background-color: #fff;
    border: none;
    padding: 0;
    margin: 0;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
    height: 45px;
    font-size: 18px;
    line-height: 45px;
    font-weight: 400;
    background-color: #cce7f5;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
    margin: 0 10px;
    border-bottom: solid 1px #e5e5e5;
    text-transform: uppercase;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
    flex: none;
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    font-weight: 500;
    margin: 0;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month {
    margin: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day {
    flex: none;
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
    margin: 0;
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover {
    border-radius: 50%;
    background-color: rgba(0, 54, 95, 0.0625);
  }
}
@media screen {
  .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected {
    border-radius: 50%;
    background-color: #00365f;
  }
}
@media screen {
  .react-datepicker-popper[data-placement=top-start] .react-datepicker__triangle:before {
    border-bottom-color: #fff !important;
    border-top-color: #e5e5e5 !important;
  }
}
@media screen {
  .react-datepicker-popper[data-placement=bottom-start] .react-datepicker__triangle {
    border-bottom-color: transparent;
  }
}
@media screen {
  .react-datepicker-popper[data-placement=bottom-start] .react-datepicker__triangle:before {
    border-bottom-color: #cce7f5 !important;
    border-top-color: #e5e5e5 !important;
  }
}

@media screen {
  .info {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }
}
@media screen {
  .info.relativ {
    position: relative;
  }
}
@media screen {
  .info p {
    font-size: 16px;
  }
}
@media screen {
  .info strong {
    font-weight: 700;
  }
}
@media screen {
  .info strong.large {
    font-size: 32px;
    display: inline-block;
    margin: 0 0 0 10px;
  }
}
@media screen {
  .info sup {
    -webkit-transform: translate3d(0, -12px, 0);
    -moz-transform: translate3d(0, -12px, 0);
    -ms-transform: translate3d(0, -12px, 0);
    transform: translate3d(0, -12px, 0);
  }
  .info sup button {
    padding: 0;
  }
}

@media screen {
  .react-icon-select {
    height: 39px;
    max-height: 39px;
    min-width: 38px;
  }
}
@media screen {
  .react-icon-select .react-icon-select__control {
    height: 39px;
    max-height: 39px;
    border: none;
    display: flex;
    flex-direction: row;
    border-radius: 0;
    background-color: #fff;
    position: relative;
    cursor: pointer;
  }
}
@media screen {
  .react-icon-select .react-icon-select__control.react-icon-select__control--menu-is-open, .react-icon-select .react-icon-select__control.react-icon-select__control--is-focused {
    border: none;
    box-sizing: content-box;
    -webkit-box-shadow: inset 0 0 0 1px #00365f;
    -moz-box-shadow: inset 0 0 0 1px #00365f;
    -ms-box-shadow: inset 0 0 0 1px #00365f;
    box-shadow: inset 0 0 0 1px #00365f;
  }
}
@media screen {
  .react-icon-select .react-icon-select__control .react-icon-select__value-container {
    width: 100%;
    height: 40px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: none;
    position: relative;
  }
}
@media screen {
  .react-icon-select .react-icon-select__control .react-icon-select__value-container .react-icon-select__value {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
  }
}
@media screen {
  .react-icon-select .react-icon-select__control .react-icon-select__value-container .react-icon-select__value .material-icons-outlined + span {
    display: none;
  }
}
@media screen {
  .react-icon-select .react-icon-select__control .react-icon-select__indicators .react-icon-select__indicator.react-icon-select__clear-indicator {
    display: none;
  }
}
@media screen {
  .react-icon-select .react-icon-select__control .react-icon-select__indicators .react-icon-select__indicator.react-icon-select__dropdown-indicator::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 6px 6px;
    border-color: transparent transparent #0067e6 transparent;
    position: absolute;
    right: 4px;
    bottom: 4px;
  }
}
@media screen {
  .react-icon-select .react-icon-select__control .react-icon-select__indicators .react-icon-select__indicator.react-icon-select__dropdown-indicator svg {
    display: none;
  }
}
@media screen {
  .react-icon-select .react-icon-select__control .react-icon-select__indicators .react-icon-select__indicator-separator {
    display: none;
  }
}

@media screen {
  .react-icon-select__menu {
    border-radius: 0 !important;
    margin-top: -1px !important;
    margin-bottom: 0 !important;
    width: 160px !important;
    box-shadow: none !important;
    border: solid 1px #00365f;
  }
}
@media screen {
  .react-icon-select__menu .react-icon-select__menu-list {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}
@media screen {
  .react-icon-select__menu .react-icon-select__menu-list .react-icon-select__option {
    height: 40px;
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    border-bottom: solid 1px #7fa2b6;
  }
}
@media screen {
  .react-icon-select__menu .react-icon-select__menu-list .react-icon-select__option .material-icons-outlined {
    margin: 0 8px 0 0;
  }
}
@media screen {
  .react-icon-select__menu .react-icon-select__menu-list .react-icon-select__option span {
    color: #000;
    opacity: 0.6;
  }
}
@media screen {
  .react-icon-select__menu .react-icon-select__menu-list .react-icon-select__option.react-icon-select__option--is-selected {
    background-color: #e5ecf0;
  }
}
@media screen {
  .react-icon-select__menu .react-icon-select__menu-list .react-icon-select__option.react-icon-select__option--is-selected span {
    opacity: 1;
  }
}
@media screen {
  .react-icon-select__menu .react-icon-select__menu-list .react-icon-select__clear {
    display: block;
    background-color: transparent;
    border: none;
    height: 40px;
    color: #00365f;
    font-size: 14px;
    line-height: 40px;
    font-weight: 700;
    margin: 0;
    padding: 0 8px;
    text-align: left;
  }
}

@media screen {
  .react-icon-select .material-icons-outlined,
  .react-icon-select__menu .material-icons-outlined {
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
  }
}
@media screen {
  .react-icon-select .material-icons-outlined.green,
  .react-icon-select__menu .material-icons-outlined.green {
    color: #20b123;
    -webkit-box-shadow: inset 0 0 0 1px #20b123;
    -moz-box-shadow: inset 0 0 0 1px #20b123;
    -ms-box-shadow: inset 0 0 0 1px #20b123;
    box-shadow: inset 0 0 0 1px #20b123;
  }
}
@media screen {
  .react-icon-select .material-icons-outlined.red,
  .react-icon-select__menu .material-icons-outlined.red {
    color: #cc233c;
    -webkit-box-shadow: inset 0 0 0 1px #cc233c;
    -moz-box-shadow: inset 0 0 0 1px #cc233c;
    -ms-box-shadow: inset 0 0 0 1px #cc233c;
    box-shadow: inset 0 0 0 1px #cc233c;
  }
}

.default_select {
  max-height: 41px;
  height: 41px;
  flex-grow: 0;
}
.default_select .ds__control {
  border-radius: 0;
  border: 1px solid #e5e5e5;
  min-height: 35px;
  max-height: 41px;
}
.default_select .ds__control .ds__single-value .right {
  margin-left: 10px;
}
.default_select .ds__control .ds__value-container div[class$=-Input] {
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  color: transparent;
}
.default_select .ds__control .ds__indicators .ds__indicator-separator {
  display: none;
}
.default_select .ds__control:hover, .default_select .ds__control.ds__control--is-focused {
  border-color: #e5e5e5;
  box-shadow: 0 0 1px #e5e5e5;
}
.default_select .ds__menu {
  border-radius: 0;
  margin-top: -1px;
  margin-bottom: 0;
  box-shadow: none;
  border: 1px solid #e5e5e5;
}
.default_select .ds__menu .ds__menu-list {
  padding-top: 0;
  padding-bottom: 0;
}
.default_select .ds__menu .ds__menu-list .ds__option {
  white-space: pre-line;
  padding: 14px 12px;
  color: #444;
  border-bottom: 1px solid #e5e5e5;
}
.default_select .ds__menu .ds__menu-list .ds__option small {
  display: block;
  color: #73737d;
  font-size: 0.9em;
}
.default_select .ds__menu .ds__menu-list .ds__option--is-selected,
.default_select .ds__menu .ds__menu-list .ds__option--is-focused {
  background-color: #f6f6f6;
  color: #000;
}
.default_select.full_on_phone .ds__menu {
  position: fixed;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;
}
@media screen and (min-width: 768px) {
  .default_select.full_on_phone .ds__menu {
    position: absolute;
    height: auto;
  }
}
@media screen {
  .default_select.full_on_phone.full_on_phone .ds__menu {
    border: none;
  }
}
@media screen and (min-width: 768px) {
  .default_select.full_on_phone.full_on_phone .ds__menu {
    border: solid 1px #e5e5e5;
    border-top: none;
    position: absolute;
    min-width: 0;
    width: 280px;
  }
}
@media screen {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__menu-list {
    max-height: none !important;
  }
}
@media screen and (min-width: 768px) {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__menu-list {
    max-height: 360px !important;
  }
}
@media screen {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    height: 70px;
    position: relative;
  }
}
@media screen and (min-width: 768px) {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__header {
    display: none;
  }
}
@media screen {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__header .ds__button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    color: #0067e6;
    font-size: 16px;
    line-height: 32px;
    display: block;
  }
}
@media screen {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__header .ds__button.left {
    position: absolute;
    left: 25px;
  }
}
@media screen {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__header .ds__title {
    font-size: 16px;
    line-height: 32px;
    flex: none;
    max-width: 200px;
  }
}
@media screen {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__body {
    overflow-y: scroll;
    height: calc(100vh - 70px);
  }
}
@media screen and (min-width: 768px) {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__body {
    overflow-y: auto;
    height: auto;
  }
}
@media screen {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__option {
    font-size: 18px;
    line-height: 20px;
    padding: 20px 25px;
  }
}
@media screen and (min-width: 768px) {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__option {
    font-size: 14px;
    line-height: 20px;
    padding: 15px;
  }
}
@media screen and (min-width: 1280px) {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__option {
    font-size: 16px;
  }
}
@media screen {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__option:first-of-type {
    border-top: solid 1px #e5e5e5;
  }
}
@media screen {
  .default_select.full_on_phone.full_on_phone .ds__menu .ds__option:last-of-type {
    border-bottom: solid 1px #e5e5e5;
  }
}
.default_select.minwidth {
  min-width: 280px;
}
.default_select.smallwidth {
  min-width: 220px;
}

.calculator-tee-dropdown {
  width: 100%;
}
.calculator-tee-dropdown .ds__control:hover, .calculator-tee-dropdown .ds__control.ds__control--is-focused {
  border-color: transparent;
  box-shadow: none;
}
.calculator-tee-dropdown .padding-left {
  padding-left: 10px;
}

.calculator-selected-tee .calculator-tee-option-ratings {
  display: none;
}

.calculator-tee-dropdown > .ds__menu > .ds__menu-list > .ds__option--is-disabled {
  background-color: #eff0f2 !important;
  min-width: 280px;
}

.calculator-tee-dropdown > .ds__menu > .ds__menu-list > .ds__option--is-selected {
  background-color: transparent !important;
}

.calculator-tee-dropdown > .ds__menu > .ds__menu-list > .ds__option--is-focused {
  background-color: #eff0f2 !important;
}

@media (min-width: 800px) {
  .calculator-tee-dropdown > .ds__menu {
    width: max-content !important;
  }
}

@media screen and (min-width: 768px) {
  .hide-on-tablet-and-above {
    display: none;
  }
}

@media screen {
  .hide-on-phone {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .hide-on-phone {
    display: block;
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.blurred {
  background-color: transparent;
  filter: blur(0.7px);
  -webkit-filter: blur(0.7px);
  -moz-filter: blur(0.7px);
  -o-filter: blur(0.7px);
  -ms-filter: blur(0.7px);
}

.blackWhite {
  opacity: 0.5;
  filter: grayscale(100%);
  -webkit-filter: grayscale(0.7px);
}

.row.manual-handicap__row {
  padding: 0 20px;
  margin: 0 0 20px 0;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .row.manual-handicap__row {
    flex-direction: row;
    margin: 0 -8px 20px -8px;
  }
}
.row.manual-handicap__row .col:first-of-type {
  margin: 0 0 20px 0;
}

