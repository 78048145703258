.filters-modal-container {
  background-color: white;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 20px 20px 0 0;
  padding: 25px;
}

.filters-close-button {
  display: inline-block;
  padding: 25px;
  border: none;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
}

.filters-modal-title {
  margin-top: 10px;
  margin-bottom: 26px;
  font-size: 20px;
  font-weight: 700;
}

.filters-modal-following {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 500;
  color: #101828;
}

.filters-modal-status {
  font-weight: 500;
  color: #101828;

  &-title {
    text-transform: uppercase;
    padding: 16px 0;
  }

  &-option {
    border: solid 1px #98A2B3;
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 5px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-text {
      display: flex;
      flex-direction: column;
      padding: 0 20px;

      span:nth-child(even) {
        color: #667085;
        font-size: 14px;
      }
    }
  }
}