/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.modal-container {
  background: white;
  padding: 25px 20px;
  border-radius: 12px;
  width: 400px;
  height: 500px;
  max-width: 90%;
  text-align: left;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  font-family: "Arial", sans-serif;
}

/* Close Button */
.close-modal {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

/* Dots Navigation */
.dots-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  /* Adjust spacing for the dots above buttons */
}

.dots-navigation .dot {
  width: 10px;
  height: 10px;
  background-color: #ddd;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dots-navigation .dot.active {
  background-color: #003366;
}

/* Step Number */
.step-number {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #003366;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
  border: 3px solid #ddd;
}

/* Step Title */
.step-title {
  font-size: 22px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #000000;
}

/* Step Description */
.step-description {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.5;
}

.additional-content p {
  font-size: 13px;
  color: #666;
}

.modal-navigation {
  margin-bottom: 10px;
  text-align: center !important;
}

.modal-navigation .prev-button,
.modal-navigation .next-button,
.modal-navigation .close-button {
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  width: 150px;
  font-weight: bold;
}

.modal-navigation .prev-button {
  background-color: white;
  color: #003366;
  border: 2px solid #003366;
  margin-right: 10px;
}

.modal-navigation .next-button {
  background-color: white;
  color: #003366;
  border: 2px solid #003366;
}

.modal-navigation .close-button {
  background-color: white;
  color: #003366;
  border: 2px solid #003366;
  align-items: center;
}

.modal-navigation .prev-button:hover,
.modal-navigation .next-button:hover,
.modal-navigation .close-button:hover {
  background-color: #f0f0f0;
}

.bottom-navigation {
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

/* Score Calculation Container */
.score-calculation {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 20px 0;
  gap: 15px;
}

.score-item {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 70px;
}

.score-label {
  font-size: 10px;
  color: #6c757d;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
}

.score-value {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.score-small {
  font-size: 12px;
  color: #6c757d;
  margin-left: 5px;
}

.score-operator {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  margin-bottom: -20px;
}