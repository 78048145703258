.challenge-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
  background-color: white;
  font-family: "National" !important;
}

.challenge-content {
  width: 100%;
  padding: 10px 20px;
  font-family: "National" !important;
}

.challenge-title{
  font-weight: bold !important;
  font-family: "National" !important;
  color: gray;
}

.challenge-title h2 {
  margin: 0;
  font-family: "National" !important;
}

.challenge-leaderboard-title {
  margin-bottom: 10px !important;
  font-weight: bold !important;
  margin-block: 0 !important;
  font-family: "National" !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  color: black;
}

.challenge-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "National" !important;
  height: 40px;
}

.challenge-caption {
  color: gray;
}

.challenge-filter-container {
  margin-right: 10px;
  display: flex;
  align-items: center;
  
  &-label {
    margin-right: 14px;
  }
}

.challenge-filter {
  background-color: white;
  width: 200px;
}

.challenge-filter-label {
  font-size: 14px;
  font-weight: bold;
  color: grey;
  padding: 10px 0 0 10px;
}

.challenge-chips-container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-self: end;
}


.table-container {
  min-height: 100vh;
  max-width: 100%;
  overflow-x: auto;
}

.table-container-compact {
  font-size: 10px;
}

.table-container-regular {
  font-size: 14px;
}

.table-row-compact {
  height: 37px;
}

.table-row-comfortable {
  height: 53px;
}

.table-row-expanded {
  height: 69px;
}